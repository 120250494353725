import { useCallback, useState } from "react";
import debounce from "debounce";
import useAuthenticatedRestCall from "./useAuthenticatedRestCall";
import { getAccessCodeByCodeUrl } from "../util/getUrls";

const CHECK_ACCESS_CODE_EXISTENCE_DEBOUNCE_DELAY_MS = 300;

export const useAccessCodeVerification = () => {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const [accessCodeAlreadyExists, setAccessCodeAlreadyExists] = useState(false);

  const validateAccessCode = useCallback(
    debounce((value) => {
      makeAuthenticatedRequest(
        getAccessCodeByCodeUrl(value.trim()),
        false
      ).then((response) => {
        const accessCodeAlreadyExists = response.data.docs.length > 0;
        setAccessCodeAlreadyExists(accessCodeAlreadyExists);
      });
    }, CHECK_ACCESS_CODE_EXISTENCE_DEBOUNCE_DELAY_MS),
    [makeAuthenticatedRequest]
  );

  return { validateAccessCode, accessCodeAlreadyExists };
};
