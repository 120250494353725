import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import useToggle from "../hooks/useToggle";
import { addClassToDefaultIfTrue } from "../util/addClassToDefaultIfTrue";

import DevicePreview from "./device-preview/DevicePreview";

import { useParams } from "react-router";
import QuizEditorContext from "../contexts/QuizEditorContext";
import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";
import ContentEditorToolbar from "./courses/ContentEditorToolbar";
import ContentEditor from "./courses/ContentEditor";
import LanguageContext from "../contexts/LanguageContext";
import { DEFAULT_LANGUAGE_CODE } from "../constants/defaultLanguageCode";
import { DEFAULT_EDITOR_CONTENT } from "../constants/defaultEditorContent";
import { useDefaultLanguage } from "../hooks/useDefaultLanguage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    display: "flex",
    overflowY: "hidden",
    flexDirection: "column",
    "&.hidden": {
      display: "none",
    },
  },
  closePreviewButton: {
    fontSize: "14px",
    background: "rgba(0,0,0,0.3)",
    color: "white",
  },
  editorContainer: {
    minHeight: 0,
    margin: "0 20vw",
    position: "relative",
    "&.hidden": {
      display: "none",
    },
  },
  loadingSpinner: {
    marginTop: "4rem",
    width: "6rem !important",
    height: "6rem !important",
  },
});

function AddEditCourseContent({
  initialRichContent,
  onTitleChange,
  isLoading,
  saveSuccessMessage,
  saveRequest,
  isQuiz,
  title,
  showQuizModeToggle,
  onQuizModeChanged,
  published,
  onPublishedChanged,
  translatedContent,
  setTranslatedContent,
  translatedTitles,
  setTranslatedTitles,
}) {
  const classes = useStyles();
  const [titleError, setTitleError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [previewOpen, togglePreviewOpen] = useToggle();
  const { enqueueSnackbar } = useSnackbar();
  let { courseId, levelId } = useParams();
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { setDisplayAllErrors, emptyQuestions, emptyAnswers } =
    useContext(QuizEditorContext);
  const { languageInfo } = useContext(LanguageContext);
  const { defaultLanguage, defaultLanguageLoaded } = useDefaultLanguage();

  const [languageBeingEditedId, setLanguageIdBeingEdited] = useState("");

  //Init editor content with empty paragraph.
  const [editorContent, setEditorContent] = useState(DEFAULT_EDITOR_CONTENT);
  const [previewContent, setPreviewContent] = useState(DEFAULT_EDITOR_CONTENT);

  useEffect(() => {
    if (initialRichContent) {
      setEditorContent(initialRichContent);
    }
  }, [initialRichContent]);

  const handleTitleChange = (value) => {
    if (value.trim().length === 0) {
      setTitleError("Please provide a title");
    } else {
      setTitleError(null);
    }

    onTitleChange(value);
  };

  const displayLoadingSpinner = () =>
    isLoading ? <CircularProgress className={classes.loadingSpinner} /> : null;

  const updateRichContent = (newContentId) =>
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${levelId}/courses/${courseId}/content/${newContentId}/rich-content`,
      true,
      {
        data: {
          content: editorContent,
        },
        method: "PUT",
      }
    );

  const redirectOrShowError = (response) => {
    if (response && response.status === 200) {
      enqueueSnackbar(`${saveSuccessMessage}`, {
        variant: "success",
      });
    }

    setSaving(false);
  };

  const addRichContentAndRedirectOrShowError = (newContentId) => {
    updateRichContent(newContentId).then(redirectOrShowError);
  };

  const saveContent = () => {
    setSaving(true);

    saveRequest().then((response) => {
      if (!isQuiz && response) {
        const newContentId = response.data.id;

        addRichContentAndRedirectOrShowError(newContentId);
      } else {
        redirectOrShowError(response);
      }
    });
  };

  const saveIfNoContentErrors = () => {
    setDisplayAllErrors(true);

    if (!isQuiz || (emptyQuestions.length === 0 && emptyAnswers.length === 0)) {
      saveContent();
    } else {
      enqueueSnackbar(
        "Please fix the missing fields in your quiz before saving",
        {
          variant: "error",
        }
      );
    }
  };

  const handleSaveClicked = () => {
    if (titleError) {
      enqueueSnackbar("Please give your content a title", {
        variant: "error",
      });
    } else {
      saveIfNoContentErrors();
    }
  };

  const updateTranslatedContent = useCallback(
    (languageId, translatedContentItem) => {
      const updatedContent = { ...translatedContent };
      updatedContent[languageId] = translatedContentItem;

      setTranslatedContent(updatedContent);
    },
    [translatedContent, setTranslatedContent]
  );

  // TODO: refactor to reduce duplication
  const setTranslatedContentForLanguageBeingEdited = useCallback(
    (translatedContentItem) => {
      updateTranslatedContent(languageBeingEditedId, translatedContentItem);
    },
    [updateTranslatedContent, languageBeingEditedId]
  );

  const setTranslatedTitleForLanguageBeingEdited = useCallback(
    (translatedTitle) => {
      const updatedTitles = { ...translatedTitles };
      updatedTitles[languageBeingEditedId] = translatedTitle;

      setTranslatedTitles(updatedTitles);
    },
    [setTranslatedTitles, translatedTitles, languageBeingEditedId]
  );

  const copyEnglishContentIfEmpty = (languageId) => {
    if (
      defaultLanguageLoaded &&
      languageId !== defaultLanguage.id &&
      JSON.stringify(translatedContent[languageId]) ===
        JSON.stringify(DEFAULT_EDITOR_CONTENT)
    ) {
      updateTranslatedContent(languageId, editorContent);
    }
  };

  const handleLanguageBeingEditedChanged = (event) => {
    setLanguageIdBeingEdited(event.target.value);
    copyEnglishContentIfEmpty(event.target.value);
  };

  useEffect(() => {
    if (!languageInfo.loading) {
      const defaultLanguage = languageInfo.languages.find(
        (language) => language.abbreviation === DEFAULT_LANGUAGE_CODE
      );

      setLanguageIdBeingEdited(defaultLanguage.id);
    }
  }, [languageInfo.loading, languageInfo.languages]);

  // END TODO

  useEffect(() => {
    if (defaultLanguageLoaded) {
      const updatedPreviewContent =
        languageBeingEditedId === defaultLanguage.id
          ? editorContent
          : translatedContent[languageBeingEditedId];

      setPreviewContent(updatedPreviewContent);
    }
  }, [
    defaultLanguageLoaded,
    defaultLanguage,
    editorContent,
    languageBeingEditedId,
    translatedContent,
  ]);

  return (
    <Fragment>
      {displayLoadingSpinner()}
      <div
        className={addClassToDefaultIfTrue(
          classes.wrapper,
          isLoading,
          "hidden"
        )}
      >
        <ContentEditorToolbar
          title={title}
          translatedTitles={translatedTitles}
          onTitleChange={handleTitleChange}
          titleError={titleError}
          saving={saving}
          onSaveClick={handleSaveClicked}
          togglePreviewOpen={togglePreviewOpen}
          quizMode={isQuiz}
          showQuizModeToggle={showQuizModeToggle}
          onQuizModeChanged={onQuizModeChanged}
          published={published}
          onPublishedChanged={onPublishedChanged}
          languageBeingEditedId={languageBeingEditedId}
          onLanguageBeingEditedChanged={handleLanguageBeingEditedChanged}
          setTranslatedTitleForLanguageBeingEdited={
            setTranslatedTitleForLanguageBeingEdited
          }
        />
        <ContentEditor
          saving={saving}
          loading={isLoading}
          quizMode={isQuiz}
          editorContent={editorContent}
          setEditorContent={setEditorContent}
          untranslatedTitle={title}
          languageToEditId={languageBeingEditedId}
          translationContent={translatedContent}
          setTranslationContent={setTranslatedContent}
          setTranslatedContentForLanguageBeingEdited={
            setTranslatedContentForLanguageBeingEdited
          }
        />
      </div>
      <Dialog
        open={previewOpen}
        onClose={togglePreviewOpen}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent>
          <DevicePreview title={title} previewContent={previewContent} />
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            className={classes.closePreviewButton}
            onClick={togglePreviewOpen}
          >
            Close Preview
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditCourseContent;
