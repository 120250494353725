import { Editor, Transforms, Range, Element } from 'slate';
import getVideoId from 'get-video-id';
const LIST_TYPES = ['numbered-list','numbered-list-standard','numbered-list-fancy','bulleted-list','bulleted-list-standard','bulleted-list-fancy'];

// Checks to see if a BLOCK type element is active
export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });
  return !!match;
};

// Toggle BLOCK type elements
export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

// Checks to see if a MARK type (italic, bold, underline) style is active
export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

// Toggles Mark Type
export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export function getTextBlockStyle(editor) {
  const selection = editor.selection;
  if (selection == null) {
    return null;
  }

  const topLevelBlockNodesInSelection = Editor.nodes(editor, {
    at: editor.selection,
    mode: 'highest',
    match: (n) => Editor.isBlock(editor, n),
  });

  let blockType = null;
  let nodeEntry = topLevelBlockNodesInSelection.next();
  while (!nodeEntry.done) {
    const [node] = nodeEntry.value;
    if (blockType == null) {
      blockType = node.type;
    } else if (blockType !== node.type) {
      return 'multiple';
    }

    nodeEntry = topLevelBlockNodesInSelection.next();
  }

  return blockType;
}

// Checks to see if the selection is a Link type
export function isLinkNodeAtSelection(editor, selection) {
  if (selection == null) {
    return false;
  }

  return (
    Editor.above(editor, {
      at: selection,
      match: (n) => n.type === 'link',
    }) != null
  );
}

// Toggle Link for selection
export function toggleLinkAtSelection(editor) {
  if (!isLinkNodeAtSelection(editor, editor.selection)) {
    const isSelectionCollapsed = Range.isCollapsed(editor.selection);
    if (isSelectionCollapsed) {
      Transforms.insertNodes(
        editor,
        {
          type: 'link',
          url: '#',
          children: [{ text: 'link' }],
        },
        { at: editor.selection }
      );
    } else {
      Transforms.wrapNodes(
        editor,
        { type: 'link', url: '#', children: [{ text: '' }] },
        { split: true, at: editor.selection }
      );
    }
  } else {
    Transforms.unwrapNodes(editor, {
      match: (n) => Element.isElement(n) && n.type === 'link',
    });
  }
}

export function insertVideo(editor, url) {
  if (!url) return;
  const videoId = getVideoId(url).id;
  let src;
  if (url.match(/youtube\.com/)) {
    src = `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0`;
  } else if (url.match(/vimeo\.com/)) {
    src = `https://player.vimeo.com/video/${videoId}`;
  } else {
    alert('Only YouTube or Vimeo links are allowed'); /* eslint-disable-line */
    return;
  }
  console.log(src);
  Transforms.insertNodes(
    editor,
    [
      {
        type: 'video',
        src,
        style: 'light',
        title: '',
        caption: '',
        children: [{ text: ' ' }],
      },
      {
        type: 'paragraph',
        children: [{ text: ' ' }],
      },
    ],
    { at: editor.selection }
  );
}

export const isAlignmentActive = (editor, align) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.align === align,
  });
  return !!match;
};


export const toggleAlignment = (editor, align) => {
  const isActive = isAlignmentActive(editor, align);
  if (isActive) {
    Transforms.setNodes(editor, { align: null });
  } else {
    Transforms.setNodes(editor, { align: align });
  }
};
