import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  section: {
    height: "100%",
  },
  header: {
    display: "flex",
    marginBottom: "1rem",
  },
  title: {
    marginRight: "auto",
    marginBottom: "0",
  },
  toolbar: {
    "& > *": {
      marginLeft: "2rem",
    },
  },
});

function Page(props) {
  const classes = useStyles();

  const title = () => {
    return props.title && props.title.length > 1 ? (
      <h1 className={classes.title}>{props.title}</h1>
    ) : null;
  };

  return (
    <section className={classes.section}>
      <div className={classes.header}>
        {title()}
        <div className={classes.toolbar}>{props.toolbarContent}</div>
      </div>
      <div>{props.children}</div>
    </section>
  );
}

export default Page;
