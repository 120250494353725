import { useEffect, useState, useCallback, useContext } from "react";
import { useSnackbar } from "notistack";
import AddEditLevelDialog from "./AddEditLevelDialog";
import useAuthenticatedRestCall from "../../hooks/useAuthenticatedRestCall";
import { useUploadLevelCompletionImage } from "../../hooks/useUploadLevelCompletionImage";
import ClientContext from "../../contexts/ClientContext";
import useExistingTitleTranslationsInDialog from "../../hooks/useExistingTitleTranslationsInDialog";

export default function EditLevelDialog(props) {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { enqueueSnackbar } = useSnackbar();
  const uploadLevelCompletionImage = useUploadLevelCompletionImage();
  const { clientInfo } = useContext(ClientContext);
  const [imageSrc, setImageSrc] = useState(null);
  const [newImageUploaded, setNewImageUploaded] = useState(false);

  const { existingTitleTranslations } = useExistingTitleTranslationsInDialog(
    props.open,
    `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.level.id}`
  );

  const fetchCourseCompletionImage = useCallback(() => {
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}/clients/${clientInfo.clientId}/levels/${props.level.id}/level-completion-image`,
      false
    )
      .then((result) => {
        setImageSrc(result.data.location);
      })
      .catch(() => {
        setImageSrc("");
      });
  }, [makeAuthenticatedRequest, clientInfo.clientId, props.level.id]);

  useEffect(() => {
    if (props.open & !newImageUploaded) {
      fetchCourseCompletionImage();
    }
  }, [props.open, fetchCourseCompletionImage, newImageUploaded]);

  const handleConfirm = (title, imageFile, titleTranslations) => {
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.level.id}`,
      false,
      {
        data: {
          title,
          titleTranslations,
        },
        method: "PUT",
      }
    ).then((response) => {
      enqueueSnackbar(`${title} updated!`, {
        variant: "success",
      });

      if (imageFile) {
        uploadLevelCompletionImage(response.data.id, imageFile).then(() => {
          setNewImageUploaded(true);
          setImageSrc(`${imageSrc}#${Math.random()}`);
        });
      }

      props.onLevelModified(response.data);
    });

    props.onClose();
  };

  return (
    <AddEditLevelDialog
      title="Edit level"
      open={props.open}
      onClose={props.onClose}
      onConfirm={handleConfirm}
      confirmText="Save level"
      initialTitle={props.level.title}
      imageLoading={imageSrc === null}
      initialImageSrc={imageSrc}
      levelId={props.level.id}
      existingTitleTranslations={existingTitleTranslations}
      loading={existingTitleTranslations === null}
    />
  );
}
