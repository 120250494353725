/**
 * These utils are intended to make it easier to update Course/Content arrays following a drag
 * and drop event.
 */

export const removeItemFromArrayById = (array, itemId) =>
  [...array].filter((item) => item.id !== itemId);

export const addOrMoveItemAfterOtherItem = (array, updatedItem, otherItemId) => {
  const arrayWithoutItem = removeItemFromArrayById(array, updatedItem.id);
  const otherContentIndex = arrayWithoutItem.findIndex(
    (content) => content.id === otherItemId
  );
  return [
    ...arrayWithoutItem.slice(0, otherContentIndex + 1),
    updatedItem,
    ...arrayWithoutItem.slice(otherContentIndex + 1),
  ];
};
