import { makeStyles } from '@material-ui/core/styles';
import { MdNotifications, MdBusiness } from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import MainNavLink from './MainNavLink';
import { ReactComponent as CoursesIcon } from '../images/courses-icon.svg';
import { ReactComponent as UsersIcon } from '../images/users-icon.svg';
import { ReactComponent as AdminIcon } from '../images/admin-icon.svg';
import { addClassToDefaultIfTrue } from '../util/addClassToDefaultIfTrue';

const useStyles = makeStyles({
  root: {
    padding: '3rem 2rem',
    background: 'white',
    borderRight: '1px solid var(--dark-grey)',
    width: '30rem',
    fontSize: '1.4rem',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 400ms ease',
    '&.hidden': {
      height: 'calc(100% - 6.5rem)',
      position: 'absolute',
      transform: 'translateX(-30rem)',
    },
  },
});

function MainNav(props) {
  const classes = useStyles();

  return (
    <nav
      className={addClassToDefaultIfTrue(
        classes.root,
        !props.visible,
        'hidden'
      )}
    >
      <MainNavLink to="/courses" icon={<CoursesIcon />}>
        Courses
      </MainNavLink>
      <MainNavLink to="/users" icon={<UsersIcon />}>
        Users
      </MainNavLink>
      <MainNavLink to="/clients" icon={<MdBusiness />}>
        Clients
      </MainNavLink>
      <MainNavLink to="/reports" icon={<HiOutlineDocumentReport />}>
        Reports
      </MainNavLink>
      <MainNavLink to="/admin" icon={<AdminIcon />}>
        Admin
      </MainNavLink>
      <MainNavLink
        to={{ pathname: 'https://dashboard.jacapps.com' }}
        icon={<MdNotifications />}
        target="_blank"
      >
        Push Dashboard
      </MainNavLink>
    </nav>
  );
}

export default MainNav;
