import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  button: {
    background: "white",
    padding: "2rem 5rem",
    border: "1px solid var(--dark-grey)",
    fontWeight: "bold",
    letterSpacing: "1px",
  },
});

function AdminLandingPageButton(props) {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      variant="outlined"
      color="secondary"
      to={props.to}
      component={NavLink}
    >
      {props.label}
    </Button>
  );
}

export default AdminLandingPageButton;
