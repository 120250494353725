import { useState, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import {
  FormControlLabel,
  Checkbox,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Page from "../components/Page";
import Card from "../components/Card";
import EditField from "../components/EditField";
import GarnishAccordion from "../components/GarnishAccordion";
import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";
import {
  getCourseUrl,
  getAccessCodesUrl,
  getLevelsForClientUrl,
} from "../util/getUrls";
import ClientContext from "../contexts/ClientContext";
import { useHistory } from "react-router-dom";
import { updateObjectAtIndex } from "../util/arrayUtils";
import { useAccessCodeVerification } from "../hooks/useAccessCodeVerification";

const useStyles = makeStyles({
  codeField: {
    width: "50rem",
  },
  contentEntry: {
    padding: "1rem 0 1rem 5rem",
    "&:not(:last-child)": {
      borderBottom: "2px solid var(--dark-grey)",
    },
  },
  emptyLevelMessage: {
    padding: ".5rem 2rem",
  },
  contentLoadingSpinner: {
    margin: "1rem 2rem",
  },
});

function AddAccessCodePage() {
  const classes = useStyles();
  const { clientInfo } = useContext(ClientContext);
  const [code, setCode] = useState("");
  const [levels, setLevels] = useState([]);
  const [levelCourses, setLevelCourses] = useState([]);
  const [levelsSelected, setLevelsSelected] = useState([]);
  const [saving, setSaving] = useState(false);
  const { accessCodeAlreadyExists, validateAccessCode } =
    useAccessCodeVerification();
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const fetchLevels = async () => {
      const levelResponses = await makeAuthenticatedRequest(
        getLevelsForClientUrl(clientInfo.clientId)
      );

      setLevels(levelResponses.data.docs);
    };

    if (clientInfo.clientId) {
      fetchLevels();
    }
  }, [makeAuthenticatedRequest, clientInfo.clientId]);

  const handleCodeChanged = (event) => {
    validateAccessCode(event.target.value);
    setCode(event.target.value.toUpperCase());
  };

  const levelIsSelected = (levelId) => levelsSelected.includes(levelId);

  const getCourseEntry = (levelId) =>
    levelCourses.find((course) => course.levelId === levelId);

  const courseIsExcluded = (levelId, courseId) =>
    !levelIsSelected(levelId) ||
    getCourseEntry(levelId).exclusions.includes(courseId);

  const updateCourseEntry = (levelId, updatedCourseEntry) => {
    const index = levelCourses.findIndex(
      (course) => course.levelId === levelId
    );

    const updatedCourseContentEntries = updateObjectAtIndex(
      levelCourses,
      index,
      updatedCourseEntry
    );

    setLevelCourses(updatedCourseContentEntries);
  };

  const handleCourseExcludedToggled = (event, levelId, courseId) => {
    const updatedCourseEntry = { ...getCourseEntry(levelId) };

    if (event.target.checked) {
      updatedCourseEntry.exclusions = updatedCourseEntry.exclusions.filter(
        (exclusion) => exclusion !== courseId
      );

      if (!levelsSelected.includes(levelId)) {
        setLevelsSelected([...levelsSelected, levelId]);
      }
    } else {
      updatedCourseEntry.exclusions = [
        ...updatedCourseEntry.exclusions,
        courseId,
      ];
    }

    updateCourseEntry(levelId, updatedCourseEntry);
  };

  const removeAllExclusions = (levelId) => {
    const updatedCourseEntry = { ...getCourseEntry(levelId) };

    updatedCourseEntry.exclusions = [];

    updateCourseEntry(levelId, updatedCourseEntry);
  };

  const excludeAll = (levelId) => {
    const existingCourseEntry = getCourseEntry(levelId);

    if (existingCourseEntry) {
      const updatedCourseContentEntry = { ...existingCourseEntry };

      updatedCourseContentEntry.exclusions =
        updatedCourseContentEntry.content.map((course) => course.id);

      updateCourseEntry(levelId, updatedCourseContentEntry);
    }
  };

  const handleLevelSelected = (event, levelId) => {
    event.stopPropagation();

    if (event.target.checked) {
      setLevelsSelected([...levelsSelected, levelId]);
      removeAllExclusions(levelId);
    } else {
      const updatedLevelsSelected = levelsSelected.filter(
        (id) => id !== levelId
      );

      setLevelsSelected(updatedLevelsSelected);
      excludeAll(levelId);
    }
  };

  const getAccordionTitleCheckbox = (levelId, levelName) => (
    <FormControlLabel
      control={
        <Checkbox
          checked={levelIsSelected(levelId)}
          onChange={(event) => handleLevelSelected(event, levelId)}
          onClick={(event) => event.stopPropagation()}
          name={levelName}
          disabled={saving}
        />
      }
      label={levelName}
    />
  );

  const getLevelCourses = (levelId) => {
    const level = levels.find((level) => level.id === levelId);

    const requests = level.courses.map((id) =>
      makeAuthenticatedRequest(getCourseUrl(levelId, id))
    );

    return Promise.all(requests);
  };

  const handleLevelCourseResponses = (levelId, responses) => {
    const responseData = responses.map((response) => response.data);

    const exclusions = levelsSelected.includes(levelId)
      ? []
      : responseData.map((x) => x.id);

    setLevelCourses([
      ...levelCourses,
      {
        levelId,
        content: responseData,
        exclusions,
      },
    ]);
  };

  const handleAccordionToggled = (levelId) => {
    if (!levelCourses.some((entry) => entry.levelId === levelId)) {
      getLevelCourses(levelId).then((responses) =>
        handleLevelCourseResponses(levelId, responses)
      );
    }
  };

  const getEmptyLevelContent = () => (
    <p className={classes.emptyLevelMessage}>This level has no courses</p>
  );

  const renderCourse = (courseEntry) =>
    courseEntry.content.length > 0
      ? courseEntry.content.map((course) => (
          <div key={course.id} className={classes.contentEntry}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!courseIsExcluded(courseEntry.levelId, course.id)}
                  onChange={(event) =>
                    handleCourseExcludedToggled(
                      event,
                      courseEntry.levelId,
                      course.id
                    )
                  }
                  name={course.name}
                  disabled={saving}
                />
              }
              label={course.name}
            />
          </div>
        ))
      : getEmptyLevelContent();

  const getCoursesIfLoaded = (levelId) => {
    const courseEntry = levelCourses.find(
      (course) => course.levelId === levelId
    );

    return courseEntry?.content ? (
      renderCourse(courseEntry)
    ) : (
      <CircularProgress />
    );
  };

  const getAccessCodeAccordions = () =>
    levels.map((level) => (
      <GarnishAccordion
        key={level.id}
        startContent={getAccordionTitleCheckbox(level.id, level.title)}
        onToggle={() => handleAccordionToggled(level.id)}
      >
        {getCoursesIfLoaded(level.id)}
      </GarnishAccordion>
    ));

  const getLevelsForPostBody = () =>
    levelsSelected.map((levelId) => {
      const levelCoursesRecord = levelCourses.find(
        (levelCourse) => levelCourse.levelId === levelId
      );
      const exclusions = levelCoursesRecord
        ? levelCoursesRecord.exclusions
        : [];

      return {
        levelId,
        exclusions,
      };
    });

  const saveAccessCode = () => {
    setSaving(true);

    const newAccessCodeBody = {
      clientId: clientInfo.clientId,
      code,
      levels: getLevelsForPostBody(),
    };

    makeAuthenticatedRequest(getAccessCodesUrl(), false, {
      data: newAccessCodeBody,
      method: "POST",
    })
      .then(() => {
        enqueueSnackbar(`${code} created!`, {
          variant: "success",
        });

        history.push("/admin/access-codes");
      })
      .catch(() => {
        enqueueSnackbar("An error occurred saving the access code", {
          variant: "error",
        });

        setSaving(false);
      });
  };

  const handleAccessCodeSaved = () => {
    if (code.trim().length === 0) {
      enqueueSnackbar("Please supply a value for the new access code", {
        variant: "error",
      });
    } else if (levelsSelected.length === 0) {
      enqueueSnackbar(
        "Please select one or more courses to add to the access code",
        {
          variant: "error",
        }
      );
    } else if (!accessCodeAlreadyExists) {
      saveAccessCode();
    }
  };

  const getToolbarContent = () => (
    <Button
      variant="contained"
      color="primary"
      title="Save the access code"
      onClick={handleAccessCodeSaved}
      disabled={saving}
    >
      Save access code
    </Button>
  );

  return (
    <Page title="Add access code" toolbarContent={getToolbarContent()}>
      <Card>
        <EditField
          id="add-access-code"
          label="code"
          value={code}
          onChange={handleCodeChanged}
          className={classes.codeField}
          error={accessCodeAlreadyExists}
          helperText={
            accessCodeAlreadyExists
              ? "This code name is already being used"
              : null
          }
        />
        <div className="accordion-container">{getAccessCodeAccordions()}</div>
      </Card>
    </Page>
  );
}

export default AddAccessCodePage;
