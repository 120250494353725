import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import EditField from "../EditField";
import GarnishDialog from "../GarnishDialog";
import ImageUploader from "../ImageUploader";
import AddEditTranslatedTitles from "./AddEditTranslatedTitles";
import useTranslatedTitlesInDialog from "../../hooks/useTranslatedTitlesInDialog";

export default function AddEditLevelDialog(props) {
  const [title, setTitle] = useState(props.initialTitle || "");
  const [titleValidationError, setTitleValidationError] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const {
    titleTranslations,
    handleTranslatedTitleChanged,
    handleDeleteTranslatedTitleClicked,
    handleTranslationAdded,
    getTranslatedTitlesForUpload,
  } = useTranslatedTitlesInDialog(props.open, props.existingTitleTranslations);

  useEffect(() => {
    if (!props.open) {
      setTitle(props.initialTitle || "");
      setTitleValidationError(null);
    }
  }, [props.open, props.initialTitle, props.initialPassingGrade]);

  const handleTitleChanged = (event) => {
    setTitle(event.target.value);

    if (event.target.value.trim().length === 0) {
      setTitleValidationError("Please provide a title");
    } else {
      setTitleValidationError(null);
    }
  };

  const handleImageUpload = (file) => {
    setImageFile(file);
  };

  const handleConfirm = () => {
    if (!title) {
      enqueueSnackbar(`Please provide a title for your new level`, {
        variant: "error",
      });
    } else if (titleValidationError) {
      enqueueSnackbar(`Please fix form errors before submitting your course`, {
        variant: "error",
      });
    } else {
      props.onConfirm(title, imageFile, getTranslatedTitlesForUpload());
    }
  };

  return (
    <GarnishDialog
      title={props.title}
      open={props.open}
      onClose={props.onClose}
      onConfirm={handleConfirm}
      confirmText={props.confirmText}
      loading={props.loading}
    >
      <EditField
        id="title"
        label="title"
        onChange={handleTitleChanged}
        error={titleValidationError !== null}
        value={title}
        helperText={titleValidationError}
      />
      <AddEditTranslatedTitles
        id={props.levelId}
        titleTranslations={titleTranslations}
        handleTranslatedTitleChanged={handleTranslatedTitleChanged}
        handleDeleteTranslatedTitleClicked={handleDeleteTranslatedTitleClicked}
        handleTranslationAdded={handleTranslationAdded}
      />
      <ImageUploader
        label="Level completion image"
        onImageUpload={handleImageUpload}
        defaultImageSrc={props.initialImageSrc}
        isLoading={props.imageLoading}
      />
    </GarnishDialog>
  );
}
