import { useEffect, useRef, useState } from "react";
import EditField from "../components/EditField";
import { setToEventTargetValue } from "../util/setToEventTargetValue";

export const useSimpleTextInput = (editFieldProps) => {
  const [value, setValue] = useState("");
  const ref = useRef();
  const [invalid, setInvalid] = useState(true);

  // Relying on the class of the material input error is a bit of a hack -- there may
  // be a better way
  useEffect(() => {
    if (
      !ref.current ||
      ref.current
        ?.querySelector(".MuiInputBase-root")
        .classList.contains("Mui-error") ||
      (editFieldProps.required && value.trim().length === 0)
    ) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [value, editFieldProps.required]);

  const getEditField = () => (
    <EditField
      textFieldRef={ref}
      {...editFieldProps}
      value={value}
      onChange={setToEventTargetValue(setValue)}
    ></EditField>
  );

  const clear = () => setValue("");

  return {
    value,
    editField: getEditField,
    clear,
    invalid,
  };
};
