import { useState } from "react";
import Page from "../components/Page";
import Card from "../components/Card";
import { useUploadImage } from "../hooks/useImageUpload";
import { Button, makeStyles } from "@material-ui/core";
import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";
import ImageUploader from "../components/ImageUploader";
import DivisionList from "../components/clients/DivisionList";
import AddEditDivisionDialog from "../components/clients/AddEditDivisionDialog";
import { updateObjectAtIndex } from "../util/arrayUtils";
import { getClientsUrl, getClientWatermarkUrl, getDivisionIconUrl, getDivisionsUrl } from "../util/getUrls";
import { useHistory } from "react-router-dom";
import DivisionTypeSelect from "../components/clients/DivisionTypeSelect";
import ClientNameInput from "../components/clients/ClientNameInput";

const useStyles = makeStyles({
    clientInfoContainer: {
        display: "flex",
        flexDirection: "column",
        width: "30rem",
    },
    label: {
        textAlign: "center"
    },
    clientImageUploaderWrapper: {
        marginTop: "2rem"
    },
    divisionsCard: {
        margin: "2rem 0"
    },
})


export default function AddClient() {
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(null)
    const [nameDirty, setNameDirty] = useState(false)
    const [divisionType, setDivisionType] = useState("")
    const [watermarkIconSrc, setWatermarkIconSrc] = useState(null);
    const [watermarkIconFile, setWatermarkIconFile] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const [saving, setSaving] = useState(false);
    const [divisionBeingEdited, setDivisionBeingEdited] = useState(null);
    const [showAddEditDivisionDialog, setShowAddEditDivisionDialog] = useState(false)

    const uploadImage = useUploadImage();
    const makeAuthenticatedRequest = useAuthenticatedRestCall();

    const history = useHistory()

    const classes = useStyles();

    const handleUpdateWatermark = (file, url) => {
        setWatermarkIconFile(file)
        setWatermarkIconSrc(url)
    }

    const onEditDivisionDialogClosed = () => {
        setShowAddEditDivisionDialog(false)
        setDivisionBeingEdited(null)
    }

    const onAddDivisionClicked = () => {
        setShowAddEditDivisionDialog(true)
    }

    const onDivisionEditClicked = (divisionId) => {
        setShowAddEditDivisionDialog(true)
        setDivisionBeingEdited(divisions.find(division => division.id === divisionId))
    }

    const getAddEditDivisionDialogTitle = () => divisionBeingEdited ? "Edit Division" : "Add Division"

    const editDivision = (divisionId, updatedName, updatedIconFile) => {
        const existingDivisionIndex = divisions.findIndex(division => division.id === divisionId)
        const updatedDivision = { ...divisions[existingDivisionIndex], name: updatedName }

        if (updatedIconFile) {
            updatedDivision.iconFile = updatedIconFile
        }

        setDivisions(updateObjectAtIndex(divisions, existingDivisionIndex, updatedDivision))
    }

    const onDivisionAddEditConfirmed = (update) => {
        const { divisionId, updatedName, updatedIconFile, updatedIconUrl } = update

        setSaving(true)

        if (divisionId) {
            editDivision(divisionId, updatedName, updatedIconFile)
        } else {
            setDivisions([...divisions, { id: Math.random(), name: updatedName, iconUrl: updatedIconUrl, iconFile: updatedIconFile }])
        }

        setSaving(false)
        setShowAddEditDivisionDialog(false)
    }

    const onDeleteDivision = (divisionId) => {
        setDivisions(divisions.filter(division => division.id !== divisionId))
    }

    const saveDivision = async (clientId, division) => {
        const newDivisionResponse = await makeAuthenticatedRequest(getDivisionsUrl(clientId), true, {
            data: {
                name: division.name
            },
            method: "POST",
        })

        const newDivision = newDivisionResponse.data

        if (division.iconFile) {
            await uploadImage(getDivisionIconUrl(clientId, newDivision.id), division.iconFile)
        }
    }

    const saveNewClient = async () => {
        const newClientResponse = await makeAuthenticatedRequest(getClientsUrl(), true, {
            data: {
                name,
                divisionType
            },
            method: "POST",
        })

        const newClient = newClientResponse.data

        if (watermarkIconFile) {
            await uploadImage(getClientWatermarkUrl(newClient.id), watermarkIconFile)
        }

        return newClient
    }

    const saveNewDivisions = async (newClient) => {
        const divisionRequests = divisions.map(division => saveDivision(newClient.id, division))

        return Promise.all(divisionRequests)
    }

    const saveNewClientWithDivisions = async () => {
        if (name.length === 0) {
            setNameDirty(true)
        } else {
            setSaving(true)

            const newClient = await saveNewClient()

            await saveNewDivisions(newClient)

            setSaving(false)

            history.push("/clients")
        }
    }

    const toolBarContent = (<Button variant="contained" color="primary" onClick={saveNewClientWithDivisions}>Save new client</Button>)

    return (
        <Page title="Add client" toolbarContent={toolBarContent}>
            <Card>
                <div className={classes.clientInfoContainer}>
                    <ClientNameInput value={name} error={nameError} onError={error => setNameError(error)} onChange={name => setName(name)} dirty={nameDirty} />
                    <DivisionTypeSelect value={divisionType} onChange={value => setDivisionType(value)} />
                    <div className={classes.clientImageUploaderWrapper}>
                        <ImageUploader className={classes.clientImageUploader} label="Watermark logo" onImageUpload={handleUpdateWatermark} disabled={saving} defaultImageSrc={watermarkIconSrc} />
                    </div>
                </div>
            </Card>
            <Card className={classes.divisionsCard}>
                <h1>Divisions</h1>
                <DivisionList divisions={divisions} disabled={saving} onDivisionEditClicked={onDivisionEditClicked} onAddDivisionClicked={onAddDivisionClicked} onDeleteDivision={onDeleteDivision} allowDeletion={true} />
                <AddEditDivisionDialog open={showAddEditDivisionDialog} title={getAddEditDivisionDialogTitle()} division={divisionBeingEdited} onConfirm={onDivisionAddEditConfirmed} onClose={onEditDivisionDialogClosed} disabled={saving} />
            </Card>
        </Page>
    )
}

