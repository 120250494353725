import { CircularProgress, makeStyles, Paper, Slide } from "@material-ui/core";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDefaultLanguage } from "../../hooks/useDefaultLanguage";
import { useSlideAnimation } from "../../hooks/useSlideAnimation";
import GentleGrowTransition from "../GentleGrowTransition";
import QuizEditor from "./quizEditor/QuizEditor";
import SlateEditor from "./slateEditor/SlateEditor";

const useStyles = makeStyles(theme => ({
  editorContainer: {
    minHeight: 0,
    flexGrow: "1",
    position: "relative",
    "&.hidden": {
      display: "none",
    },
    "&.side-by-side": {
      margin: "1rem",
    },
    "&.quiz-mode": {
      overflowY: "auto",
    },
  },
  editorCard: {
    height: "100%",
    justifyContent: "center",
    gap: "1rem",
    display: "flex",
    "&:not(.side-by-side) $editorWithTitleWrapper": {
      width: "70%",

      [theme.breakpoints.down('md')]: {
        width: "80%"
      },
      [theme.breakpoints.down('sm')]: {
        width: "95%"
      }
    }
  },
  editorWithTitleWrapper: {
    height: "100%",
    minHeight: 0,
    width: "50%",
    display: "flex",
    flexDirection: "column",
    transition: "opacity 200ms ease",
  },
  originalContentTitle: {
    fontSize: "3.2rem",
    fontFamily: "Montserrat",
    color: "var(--garnish-dark-green)",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    paddingBottom: "9.7rem",
    borderBottom: `1px solid var(--dark-grey)`,
  },
  originalContentLabel: {
    fontSize: "1.5rem",
    marginLeft: "auto",
    background: "var(--dark-grey)",
    padding: ".5rem 1rem",
    borderRadius: ".5rem",
    fontWeight: "normal",
  },
  translationEditorWrapper: {
    width: "50%",
  },
  editorContentSavingLoadingSpinner: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

function ContentEditor({
  saving,
  quizMode,
  editorContent,
  setEditorContent,
  languageToEditId,
  translationContent,
  setTranslatedContentForLanguageBeingEdited,
  untranslatedTitle,
  loading,
}) {
  const classes = useStyles();
  const originalLanguageEditorRef = useRef(null);
  const getSavingLoadingSpinner = () =>
    saving ? (
      <div className={classes.editorContentSavingLoadingSpinner}>
        <CircularProgress />
      </div>
    ) : null;
  const { defaultLanguage, defaultLanguageLoaded } = useDefaultLanguage();
  const [originalContentScrollTop, setOriginalContentScrollTop] = useState(0);
  // This value is used to store the last translated language so that we can display content in the editor
  // as it transitions off-screen. The translation state is reset when the user toggles back to English, but we hold onto
  // the old contents so the editor doesn't go blank as it slides downwards. :)
  const [lastTranslationLanguageId, setLastTranslationLanguageId] =
    useState(null);

  const translationEditMode = useMemo(
    () => defaultLanguageLoaded && languageToEditId !== defaultLanguage.id,
    [defaultLanguageLoaded, languageToEditId, defaultLanguage]
  );

  useEffect(() => {
    if (translationContent[languageToEditId]) {
      setLastTranslationLanguageId(languageToEditId);
    }
  }, [translationContent, languageToEditId]);

  const animateOriginalLanguageEditor = useSlideAnimation(
    originalLanguageEditorRef,
    lastTranslationLanguageId,
    translationEditMode
  );

  useEffect(() => {
    if (originalLanguageEditorRef.current && !loading) {
      animateOriginalLanguageEditor();
    }
  }, [
    translationEditMode,
    lastTranslationLanguageId,
    loading,
    animateOriginalLanguageEditor,
  ]);

  const getTranslationContentForLanguageToEditId = useCallback(
    () =>
      translationContent[languageToEditId] ||
      translationContent[lastTranslationLanguageId],
    [languageToEditId, translationContent, lastTranslationLanguageId]
  );

  const handleTranslationEditorScrolled = (event) => {
    setOriginalContentScrollTop(event.target.scrollTop);
  };

  const getUntranslatedTitleIfInTranslationMode = () =>
    translationEditMode ? (
      <Paper className={classes.originalContentTitle} elevation={0}>
        {untranslatedTitle}{" "}
        <span className={classes.originalContentLabel}>
          original English content
        </span>
      </Paper>
    ) : null;

  return (
    <Fragment>
      {getSavingLoadingSpinner()}
      <div
        className={`${classes.editorContainer} ${saving ? "hidden" : ""} ${translationEditMode ? "side-by-side" : ""
          } ${quizMode ? "quiz-mode" : ""}`}
      >
        <GentleGrowTransition show={!quizMode}>
          <div
            className={`${classes.editorCard} ${translationEditMode ? "side-by-side" : ""
              }`}
          >
            <div
              className={classes.editorWithTitleWrapper}
              ref={originalLanguageEditorRef}
            >
              {getUntranslatedTitleIfInTranslationMode()}
              <SlateEditor
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                readOnly={translationEditMode}
                editorScrollTop={originalContentScrollTop}
              />
            </div>
            <Slide
              direction="up"
              in={translationEditMode}
              mountOnEnter
              unmountOnExit
            >
              <div className={classes.translationEditorWrapper}>
                <SlateEditor
                  editorContent={getTranslationContentForLanguageToEditId()}
                  setEditorContent={setTranslatedContentForLanguageBeingEdited}
                  onScroll={handleTranslationEditorScrolled}
                />
              </div>
            </Slide>
          </div>
        </GentleGrowTransition>
        <GentleGrowTransition show={quizMode}>
          <QuizEditor
            quizMode={quizMode}
            translationMode={translationEditMode}
            languageToEditId={languageToEditId}
            translationModeHasBeenTriggered={lastTranslationLanguageId}
          />
        </GentleGrowTransition>
      </div>
    </Fragment>
  );
}

export default ContentEditor;
