const BASE_AUTH_URL = `${process.env.REACT_APP_AUTH_URL}/admin/realms/${process.env.REACT_APP_AUTH_REALM_NAME}`;

export const getAuthAdminsUrl = () => `${BASE_AUTH_URL}/roles/admin/users`;

export const getAuthUsersUrl = () => `${BASE_AUTH_URL}/users`;

export const getAuthUserRealmRoleMappingsUrl = (userId) =>
  `${BASE_AUTH_URL}/users/${userId}/role-mappings/realm`;

export const getAuthUserClientRoleMappingsUrl = (userId) =>
  `${BASE_AUTH_URL}/users/${userId}/role-mappings/clients/${process.env.REACT_APP_KEYCLOAK_REALM_MANAGEMENT_CLIENT_ID}`;

export const getAccessCodesUrl = () =>
  `${process.env.REACT_APP_COURSE_SERVICE_URL}/access-codes`;

export const getAccessCodeByCodeUrl = (code) =>
  `${getAccessCodesUrl()}?code=${code}`;

export const getAccessCodeUrl = (accessCodeId) =>
  `${getAccessCodesUrl()}/${accessCodeId}`;

export const getAccessCodeListUrl = (clientId) =>
  `${getAccessCodesUrl()}?clientId=${clientId}`;

export const getLevel = (levelId) =>
  `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${levelId}`;

export const getLevelsForClientUrl = (clientId) =>
  `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels?clientId=${clientId}`;

export const getCourseUrl = (levelId, courseId) =>
  `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${levelId}/courses/${courseId}`;

export const getContentUrl = (levelId, courseId, contentId) =>
  `${getCourseUrl(levelId, courseId)}/content/${contentId}`;

export const getRichContentUrl = (levelId, courseId, contentId) =>
  `${getContentUrl(levelId, courseId, contentId)}/rich-content`;

export const getRichContentTranslationUrl = (
  levelId,
  courseId,
  contentId,
  languageId
) =>
  `${getRichContentUrl(
    levelId,
    courseId,
    contentId,
    languageId
  )}/translations/${languageId}`;

export const getClientsUrl = () => `${process.env.REACT_APP_CLIENT_SERVICE_URL}/clients`
export const getClientUrl = (clientId) => `${getClientsUrl()}/${clientId}`
export const getDivisionsUrl = (clientId) => `${process.env.REACT_APP_CLIENT_SERVICE_URL}/clients/${clientId}/divisions`
export const getDivisionUrl = (clientId, divisionId) => `${getDivisionsUrl(clientId)}/${divisionId}`
export const getClientWatermarkUrl = (clientId) => `${process.env.REACT_APP_IMAGE_SERVICE_URL}/clients/${clientId}/watermark`
export const getDivisionIconUrl = (clientId, divisionId) => `${process.env.REACT_APP_IMAGE_SERVICE_URL}/clients/${clientId}/divisions/${divisionId}/icon`
