import { useRef, Fragment } from 'react';
import { useSlate, useSlateStatic } from 'slate-react';

import { Divider, Paper, Button, ButtonGroup } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// Icons
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaLink,
  FaRegEye,
} from 'react-icons/fa';
import { VscHorizontalRule } from 'react-icons/vsc';
import {
  MdFormatListNumbered,
  MdFormatListBulleted,
  MdFormatQuote,
  MdLooksOne,
  MdLooksTwo,
  MdLooks3,
  MdLooks4,
  MdLooks5,
  MdImage,
  MdOndemandVideo,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
} from 'react-icons/md';
import { ReactComponent as GarnishTipIcon } from '../../../images/garnish-twist.svg';
import { ReactComponent as DidYouKnowIcon } from '../../../images/did-you-know-grey.svg';
import { ReactComponent as DefinitionIcon } from '../../../images/wordgroup-grey.svg';

import {
  isBlockActive,
  isMarkActive,
  isAlignmentActive,
  isLinkNodeAtSelection,
  toggleBlock,
  toggleMark,
  toggleLinkAtSelection,
  toggleAlignment,
  insertVideo,
} from './SlateEditorUtils';

import { useUploadCourseContentImage } from '../../../hooks/useUploadCourseContentImage';

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const AlignButton = ({ align, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={align}
      selected={isAlignmentActive(editor, align)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleAlignment(editor, align);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const MediaButton = ({ icon, ...props }) => {
  return (
    <Button size="small" {...props}>
      {icon}
    </Button>
  );
};

const LinkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isLinkNodeAtSelection(editor, editor.selection)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleLinkAtSelection(editor);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

export const SlateToolbar = ({ selection, previousSelection }) => {
  const classes = useStyles();
  const editor = useSlateStatic();
  const onImageSelected = useUploadCourseContentImage(
    editor,
    previousSelection
  );
  const inputImgFile = useRef(null);

  const handleInsertVideo = () => {
    const url = prompt('Enter a video URL');
    insertVideo(editor, url);
  };

  const handleImageUploadClick = () => {
    inputImgFile.current.click();
  };
  return (
    <Fragment>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup size="small" arial-label="text formatting">
          {MarkButton({ format: 'bold', icon: <FaBold size={18} /> })}
          {MarkButton({
            format: 'italic',
            icon: <FaItalic size={18} />,
          })}
          {MarkButton({
            format: 'underline',
            icon: <FaUnderline size={18} />,
          })}
          {LinkButton({
            format: 'link',
            icon: <FaLink size={18} />,
          })}
        </StyledToggleButtonGroup>
        <Divider orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup
          size="small"
          arial-label="text alignment"
          exclusive
        >
          {AlignButton({
            align: 'align-left',
            icon: <MdFormatAlignLeft size={18} />,
          })}
          {AlignButton({
            align: 'align-center',
            icon: <MdFormatAlignCenter size={18} />,
          })}
          {AlignButton({
            align: 'align-right',
            icon: <MdFormatAlignRight size={18} />,
          })}
        </StyledToggleButtonGroup>
        <Divider orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup
          size="small"
          arial-label="text formatting"
          exclusive
        >
          {BlockButton({
            format: 'heading-one',
            icon: <MdLooksOne size={24} />,
          })}
          {BlockButton({
            format: 'heading-two',
            icon: <MdLooksTwo size={24} />,
          })}
          {BlockButton({
            format: 'heading-three',
            icon: <MdLooks3 size={24} />,
          })}
          {BlockButton({
            format: 'heading-four',
            icon: <MdLooks4 size={24} />,
          })}
          {BlockButton({
            format: 'heading-five',
            icon: <MdLooks5 size={24} />,
          })}
        </StyledToggleButtonGroup>
        <Divider orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup
          size="small"
          arial-label="collapsibles"
          exclusive
        >
          {BlockButton({
            format: 'blockquote',
            icon: <MdFormatQuote size={24} />,
          })}
          {BlockButton({
            format: 'example',
            icon: <FaRegEye size={24} />,
          })}
          {BlockButton({
            format: 'garnish-tip',
            icon: <GarnishTipIcon className={classes.garnishTipIcon} />,
          })}
          {BlockButton({
            format: 'did-you-know',
            icon: <DidYouKnowIcon className={classes.didYouKnowIcon} />,
          })}
          {BlockButton({
            format: 'definition',
            icon: <DefinitionIcon className={classes.definitionIcon} />,
          })}
          {BlockButton({
            format: 'divider',
            icon: <VscHorizontalRule size={30} />,
          })}
        </StyledToggleButtonGroup>
      </Paper>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          arial-label="ordered lists"
          exclusive
        >
          {BlockButton({
            format: 'bulleted-list',
            icon: <MdFormatListBulleted size={24} />,
          })}
          {BlockButton({
            format: 'bulleted-list-standard',
            icon: <MdFormatListBulleted size={24} />,
          })}
          {BlockButton({
            format: 'bulleted-list-fancy',
            icon: <MdFormatListBulleted size={24} />,
          })}
        </StyledToggleButtonGroup>
        <Divider orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup
          size="small"
          arial-label="ordered lists"
          exclusive
        >
          {BlockButton({
            format: 'numbered-list',
            icon: <MdFormatListNumbered size={24} />,
          })}
          {BlockButton({
            format: 'numbered-list-standard',
            icon: <MdFormatListNumbered size={24} />,
          })}
          {BlockButton({
            format: 'numbered-list-fancy',
            icon: <MdFormatListNumbered size={24} />,
          })}
        </StyledToggleButtonGroup>
        <Divider orientation="vertical" className={classes.divider} />
        <StyledButtonGroup size="small">
          {MediaButton({
            icon: <MdImage size={26} />,
            onMouseDown: handleImageUploadClick,
          })}
          {MediaButton({
            icon: <MdOndemandVideo size={26} />,
            onMouseDown: handleInsertVideo,
          })}
        </StyledButtonGroup>
        <input
          ref={inputImgFile}
          id="file-upload"
          hidden
          className={classes.input}
          type="file"
          accept=".png,.jpg"
          onChange={onImageSelected}
        />
      </Paper>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'center',
      borderBottom: `1px solid var(--dark-grey)`,
      padding: '10px 0',
      flexWrap: 'wrap',
    },
    divider: {
      alignSelf: 'stretch',
      height: 'auto',
      margin: theme.spacing(1, 0.5),
    },
    button: {
      border: 'none',
    },
    input: {
      display: 'none',
    },
    didYouKnowIcon: {
      height: '26px',
    },
    garnishTipIcon: {
      height: '26px',
    },
    definitionIcon: {
      height: '24px',
    },
    dropButton: {
      color: 'rgba(0, 0, 0, 0.38)',
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(0.5),
      border: 'none',
      padding: theme.spacing(0, 1.5),
      minWidth: 0,
    },
  })
);

const StyledButtonGroup = withStyles((theme) => ({
  grouped: {
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0.5),
    border: 'none',
    padding: theme.spacing(0, 1.5),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ButtonGroup);

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: "contents"
  },
  grouped: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0.5),
    border: 'none',
    padding: theme.spacing(0, 1.5),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);
