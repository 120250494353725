import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Page from '../components/Page';
import Card from '../components/Card';

import useAuthenticatedRestCall from '../hooks/useAuthenticatedRestCall';

const useStyles = makeStyles({
  clientWrapper: {
    maxWidth: '500px',
  },
  clientTitle: {
    fontSize: '1.8rem',
    textTransform: 'uppercase !important',
    fontFamily: 'Montserrat',
    letterSpacing: '0.2rem',
    fontWeight: 'bold',
    color: 'var(--garnish-dark-green)',
  },
  clientCard: {
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '25px',
  },
});

export default function ClientPage() {
  const classes = useStyles();
  const history = useHistory();
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    const fetchClientsAndSetState = async () => {
      try {
        const response = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_CLIENT_SERVICE_URL}/clients?paginated=false`
        );
        const clientList = response.data.docs;

        setClientList(clientList);
      } catch (error) {}
    };

    fetchClientsAndSetState();
  }, [makeAuthenticatedRequest]);

  const handleAddClientClicked = async () => {
    history.push(`/clients/add-new-client`);
  };

  const handleEditClientClicked = async (clientId) => {
    history.push(`/clients/${clientId}/edit`);
  };

  const getToolbarButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        title="Add a new client"
        startIcon={<AddIcon />}
        onClick={handleAddClientClicked}
      >
        Add client
      </Button>
    </Fragment>
  );

  return (
    <Page title="Clients" toolbarContent={getToolbarButtons()}>
      <div className={classes.clientWrapper}>
        {clientList.map((client) => (
          <Card key={client.id} className={classes.clientCard}>
            <h2 className={classes.clientTitle}>{client.name}</h2>
            <Button
              variant="contained"
              color="primary"
              title="Add a new client"
              startIcon={<EditIcon />}
              onClick={() => handleEditClientClicked(client.id)}
            >
              Edit Client
            </Button>
          </Card>
        ))}
      </div>
    </Page>
  );
}
