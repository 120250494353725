import React, { useMemo, useCallback } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import Device from './Device';
import DevicePreviewHeader from './DevicePreviewHeader';
import DevicePreviewFooter from './DevicePreviewFooter';
import { SlateLeaf, SlateElement } from '../../hooks/useEditorConfig';

const DevicePreview = ({ title, previewContent }) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback(
    (props) => <SlateElement previewMode={true} {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);
  return (
    <Device device="nexus5" scale=".9">
      <div className="preview-content__wrapper">
        <DevicePreviewHeader title={title} />
        <Slate editor={editor} value={previewContent}>
          <Editable
            className="preview-content"
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Slate>
      </div>
      <DevicePreviewFooter />
    </Device>
  );
};

export default DevicePreview;
