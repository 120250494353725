/**
 * A convenience function to handle onChange events for input changes that have no side effects
 * other than setting some value through a useState setter
 * 
 * @example
 * const [value, setValue] = useState("");
 * <Input value={value} onChange={setToEventTargetValue(setValue)} />
 * @param {*} setter 
 * @returns 
 */
export const setToEventTargetValue = (setter) => (event) =>
  setter(event.target.value);
