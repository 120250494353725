import { IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Close";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";
import EditField from "../EditField";

const useStyles = makeStyles({
  translatedTitleInputWrapper: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
  deleteButton: {
    height: "4rem",
  },
});

export default function TranslatedTitleInput(props) {
  const classes = useStyles();

  const { languageInfo } = useContext(LanguageContext);

  const getLabelForLanguageId = (languageId) =>
    languageInfo.loading | !languageId
      ? ""
      : languageInfo.languages.find((language) => language.id === languageId)
          .displayName;

  return (
    <div className={classes.translatedTitleInputWrapper}>
      <EditField
        className={classes.input}
        autoFocus
        id={`${props.id}-${props.languageId}-edit`}
        key={props.languageId}
        label={`${getLabelForLanguageId(props.languageId)} title`}
        onChange={(event) =>
          props.onTranslatedTitleChanged(event.target.value, props.languageId)
        }
        value={props.value}
      />
      <IconButton
        className={classes.deleteButton}
        onClick={() => props.onDeleteClicked(props.languageId)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
