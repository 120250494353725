import React, { useContext } from "react";
import AddTranslation from "./AddTranslation";
import LanguageContext from "../../contexts/LanguageContext";
import TranslatedTitleInput from "./TranslatedTitleInput";

export default function AddEditTranslatedTitles(props) {
  const { languageInfo } = useContext(LanguageContext);

  const translatedTitleInputs = props.titleTranslations.map((translation) => (
    <TranslatedTitleInput
      id={props.id}
      key={translation.languageId}
      languageId={translation.languageId}
      value={translation.translatedValue}
      onTranslatedTitleChanged={props.handleTranslatedTitleChanged}
      onDeleteClicked={props.handleDeleteTranslatedTitleClicked}
    />
  ));

  // We subtract one because the default, English, cannot be added as a translation
  const allLanguagesAdded =
    props.titleTranslations.length === languageInfo.languages.length - 1;

  const renderAddTranslationIfApplicable = () =>
    allLanguagesAdded ? null : (
      <AddTranslation
        id={`${props.id}-new-language-select`}
        buttonLabel="Add title translation"
        onTranslationAdded={props.handleTranslationAdded}
        existingTranslations={props.titleTranslations}
      />
    );

  return (
    <React.Fragment>
      {translatedTitleInputs}
      {renderAddTranslationIfApplicable()}
    </React.Fragment>
  );
}
