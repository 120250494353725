import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import EditField from "../EditField";
import GarnishDialog from "../GarnishDialog";
import ImageUploader from "../ImageUploader";

const useStyles = makeStyles({
  dialogWrapper: {
    width: "30rem",
    marginLeft: "auto",
    marginRight: "auto"
  }
})

const DIVISION_NAME_REQUIRED_ERROR = "A name for the division is required"

function AddEditDivisionDialog(props) {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [iconFile, setIconFile] = useState(null)
  const [iconUrl, setIconUrl] = useState('')
  const [divisionNameError, setDivisionNameError] = useState(null)

  useEffect(() => {
    setDivisionNameError(null)

    if (props.division) {
      setName(props.division.name)
    } else {
      setName("")
    }
  }, [props.division, props.open])

  const onDivisionIconUploaded = (iconFile, url) => {
    setIconFile(iconFile)
    setIconUrl(url)
  }

  const handleNameChanged = (event) => {
    if (event.target.value.trim().length === 0) {
      setDivisionNameError(DIVISION_NAME_REQUIRED_ERROR)
    } else {
      setDivisionNameError(null)
    }

    setName(event.target.value)
  }

  const handleConfirm = () => {
    if (name.trim().length === 0) {
      setDivisionNameError(DIVISION_NAME_REQUIRED_ERROR)
    } else {
      props.onConfirm({ divisionId: props.division?.id, updatedName: name, updatedIconFile: iconFile, updatedIconUrl: iconUrl })
    }
  }

  return (
    <GarnishDialog
      title={props.title}
      open={props.open}
      onClose={props.onClose}
      onConfirm={handleConfirm}
      confirmText="save"
      confirming={props.disabled}>
      <div className={classes.dialogWrapper}>
        <EditField
          id="edit-division-name"
          label="name"
          onChange={handleNameChanged}
          error={divisionNameError !== null}
          value={name}
          helperText={divisionNameError}
          disabled={props.disabled}
        />
        <ImageUploader
          label="Division Icon"
          onImageUpload={onDivisionIconUploaded}
          defaultImageSrc={props.initialDivisionIconSrc}
          isLoading={props.imageLoading}
          disabled={props.disabled}
        />
      </div>
    </GarnishDialog>
  );
}

AddEditDivisionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  division: PropTypes.object,
  disabled: PropTypes.bool
}

export default AddEditDivisionDialog