import { IconButton, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Close';
import { Fragment, useMemo, useState } from 'react';
import { renderWithToolTip } from '../../util/gridUtils';

const useStyles = makeStyles({
  adminGrid: {
    color: 'var(--darker-grey)',
    '& .MuiDataGrid-cell[data-field="name"]': {
      fontWeight: '500',
      color: '#212529',
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 'normal',
      color: '#3A3B3F',
    },
  },
});

const mapAdminResponseToRows = (response) =>
  response.map((admin) => ({
    name: `${admin.firstName || ''} ${admin.lastName || ''}`,
    email: admin.email,
    id: admin.id,
  }));

function AdminGrid(props) {
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        field: 'editSpacer',
        headerName: ' ',
        width: 60,
        renderCell: (params) => (
          <Fragment>
            <IconButton
              className={classes.deleteButton}
              onClick={() => props.onDeleteClicked(params.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Fragment>
        ),
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.15,
        renderCell: renderWithToolTip,
      },
      {
        field: 'email',
        headerName: 'Email Address',
        flex: 0.9,
        renderCell: renderWithToolTip,
      },
    ],
    [props, classes.deleteButton]
  );

  const [pageSize, setPageSize] = useState(25);

  const adminRows = useMemo(() => {
    return mapAdminResponseToRows(props.admins);
  }, [props.admins]);

  return (
    <DataGrid
      className={classes.adminGrid}
      rows={adminRows}
      columns={columns}
      error={props.error}
      loading={props.loading}
      autoHeight
      disableColumnSelector
      disableSelectionOnClick
      pagination
      pageSize={pageSize}
      onPageSizeChange={(params) => setPageSize(params.pageSize)}
    />
  );
}

export default AdminGrid;
