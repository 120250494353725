import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import useAuthenticatedRequest from "../hooks/useAuthenticatedRestCall";
import AddEditCourseContent from "../components/AddEditCourseContent";
import { CONTENT_TYPES } from "../constants/contentTypes";
import QuizEditorContext from "../contexts/QuizEditorContext";
import {
  getContentUrl,
  getRichContentTranslationUrl,
  getRichContentUrl,
} from "../util/getUrls";
import { useTranslatedContentPayload } from "../hooks/useTranslatedContentPayload";
import { useInitializeTranslatedContent } from "../hooks/useInitializeTranslatedContent";
import { removeEmptyTranslations } from "../util/translationUtils";

function EditCourseContent() {
  let { levelId, courseId, contentId } = useParams();
  const makeAuthenticatedRequest = useAuthenticatedRequest();
  const [title, setTitle] = useState("");
  const [published, setPublished] = useState(false);
  const [richContent, setRichContent] = useState(null);
  const [quillContents, setQuillContents] = useState(null);
  const [isQuiz, setIsQuiz] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [displayAllErrors, setDisplayAllErrors] = useState(false);
  const [emptyQuestions, setEmptyQuestions] = useState([]);
  const [emptyAnswers, setEmptyAnswers] = useState([]);
  const [translatedContent, setTranslatedContent] = useState({});
  const [questionTranslations, setQuestionTranslations] = useState([]);
  const [existingTranslatedTitles, setExistingTranslatedTitles] =
    useState(null);
  const [existingTranslations, setExistingTranslations] = useState([]);
  const [translatedTitles, setTranslatedTitles] = useState({});
  const { getTitleTranslations, getTranslatedRichContentPayload } =
    useTranslatedContentPayload(translatedTitles, translatedContent);

  useInitializeTranslatedContent(
    setTranslatedContent,
    setTranslatedTitles,
    existingTranslations,
    existingTranslatedTitles
  );

  const getRichContentTranslation = useCallback(
    async (languageId) => {
      const response = await makeAuthenticatedRequest(
        getRichContentTranslationUrl(levelId, courseId, contentId, languageId)
      );

      return { languageId: languageId, translatedValue: response.data };
    },
    [contentId, courseId, levelId, makeAuthenticatedRequest]
  );

  useEffect(() => {
    const fetchRichContent = async () =>
      makeAuthenticatedRequest(
        getRichContentUrl(levelId, courseId, contentId),
        true
      );

    const fetchContent = async () => {
      const response = await makeAuthenticatedRequest(
        getContentUrl(levelId, courseId, contentId),
        true
      );

      const thisContentIsAQuiz = response.data.type === CONTENT_TYPES.quiz;

      setTitle(response.data.title);
      setPublished(response.data.published);
      setIsQuiz(thisContentIsAQuiz);
      setExistingTranslatedTitles(response.data.titleTranslations);

      if (!thisContentIsAQuiz) {
        const existingTranslationsRequests =
          response.data.availableTranslations.map(getRichContentTranslation);

        const existingTranslationResponse = await Promise.all(
          existingTranslationsRequests
        );

        setExistingTranslations(existingTranslationResponse);
        const richContentToDisplay = await fetchRichContent();
        setRichContent(richContentToDisplay.data);
      } else {
        setQuestions(response.data.questions);
      }
    };

    fetchContent();
  }, [
    makeAuthenticatedRequest,
    levelId,
    courseId,
    contentId,
    getRichContentTranslation,
  ]);

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const getAnswersWithoutEmptyTranslations = (answers) =>
    answers.map((answer) => ({
      ...answer,
      answerTextTranslations: removeEmptyTranslations(
        answer.answerTextTranslations
      ),
      feedbackTextTranslations: removeEmptyTranslations(
        answer.feedbackTextTranslations
      ),
    }));

  const getQuestionsWithoutEmptyTranslations = () =>
    questions.map((question) => ({
      ...question,
      questionTextTranslations: removeEmptyTranslations(
        question.questionTextTranslations
      ),
      answers: getAnswersWithoutEmptyTranslations(question.answers),
    }));

  const getPayload = () => {
    const translationInfo = {
      titleTranslations: getTitleTranslations(),
      richContentTranslations: getTranslatedRichContentPayload(),
    };

    if (!isQuiz) {
      return {
        title,
        published,
        ...translationInfo,
      };
    }

    return {
      title,
      published,
      questions: getQuestionsWithoutEmptyTranslations(questions),
      ...translationInfo,
    };
  };

  const updateContent = () =>
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${levelId}/courses/${courseId}/content/${contentId}`,
      true,
      {
        data: getPayload(),
        method: "PUT",
      }
    );

  const handleRichContentEdited = useCallback((updatedContents) => {
    setQuillContents(updatedContents);
  }, []);

  const handlePublishedChanged = (event) => setPublished(event.target.checked);

  const initialQuizContextValue = {
    questions,
    setQuestions,
    emptyQuestions,
    setEmptyQuestions,
    emptyAnswers,
    setEmptyAnswers,
    displayAllErrors,
    setDisplayAllErrors,
    questionTranslations,
    setQuestionTranslations,
  };

  const getLoading = () =>
    isQuiz ? questions.length === 0 : richContent === null;

  return (
    <QuizEditorContext.Provider value={initialQuizContextValue}>
      <AddEditCourseContent
        title={title}
        onTitleChange={handleTitleChange}
        isLoading={getLoading()}
        onRichContentEdited={handleRichContentEdited}
        initialRichContent={richContent?.content}
        published={published}
        onPublishedChanged={handlePublishedChanged}
        isQuiz={isQuiz}
        showQuizModeToggle={false}
        saveRequest={updateContent}
        quillContents={quillContents}
        translatedContent={translatedContent}
        setTranslatedContent={setTranslatedContent}
        saveSuccessMessage={`${title} updated successfully!`}
        translatedTitles={translatedTitles}
        setTranslatedTitles={setTranslatedTitles}
      />
    </QuizEditorContext.Provider>
  );
}

export default EditCourseContent;
