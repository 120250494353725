import { Fragment, useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Page from "../components/Page";
import AdminGrid from "../components/admin/AdminGrid";
import GarnishDialog from "../components/GarnishDialog";
import { useSimpleTextInput } from "../hooks/useSimpleTextInput";
import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";
import { useSnackbar } from "notistack";
import { useCreateAdminAuthUser } from "../hooks/useCreateAdminAuthUser";
import { getAuthAdminsUrl, getAuthUsersUrl } from "../util/getUrls";
import { VALID_EMAIL_REGEX } from "../constants/inputRegexes";

function AdminUsersPage() {
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [loadingAdmins, setLoadingAdmins] = useState(true);
  const [errorOccurredWhileLoading, setErrorOccurredWhileLoading] =
    useState(null);
  const [saving, setSaving] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [showRequiredFieldsErrors, setShowRequiredFieldsErrors] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  const handleAddAdminClicked = () => setAddUserDialogOpen(true);
  const handleAddAdminClosed = () => setAddUserDialogOpen(false);

  const handleDeleteAdminClosed = () => setDeleteUserDialogOpen(false);

  const {
    value: newUserName,
    editField: userNameEditField,
    clear: clearUserName,
    invalid: nameInvalid,
  } = useSimpleTextInput({
    id: "add-admin-name",
    label: "Name",
    disabled: saving,
    required: true,
    showRequiredErrorBeforeInteraction: showRequiredFieldsErrors,
    requiredErrorText: "Please enter a name for the new user",
  });

  const {
    value: newUserEmail,
    editField: userEmailEditField,
    clear: clearEmail,
    invalid: emailInvalid,
  } = useSimpleTextInput({
    id: "add-admin-email",
    label: "Email",
    disabled: saving,
    required: true,
    showRequiredErrorBeforeInteraction: showRequiredFieldsErrors,
    requiredErrorText: "Please enter an email for the new user",
    regex: VALID_EMAIL_REGEX,
    regexErrorText: "Please provide a valid email address",
  });

  const {
    value: newUserPassword,
    editField: userPasswordEditField,
    clear: clearPassword,
    invalid: passwordInvalid,
  } = useSimpleTextInput({
    id: "add-admin-password",
    label: "Password",
    type: "password",
    disabled: saving,
    required: true,
    showRequiredErrorBeforeInteraction: showRequiredFieldsErrors,
    requiredErrorText: "Please enter a password for the new user",
  });

  useEffect(() => {
    const fetchAdmins = () => {
      makeAuthenticatedRequest(getAuthAdminsUrl(), false)
        .then((response) => {
          setAdmins(response.data);
        })
        .catch((error) => {
          console.log(error);
          setErrorOccurredWhileLoading(true);
        })
        .finally(() => {
          setLoadingAdmins(false);
        });
    };

    fetchAdmins();
  }, [makeAuthenticatedRequest]);

  useEffect(() => {
    if (!addUserDialogOpen) {
      clearUserName();
      clearEmail();
      clearPassword();
      setShowRequiredFieldsErrors(false);
    }
  }, [addUserDialogOpen, clearUserName, clearEmail, clearPassword]);

  const createAdminUser = useCreateAdminAuthUser();

  const getFirstName = () => newUserName.split(" ")[0];

  const getLastName = () => {
    const lastSpaceIndex = newUserName.lastIndexOf(" ");

    return lastSpaceIndex === -1 ? "" : newUserName.slice(lastSpaceIndex);
  };

  const getUsername = () => newUserName.replace(" ", "");

  const saveNewUser = async () => {
    setSaving(true);

    try {
      const newUser = await createAdminUser(
        getUsername(),
        newUserEmail,
        getFirstName(),
        getLastName(),
        newUserPassword
      );

      enqueueSnackbar(`${newUserName} added successfully!`, {
        variant: "success",
      });

      setAddUserDialogOpen(false);

      setAdmins([...admins, newUser]);
    } catch (error) {
      enqueueSnackbar("An error occurred while adding the user", {
        variant: "error",
      });
    }

    setSaving(false);
  };

  const handleAdminAdded = async () => {
    if (nameInvalid || emailInvalid || passwordInvalid) {
      setShowRequiredFieldsErrors(true);
      enqueueSnackbar(
        "Please fix the invalid fields before submitting the new user",
        {
          variant: "error",
        }
      );
    } else {
      saveNewUser();
    }
  };

  const handleAdminDeleteUserClicked = useCallback(async (userId) => {
    setDeleteUserDialogOpen(true);
    setUserIdToDelete(userId);
  }, []);

  const getFullNameOfUserToDelete = () => {
    const userToDelete = admins.find((admin) => admin.id === userIdToDelete);

    return userToDelete?.firstName || userToDelete?.lastName
      ? `${userToDelete.firstName || ""} ${userToDelete.lastName || ""}`
      : "this user";
  };

  const deleteAdminUser = () => {
    setDeletingUser(true);

    makeAuthenticatedRequest(`${getAuthUsersUrl()}/${userIdToDelete}`, false, {
      method: "DELETE",
    })
      .then(() => {
        setDeleteUserDialogOpen(false);

        const updatedAdminsList = admins.filter(
          (admin) => admin.id !== userIdToDelete
        );

        setAdmins(updatedAdminsList);

        enqueueSnackbar(
          `${getFullNameOfUserToDelete()} was deleted successfully`,
          {
            variant: "success",
          }
        );
      })
      .catch(() => {
        enqueueSnackbar("An error occurred while adding the user", {
          variant: "error",
        });
      })
      .finally(() => {
        setDeletingUser(false);
      });
  };

  const getToolbarButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        title="Add a new admin user"
        startIcon={<AddIcon />}
        onClick={handleAddAdminClicked}
      >
        Add user
      </Button>
    </Fragment>
  );

  return (
    <Page title="Admin users" toolbarContent={getToolbarButtons()}>
      <AdminGrid
        admins={admins}
        loading={loadingAdmins}
        error={errorOccurredWhileLoading}
        onDeleteClicked={handleAdminDeleteUserClicked}
      />
      <GarnishDialog
        open={addUserDialogOpen}
        title="Add Admin User"
        onClose={handleAddAdminClosed}
        confirmText="Save user"
        alignLabelsLeft={true}
        onConfirm={handleAdminAdded}
        confirming={saving}
      >
        {userNameEditField()}
        {userEmailEditField()}
        {userPasswordEditField()}
      </GarnishDialog>

      <GarnishDialog
        open={deleteUserDialogOpen}
        title="Are you sure?"
        onClose={handleDeleteAdminClosed}
        confirmText="Delete user"
        onConfirm={deleteAdminUser}
        confirming={deletingUser}
        showCancelButton={true}
        onCancel={handleDeleteAdminClosed}
      >
        Are you sure you want to delete {getFullNameOfUserToDelete()}? This
        action can't be undone.
      </GarnishDialog>
    </Page>
  );
}

export default AdminUsersPage;
