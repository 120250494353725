import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../components/Card';
import { IconButton } from '@material-ui/core';
import { ReactComponent as EditIcon } from "../images/pencil.svg";
import { makeStyles } from '@material-ui/core/styles';
import Page from '../components/Page';
import useAuthenticatedRestCall from '../hooks/useAuthenticatedRestCall';
import DivisionList from '../components/clients/DivisionList';
import { useUploadImage } from '../hooks/useImageUpload';
import { getClientWatermarkUrl, getClientUrl, getDivisionUrl, getDivisionIconUrl, getDivisionsUrl } from '../util/getUrls';
import AddEditDivisionDialog from '../components/clients/AddEditDivisionDialog';
import ImageContainer from '../components/ImageContainer';
import EditClientDialog from '../components/clients/EditClientDialog';
import { updateObjectAtIndex } from '../util/arrayUtils';

const useStyles = makeStyles({
  title: {
    textAlign: 'left',
  },
  pageTitle: {
    marginBottom: '.5rem',
    textAlign: 'left',
  },
  clientDataWrapper: {
    display: 'flex',
    marginTop: "2rem",
    paddingBottom: "4rem",
    gap: "10rem"
  },
  subTitle: {
    marginBottom: '2rem',
    textAlign: 'left',
  },
  emptyAccessCodeText: {
    color: 'var(--graphite)',
    fontSize: '1.1rem',
    marginBottom: '1rem',
    marginTop: '0.8rem',
  },
  form: {
    width: '33%',
  },
  accessCodes: {
    marginTop: '3rem',
    marginBottom: '2rem',
  },
  accessCode: {
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    height: '3rem',
  },
  saveUserButton: {
    width: '12rem',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divisionType: {
    textTransform: "capitalize"
  },
  divisionTypeSelectContainer: {
    height: "6rem"
  },
  divisionsCard: {
    margin: "2rem 0"
  },
  clientTitle: {
    display: "flex",
    justifyContent: "center"
  },
  editClientButton: {
    fontSize: "2.5rem",
    marginLeft: "auto",
    position: "relative",
    left: "1rem",
    bottom: "1rem",
    "& svg": {
      width: "2.5rem",
      height: "2.5rem",
    },
  }
});

export default function EditClient() {
  let { clientId } = useParams();
  const classes = useStyles();
  const [client, setClient] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [loadingDivisions, setLoadingDivisions] = useState(true)
  const [saving, setSaving] = useState(false);
  const [showAddEditDivisionDialog, setShowAddEditDivisionDialog] = useState(false);
  const [showEditClientDialog, setShowEditClientDialog] = useState(false)
  const [watermarkIconSrc, setWatermarkIconSrc] = useState(null);
  const [loadingWatermarkIcon, setLoadingWatermarkIcon] = useState(true);
  const [divisionBeingEdited, setDivisionBeingEdited] = useState(null);
  const uploadImage = useUploadImage();

  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  useEffect(() => {
    const fetchClientWatermark = async (clientId) => {
      const response = await makeAuthenticatedRequest(getClientWatermarkUrl(clientId))

      setWatermarkIconSrc(response.data.location)
    }

    const fetchClientInfoAndSetState = async (id) => {
      try {
        const response = await makeAuthenticatedRequest(
          getClientUrl(id)
        );
        const clientInfo = response.data;

        setClient(clientInfo);

        if (clientInfo.hasImage) {
          await fetchClientWatermark(id)
        }

        setLoadingWatermarkIcon(false)
      } catch (error) { }
    };

    fetchClientInfoAndSetState(clientId);
  }, [makeAuthenticatedRequest, clientId]);

  useEffect(() => {
    const fetchDivisionIcon = async (clientId, division) => {
      if (!division.hasImage) {
        return division
      }

      const response = await makeAuthenticatedRequest(
        getDivisionIconUrl(clientId, division.id)
      );

      return { ...division, iconUrl: response.data.location }
    }

    const fetchClientDivisionsAndSetState = async (id) => {
      const response = await makeAuthenticatedRequest(
        `${getDivisionsUrl(id)}?paginated=false`
      );
      const clientDivisions = response.data.docs;

      const divisionsWithIcons = await Promise.all(clientDivisions.map(division => fetchDivisionIcon(id, division)))

      setDivisions(divisionsWithIcons);
      setLoadingDivisions(false);
    };

    fetchClientDivisionsAndSetState(clientId);
  }, [makeAuthenticatedRequest, clientId]);

  const handleSaveClient = async (update) => {
    const { updatedName, updatedDivisionType, updatedWatermarkFile, updatedWatermarkUrl } = update;

    setSaving(true)

    const updatedClient = await makeAuthenticatedRequest(getClientUrl(clientId), true,
      {
        data: {
          name: updatedName,
          divisionType: updatedDivisionType
        },
        method: "PUT",
      })

    if (updatedWatermarkFile) {
      await uploadImage(getClientWatermarkUrl(client.id), updatedWatermarkFile)
      setWatermarkIconSrc(updatedWatermarkUrl)
    }

    setClient(updatedClient.data)
    setSaving(false)
    setShowEditClientDialog(false)
  };

  const onDivisionEditClicked = (divisionId) => {
    setShowAddEditDivisionDialog(true)
    setDivisionBeingEdited(divisions.find(division => division.id === divisionId))
  }

  const addDivision = async (name, iconFile, iconUrl) => {
    const newDivisionResponse = await makeAuthenticatedRequest(getDivisionsUrl(clientId), true,
      {
        data: {
          name,
        },
        method: "POST",
      })

    const newDivision = newDivisionResponse.data

    if (iconFile) {
      await uploadImage(getDivisionIconUrl(client.id, newDivision.id), iconFile)
    }

    const newDivisionWithIconurl = { ...newDivision, iconUrl: iconUrl }

    setDivisions([...divisions, newDivisionWithIconurl])
  }

  const editDivision = async (divisionId, updatedName, updatedIconFile, updatedIconUrl) => {
    const updatedDivisionResponse = await makeAuthenticatedRequest(getDivisionUrl(clientId, divisionId), true,
      {
        data: {
          name: updatedName,
        },
        method: "PUT",
      })
    const updatedDivision = { ...updatedDivisionResponse.data }

    if (updatedIconFile) {
      await uploadImage(getDivisionIconUrl(client.id, divisionId), updatedIconFile)
      updatedDivision.iconUrl = updatedIconUrl
    }

    const existingDivisionIndex = divisions.findIndex(division => division.id === divisionId)

    setDivisions(updateObjectAtIndex(divisions, existingDivisionIndex, updatedDivision))
  }

  const onDivisionAddEditConfirmed = async (update) => {
    const { divisionId, updatedName, updatedIconFile, updatedIconUrl } = update

    setSaving(true)

    if (divisionId) {
      await editDivision(divisionId, updatedName, updatedIconFile, updatedIconUrl)
    } else {
      await addDivision(updatedName, updatedIconFile, updatedIconUrl)
    }

    setShowAddEditDivisionDialog(false)
    setDivisionBeingEdited(null)
    setSaving(false)
  }

  const onEditDivisionDialogClosed = () => {
    setShowAddEditDivisionDialog(false)
    setDivisionBeingEdited(null)
  }

  const initialDivisionIconSrc = () => {
    if (divisionBeingEdited !== null) {
      return divisions.find(division => division.id === divisionBeingEdited.id)?.iconUrl
    }

    return ''
  }

  const onAddDivisionClicked = () => {
    setShowAddEditDivisionDialog(true)
  }

  const getAddEditDivisionDialogTitle = () => divisionBeingEdited ? "Edit Division" : "Add Division"

  return (
    <Page>
      <Card isLoading={!client}>
        <div className={classes.clientTitle}>
          <h1>{client.name}</h1>
          <IconButton className={classes.editClientButton} onClick={() => setShowEditClientDialog(true)}><EditIcon /></IconButton>
        </div>
        <div className={classes.clientDataWrapper}>
          <div>
            <h4>Division Type</h4>
            <span className={classes.divisionType}>{client.divisionType}</span>
          </div>
          <div>
            <h4>Watermark logo</h4>
            <ImageContainer imageSrc={watermarkIconSrc} imageAlt="Client watermark icon" isLoading={loadingWatermarkIcon} />
          </div>
        </div>
        <EditClientDialog open={showEditClientDialog} clientName={client.name} divisionType={client.divisionType} saving={saving} watermarkIconSrc={watermarkIconSrc} onDialogClosed={() => setShowEditClientDialog(false)} handleEditClientConfirmed={handleSaveClient} />
      </Card>
      <Card className={classes.divisionsCard} isLoading={loadingDivisions}>
        <h1>Divisions</h1>
        <DivisionList divisions={divisions} loading={loadingDivisions} disabled={saving} onDivisionEditClicked={onDivisionEditClicked} onAddDivisionClicked={onAddDivisionClicked} />
        <AddEditDivisionDialog open={showAddEditDivisionDialog} title={getAddEditDivisionDialogTitle()} division={divisionBeingEdited} onConfirm={onDivisionAddEditConfirmed} onClose={onEditDivisionDialogClosed} initialDivisionIconSrc={initialDivisionIconSrc()} disabled={saving} />
      </Card>
    </Page>
  );
}
