import { useCallback, useContext } from "react";
import ClientContext from "../contexts/ClientContext";

export default function useClientIdQueryParameter() {
  const { clientInfo } = useContext(ClientContext);

  return useCallback(
    () => `&clientId=${clientInfo.clientId}`,
    [clientInfo.clientId]
  );
}
