import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Button } from '@material-ui/core';
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    divisionListContainer: {
        marginTop: "4rem",
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "15rem 10rem 10rem",

        "& $divisionListHeader:nth-child(2)": {
            gridColumn: "2 / 4"
        }
    },
    divisionListHeader: {
        textTransform: "uppercase",
        color: "var(--graphite)",
        fontWeight: "bolder",
        fontSize: "1.2rem",
        paddingBottom: "1rem"
    },
    divisionIconContainer: {
        width: "2rem",
        height: "2rem",
        borderRadius: "50%",
        overflow: "hidden"
    },
    divisionIcon: {
        width: "2rem",
        height: "2rem",
        objectFit: "cover"
    },
    defaultDivisionIcon: {
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        backgroundColor: "var(--dark-grey)"
    },
    divisionToolbarButton: {
        fontSize: "2rem"
    },
    addDivisionButton: {
        marginTop: "4rem"
    },
    loadingContainer: {
        height: "20rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
});

function DivisionList(props) {
    const classes = useStyles()

    const getDivisionIcon = (division) => division.iconUrl ? <img src={division.iconUrl} className={classes.divisionIcon} alt={`${division.name} icon`}></img> : <div className={classes.defaultDivisionIcon}></div>

    const getDeleteIconIfAllowed = (divisionId) => props.allowDeletion ? <IconButton className={classes.divisionToolbarButton} disabled={props.disabled} onClick={() => props.onDeleteDivision(divisionId)}><DeleteIcon /></IconButton> : null

    const getDivisionListItems = () => props.divisions.map((division) => {
        return (
            <Fragment key={division.id}>
                <div>{division.name}</div>
                <div className={classes.divisionIconContainer}>{getDivisionIcon(division)}</div>
                <div>
                    <IconButton className={classes.divisionToolbarButton} disabled={props.disabled} onClick={() => props.onDivisionEditClicked(division.id)}><EditIcon /></IconButton>
                    {getDeleteIconIfAllowed(division.id)}
                </div>
            </Fragment>
        );
    });

    const getDivisionList = () => (<div className={classes.divisionListContainer}><div className={classes.divisionListHeader}>Division</div>
        <div className={classes.divisionListHeader}>Icon</div>{getDivisionListItems()}</div>)

    const getDivisionListItemsOrEmptyMessage = () => props.divisions.length === 0 ? <p>No Divisions Have been added</p> : getDivisionList()

    const getLoadedContent = () => (<div>
        {getDivisionListItemsOrEmptyMessage()}
        <Button className={classes.addDivisionButton} variant="contained" color="primary" startIcon={<AddIcon />} disabled={props.disabled} onClick={props.onAddDivisionClicked}>
            Add Division
        </Button>
    </div>)

    const getLoadingSpinner = () => (<div className={classes.loadingContainer}><CircularProgress
        size="2.5rem"
    /></div>)

    return props.loading ? getLoadingSpinner() : getLoadedContent()
}

DivisionList.propTypes = {
    divisions: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onDivisionEditClicked: PropTypes.func,
    onAddDivisionClicked: PropTypes.func,
    allowDeletion: PropTypes.bool
};

export default DivisionList;