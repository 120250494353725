import React, { useState, useCallback, Fragment } from 'react';
import { Editor, Transforms } from 'slate';
import { useSelected, useSlateStatic } from 'slate-react';
import classNames from 'classnames';

import InvertColorsIcon from '@material-ui/icons/InvertColors';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, ButtonGroup, InputBase } from '@material-ui/core';
import isHotkey from 'is-hotkey';

import '../../../device-preview/modules/_visual-media.scss';

const Video = ({ attributes, children, element, previewMode }) => {
  const [isEditingCaption, setEditingCaption] = useState(false);
  const [imgStyle, setImgStyle] = useState(element.style);
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [caption, setCaption] = useState(element.caption);
  const [title, setTitle] = useState(element.title);
  const editor = useSlateStatic();

  const selected = useSelected();

  const applyStyleChange = () => {
    const videoNodeEntry = Editor.above(editor, {
      match: (n) => n.type === 'video',
    });
    if (videoNodeEntry == null) {
      return;
    }
    let newStyle;
    if (imgStyle === 'dark') {
      newStyle = 'light';
    } else {
      newStyle = 'dark';
    }
    Transforms.setNodes(editor, { style: newStyle }, { at: videoNodeEntry[1] });
    setImgStyle(newStyle);
  };

  const applyCaptionChange = useCallback(
    (captionInput) => {
      const videoNodeEntry = Editor.above(editor, {
        match: (n) => n.type === 'video',
      });
      if (videoNodeEntry == null) {
        return;
      }

      if (captionInput != null) {
        setCaption(captionInput);
      }

      Transforms.setNodes(
        editor,
        { caption: captionInput },
        { at: videoNodeEntry[1] }
      );
    },
    [editor, setCaption]
  );

  const applyTitleChange = useCallback(
    (titleInput) => {
      const videoNodeEntry = Editor.above(editor, {
        match: (n) => n.type === 'video',
      });
      if (videoNodeEntry == null) {
        return;
      }

      if (titleInput != null) {
        setTitle(titleInput);
      }

      Transforms.setNodes(
        editor,
        { title: titleInput },
        { at: videoNodeEntry[1] }
      );
    },
    [editor, setTitle]
  );

  const deleteVideo = () => {
    const videoNodeEntry = Editor.above(editor, {
      match: (n) => n.type === 'video',
    });
    Transforms.removeNodes(editor, { at: videoNodeEntry[1] });
  };

  const onCaptionChange = useCallback(
    (event) => {
      setCaption(event.target.value);
    },
    [setCaption]
  );

  const onTitleChange = useCallback(
    (event) => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (!isHotkey('enter', event)) {
        return;
      }
      if (isEditingCaption) {
        applyCaptionChange(event.target.value);
        setEditingCaption(false);
      }
      if (isEditingTitle) {
        applyTitleChange(event.target.value);
        setEditingTitle(false);
      }
      return;
    },
    [
      isEditingCaption,
      applyCaptionChange,
      setEditingCaption,
      isEditingTitle,
      applyTitleChange,
      setEditingTitle,
    ]
  );

  const onToggleCaptionEditMode = useCallback(
    (event) => {
      const wasEditing = isEditingCaption;
      setEditingCaption(!isEditingCaption);
      wasEditing && applyCaptionChange(caption);
    },
    [isEditingCaption, applyCaptionChange, caption]
  );

  const onToggleTitleEditMode = useCallback(
    (event) => {
      const wasEditing = isEditingTitle;
      setEditingTitle(!isEditingTitle);
      wasEditing && applyTitleChange(title);
    },
    [isEditingTitle, applyTitleChange, title]
  );

  if (previewMode) {
    return (
      <div
        className={`visual-media ${
          element.style === 'dark' ? 'dark' : 'light'
        }`}
        contentEditable={false}
        {...attributes}
      >
        <div>
          <div className="video-wrapper">
            <iframe
              title="video-embed"
              id="ytplayer"
              type="text/html"
              src={element.src}
              frameBorder="0"
            />
          </div>
          {element.title && <h3 className="title">{element.title}</h3>}
          {element.caption && (
            <div className="caption">
              <p>{element.caption}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`visual-media ${element.style === 'dark' ? 'dark' : 'light'} `}
      contentEditable={false}
      {...attributes}
    >
      <div
        className={classNames({
          'image-container': true,
          'is-selected': selected,
        })}
      >
        <div className="video-wrapper">
          <iframe
            title="video-embed"
            id="ytplayer"
            type="text/html"
            src={element.src}
            frameBorder="0"
          />
        </div>
        {isEditingTitle ? (
          <InputBase
            className="title-field"
            autoFocus={true}
            defaultValue={title}
            onKeyDown={onKeyDown}
            placeholder={'Enter a title for this video...'}
            onChange={onTitleChange}
            onBlur={onToggleTitleEditMode}
            inputProps={{ 'aria-label': 'video-title' }}
          />
        ) : (
          <Fragment>
            {element.title && (
              <h3 className="title" onClick={onToggleTitleEditMode}>
                {element.title}
              </h3>
            )}
            {!element.title && (
              <h3 className="title no-content" onClick={onToggleTitleEditMode}>
                Enter a title for this video...
              </h3>
            )}
          </Fragment>
        )}

        {isEditingCaption ? (
          <InputBase
            className="caption-field"
            autoFocus={true}
            defaultValue={caption}
            onKeyDown={onKeyDown}
            multiline
            placeholder={'Enter a caption for this video...'}
            onChange={onCaptionChange}
            onBlur={onToggleCaptionEditMode}
            inputProps={{ 'aria-label': 'video-caption' }}
          />
        ) : (
          <Fragment>
            {element.caption && (
              <div className="caption" onClick={onToggleCaptionEditMode}>
                <p>{element.caption}</p>
              </div>
            )}
            {!element.caption && (
              <div
                className="caption no-content"
                onClick={onToggleCaptionEditMode}
              >
                <p>Enter a caption for this video...</p>
              </div>
            )}
          </Fragment>
        )}
        <div className="edit-bar">
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
          >
            <Button onMouseDown={applyStyleChange}>
              <InvertColorsIcon />
            </Button>
            <Button onMouseDown={deleteVideo}>
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Video;
