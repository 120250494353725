import { useContext } from "react";
import ClientContext from "../contexts/ClientContext";
import { useUploadImage } from "./useImageUpload";

export const useUploadCourseCompletionImage = () => {
  const { clientInfo } = useContext(ClientContext);
  const uploadImage = useUploadImage();

  return function (levelId, courseId, imageFile) {
    return uploadImage(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}/clients/${clientInfo.clientId}/levels/${levelId}/courses/${courseId}/course-completion-image`,
      imageFile
    );
  };
};
