import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import EditField from "../EditField";
import GarnishDialog from "../GarnishDialog";
import ImageUploader from "../ImageUploader";
import useTranslatedTitlesInDialog from "../../hooks/useTranslatedTitlesInDialog";
import AddEditTranslatedTitles from "./AddEditTranslatedTitles";

export default function AddEditCourseDialog(props) {
  const [name, setName] = useState(props.initialName || "");
  const [nameValidationError, setNameValidationError] = useState(null);
  const [passingGradePercentage, setPassingGradePercentage] = useState(
    props.initialPassingGrade || 80
  );
  const [passingGradePercentageError, setPassingGradePercentageError] =
    useState(null);
  const [imageFile, setImageFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const {
    titleTranslations,
    handleTranslatedTitleChanged,
    handleDeleteTranslatedTitleClicked,
    handleTranslationAdded,
    getTranslatedTitlesForUpload,
  } = useTranslatedTitlesInDialog(props.open, props.existingTitleTranslations);

  useEffect(() => {
    if (!props.open) {
      setName(props.initialName || "");
      setPassingGradePercentage(props.initialPassingGrade || 80);
      setNameValidationError(null);
      setPassingGradePercentageError(null);
    }
  }, [props.open, props.initialName, props.initialPassingGrade]);

  const handleNameChanged = (event) => {
    setName(event.target.value);

    if (event.target.value.trim().length === 0) {
      setNameValidationError("Please provide a name");
    } else {
      setNameValidationError(null);
    }
  };

  const handlePassingGradeChanged = (event) => {
    setPassingGradePercentage(event.target.value);

    const valueAsNumber = parseInt(event.target.value);

    if (event.target.value.trim().length === 0) {
      setPassingGradePercentageError(
        "Please provide a passing grade percentage"
      );
    } else if (isNaN(valueAsNumber)) {
      setPassingGradePercentageError("Please provide a valid percentage");
    } else if (valueAsNumber < 0 || valueAsNumber > 100) {
      setPassingGradePercentageError(
        "Please provide a percentage between 0 and 100"
      );
    } else {
      setPassingGradePercentageError(null);
    }
  };

  const handleImageUpload = (file) => {
    setImageFile(file);
  };

  const handleConfirm = () => {
    if (!name) {
      enqueueSnackbar(`Please provide a name for your new course`, {
        variant: "error",
      });
    } else if (nameValidationError || passingGradePercentageError) {
      enqueueSnackbar(`Please fix form errors before submitting your course`, {
        variant: "error",
      });
    } else {
      props.onConfirm(
        name,
        passingGradePercentage,
        imageFile,
        getTranslatedTitlesForUpload()
      );
    }
  };

  return (
    <GarnishDialog
      title={props.title}
      open={props.open}
      onClose={props.onClose}
      onConfirm={handleConfirm}
      confirmText={props.confirmText}
    >
      <EditField
        id="name"
        label="Title"
        onChange={handleNameChanged}
        error={nameValidationError !== null}
        value={name}
        helperText={nameValidationError}
      />
      <AddEditTranslatedTitles
        id={"add-edit-course-translated-titles"}
        titleTranslations={titleTranslations}
        handleTranslatedTitleChanged={handleTranslatedTitleChanged}
        handleDeleteTranslatedTitleClicked={handleDeleteTranslatedTitleClicked}
        handleTranslationAdded={handleTranslationAdded}
      />
      <EditField
        id="passingGrade"
        label="Passing grade percentage"
        onChange={handlePassingGradeChanged}
        error={passingGradePercentageError !== null}
        value={passingGradePercentage}
        helperText={passingGradePercentageError}
      />
      <ImageUploader
        label="Course completion image"
        onImageUpload={handleImageUpload}
        defaultImageSrc={props.initialImageSrc}
        isLoading={props.imageLoading}
      />
    </GarnishDialog>
  );
}
