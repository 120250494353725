import { useEffect, useState, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import Courses from '../pages/Courses';
import Users from '../pages/Users';
import User from '../pages/User';
import Reports from '../pages/Reports';
import AddCourseContent from '../pages/AddCourseContent';
import { addClassToDefaultIfTrue } from '../util/addClassToDefaultIfTrue';
import EditCourseContent from '../pages/EditCourseContent';
import AdminLandingPage from '../pages/AdminLandingPage';
import AdminUsersPage from '../pages/AdminUsersPage';
import AdminAccessCodesPage from '../pages/AdminAccessCodesPage';
import ClientPage from '../pages/ClientPage';
import EditClient from '../pages/EditClient';
import AddAccessCodePage from '../pages/AddAccessCodePage';
import AddClient from '../pages/AddClient';

const useStyles = makeStyles({
  main: {
    flex: 1,
    minHeight: 0,
    margin: '2.4rem',
    '&.full-screen': {
      margin: '0',
    },
    width: '100%',
    '& > div': {
      height: '100%',
    },
  },
});

function AnimatedRoutes(props) {
  const classes = useStyles();
  const location = useLocation();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const fullPageRoutes = useMemo(
    () => [
      /\/courses\/.*\/add-new-content/,
      /\/courses\/.*\/content\/.*\/edit/,
    ],
    []
  );

  useEffect(() => {
    if (
      fullPageRoutes.some((routeRegex) => routeRegex.test(location.pathname))
    ) {
      props.onFullPageRouteLoaded();
      setIsFullScreen(true);
    } else {
      props.onRegularRouteLoaded();
      setIsFullScreen(false);
    }
  }, [location, props, fullPageRoutes]);

  return (
    <main
      className={addClassToDefaultIfTrue(
        classes.main,
        isFullScreen,
        'full-screen'
      )}
    >
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="slide-fade"
          timeout={300}
          unmountOnExit
        >
          <Switch location={location}>
            <Route path="/" exact>
              <Courses />
            </Route>
            <Route path="/courses" exact>
              <Courses />
            </Route>
            <Route path="/levels/:levelId/courses/:courseId/add-new-content">
              <AddCourseContent />
            </Route>
            <Route path="/levels/:levelId/courses/:courseId/content/:contentId/edit">
              <EditCourseContent />
            </Route>
            <Route path="/reports" exact>
              <Reports />
            </Route>
            <Route path="/users" exact>
              <Users />
            </Route>
            <Route path="/users/:userId">
              <User />
            </Route>
            <Route path="/clients" exact>
              <ClientPage />
            </Route>
            <Route path="/clients/add-new-client">
              <AddClient />
            </Route>
            <Route path="/clients/:clientId/edit">
              <EditClient />
            </Route>
            <Route path="/admin" exact>
              <AdminLandingPage />
            </Route>
            <Route path="/admin/users">
              <AdminUsersPage />
            </Route>
            <Route path="/admin/access-codes" exact>
              <AdminAccessCodesPage />
            </Route>
            <Route path="/admin/access-codes/create">
              <AddAccessCodePage />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
}

export default AnimatedRoutes;
