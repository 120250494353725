import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1.5rem",
  },
});

function ToolTip(props) {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={props.title || ""}>
      {props.children}
    </Tooltip>
  );
}

export default ToolTip;
