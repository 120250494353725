import {
  Button,
  FormControlLabel,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { useContext, useMemo } from "react";
import LanguageContext from "../../contexts/LanguageContext";
import { useDefaultLanguage } from "../../hooks/useDefaultLanguage";
import EditField from "../EditField";
import LanguageSelect from "./LanguageSelect";

const useStyles = makeStyles(theme => ({
  switchLabel: {
    fontSize: "1.4rem",
    color: "var(--garnish-dark-green)",
  },
  quizSwitchLabel: {
    margin: "0 2rem",
  },
  titleInput: {
    width: "60rem",
    margin: "0 2rem",
    position: "relative",
    bottom: ".4rem",
    "& input": {
      fontSize: "3.2rem",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      color: "var(--garnish-dark-green)",
    },
  },
  toolbar: {
    background: "white",
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    borderBottom: "1px solid var(--dark-grey)",
    height: "9rem",
    marginBottom: "3rem",
    "& button": {
      borderRadius: "0",
      marginLeft: "2rem",
      height: "5rem",
      minWidth: "16rem",
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: "wrap",
      height: "max-content",
      "& $languageSelectContainer": {
        width: "15rem",
      },
      "& $titleInput": {
        flexBasis: "100%",
        margin: 0
      }
    },
    [theme.breakpoints.down('xs')]: {
      gap: "1rem",

      "& $languageSelectContainer": {
        flexBasis: "100%",
        marginRight: 0
      },
    }
  },
  buttonWrapper: {
    display: "flex",
    marginLeft: "auto"
  },
  publishedInput: {
    marginLeft: "auto",
  },
  previewButton: {
    background: "#E2665B",
    transition: "filter 400ms ease",
    "&:hover": {
      background: "#E2665B",
      filter: "brightness(1.2)",
    },
  },
  languageSelectContainer: {
    height: "4.4rem",
    flexGrow: 1,
    marginRight: "1rem",
    minWidth: "10rem"
  },
}));

function ContentEditorToolbar({
  languageBeingEditedId,
  showQuizModeToggle,
  quizMode,
  onQuizModeChanged,
  saving,
  translatedTitles,
  title,
  onTitleChange,
  titleError,
  onLanguageBeingEditedChanged,
  published,
  onPublishedChanged,
  togglePreviewOpen,
  onSaveClick,
  setTranslatedTitleForLanguageBeingEdited,
}) {
  const classes = useStyles();
  const { defaultLanguage, defaultLanguageLoaded } = useDefaultLanguage();
  const { languageInfo } = useContext(LanguageContext);

  const editTranslationMode = useMemo(
    () => defaultLanguageLoaded && languageBeingEditedId !== defaultLanguage.id,
    [defaultLanguage, defaultLanguageLoaded, languageBeingEditedId]
  );

  const getQuizModeToggle = () =>
    showQuizModeToggle ? (
      <FormControlLabel
        classes={{
          root: `${classes.quizSwitchLabel} ${classes.switchLabel}`,
        }}
        labelPlacement="start"
        control={
          <Switch
            checked={quizMode}
            onChange={onQuizModeChanged}
            name="published"
            color="primary"
            disabled={saving}
          />
        }
        label="Quiz"
      />
    ) : null;

  const getTranslationTitle = () => translatedTitles[languageBeingEditedId];

  const getTitleValue = () =>
    editTranslationMode ? getTranslationTitle() : title;

  const getLanguageBeingEditedDisplayName = () =>
    languageInfo.loading
      ? ""
      : languageInfo.languages.find(
        (language) => language.id === languageBeingEditedId
      ).displayName;

  const getPlaceholder = () =>
    editTranslationMode
      ? `Enter the ${getLanguageBeingEditedDisplayName()} title`
      : "";

  const handleTitleChange = (event) => {
    if (editTranslationMode) {
      setTranslatedTitleForLanguageBeingEdited(event.target.value);
    } else {
      onTitleChange(event.target.value);
    }
  };

  return (
    <div className={classes.toolbar}>
      <EditField
        id="content-editor-title"
        className={classes.titleInput}
        value={getTitleValue()}
        onChange={handleTitleChange}
        error={titleError !== null}
        helperText={titleError}
        disabled={saving}
        placeholder={getPlaceholder()}
      />
      <div className={classes.languageSelectContainer}>
        <LanguageSelect
          id="content-editor-language-select"
          value={languageBeingEditedId}
          onChange={onLanguageBeingEditedChanged}
          maxHeight={true}
          width="100%"
        />
      </div>
      {getQuizModeToggle()}
      <FormControlLabel
        classes={{
          root: classes.publishedInput,
          label: classes.switchLabel,
        }}
        labelPlacement="start"
        control={
          <Switch
            checked={published}
            onChange={onPublishedChanged}
            name="published"
            color="primary"
            disabled={saving}
          />
        }
        label="Published"
      />
      <div className={classes.buttonWrapper}>
        <Button
          disableElevation
          variant="contained"
          className={classes.previewButton}
          onClick={togglePreviewOpen}
          disabled={saving}
        >
          Preview
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={onSaveClick}
          disabled={saving}
        >
          Save content
        </Button>
      </div>
    </div>
  );
}

export default ContentEditorToolbar;
