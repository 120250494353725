import { DragPreviewImage } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

/**
 *  The react-dnd library is ludicrous and requires us to use an "empty" drag preview image
 *  instead of providing a way to just disable it. I hate it.
 */
function EmptyPreviewImage(props) {
  return <DragPreviewImage connect={props.preview} src={getEmptyImage().src} />;
}

export default EmptyPreviewImage;
