import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import useAuthenticatedRestCall from '../hooks/useAuthenticatedRestCall';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { shortDateFormatter } from '../util/gridUtils';

import Chip from '@material-ui/core/Chip';
import Page from '../components/Page';
import { DataGrid } from '@material-ui/data-grid';
import ClientContext from '../contexts/ClientContext';

const useStyles = makeStyles({
  grid: {
    marginTop: '3rem',
    color: 'var(--darker-grey)',
    '& .MuiDataGrid-row': {
      '&:hover': {
        '&::before': {
          opacity: 1,
          transform: 'translateY(0)',
        },
      },
    },
    '& .MuiDataGrid-cell[data-field="reportDate"]': {
      fontSize: '1.5rem',
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 'normal',
      color: '#3A3B3F',
    },
  },
  readyChip: {
    color: 'white',
  },
});

const renderStatus = (params) => {
  if (params.value === true) {
    return (
      <Chip
        style={{ color: 'white' }}
        label="Ready"
        color="primary"
        size="small"
      />
    );
  }
  return (
    <Chip label="Processing" color="default" size="small" variant="outlined" />
  );
};

const renderDownloadLink = (params) => {
  const { clientId, completed, reportDate, id } = params.row;
  const date = new Date(reportDate).toISOString().split('T')[0];
  const reportUrl = `${process.env.REACT_APP_REPORT_STORAGE_URL}/${clientId}/user-report-${date}-${id}.csv`;
  if (completed === true) {
    return <a href={reportUrl}>Download CSV</a>;
  }
  return null;
};

const renderWithShortDate = (params) => shortDateFormatter(params.value);

const columns = [
  {
    field: 'reportDate',
    headerName: 'Report Date',
    flex: 1,
    renderCell: renderWithShortDate,
  },
  {
    field: 'completed',
    headerName: 'Status',
    flex: 0.25,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderStatus,
  },
  {
    field: 'progress',
    headerName: 'Download Report',
    align: 'center',
    headerAlign: 'center',
    flex: 0.25,
    sortable: false,
    renderCell: renderDownloadLink,
  },
];

function Reports() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [pageSize, setPageSize] = useState(10);
  const [totalDocsCount, setTotalDocsCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clientInfo } = useContext(ClientContext);
  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  const fetchReports = useCallback(async () => {
    try {
      const response = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_REPORT_SERVICE_URL}/reports/${
          clientInfo.clientId
        }?sortField=reportDate&sortDirection=desc&pageSize=${pageSize}&pageNumber=${
          pageNumber + 1
        }`
      );

      setTotalDocsCount(response.data.totalDocs);
      setRows(response.data.docs);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }, [pageNumber, pageSize, clientInfo.clientId, makeAuthenticatedRequest]);

  useEffect(() => {
    if (clientInfo.clientsHaveLoaded) {
      setLoading(true);
      fetchReports();
      setLoading(false);
      const interval = setInterval(() => {
        fetchReports();
      }, 20000);
      return () => clearInterval(interval);
    }
  }, [fetchReports, clientInfo.clientsHaveLoaded]);

  const handlePageSizeChange = useCallback((pageUpdate) => {
    const pageSize =
      typeof pageUpdate.pageSize === undefined
        ? pageUpdate
        : pageUpdate.pageSize;
    setPageSize(pageSize);
  }, []);

  const handlePageNumberChange = useCallback((pageUpdate) => {
    const pageNumber =
      typeof pageUpdate.page === undefined ? pageUpdate : pageUpdate.page;

    setPageNumber(pageNumber);
  }, []);

  const handleRunReportClicked = () => {
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_REPORT_SERVICE_URL}/reports/${clientInfo.clientId}/new-report`,
      false,
      {
        data: {},
        method: 'POST',
      }
    )
      .then(() => {
        enqueueSnackbar(`Report Initiated!`, {
          variant: 'success',
        });
        fetchReports();
      })
      .catch(() => {
        enqueueSnackbar('An error occurred saving the access code', {
          variant: 'error',
        });
      });
  };

  const toolbarButtons = () => (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleRunReportClicked}
      >
        Run New Report
      </Button>
    </React.Fragment>
  );

  return (
    <Page title="Reports" toolbarContent={toolbarButtons()}>
      <DataGrid
        className={classes.grid}
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pagination
        paginationMode="server"
        onPageChange={handlePageNumberChange}
        onPageSizeChange={handlePageSizeChange}
        loading={loading}
        rowCount={totalDocsCount}
        error={error}
        disableColumnSelector
        disableSelectionOnClick
      />
    </Page>
  );
}

export default Reports;
