import { makeStyles } from "@material-ui/core";
import { addClassToDefaultIfTrue } from "../util/addClassToDefaultIfTrue";

const useStyles = makeStyles({
  transition: {
    opacity: "1",
    transition: "opacity 400ms ease, transform 400ms ease",
    width: "100%",
    height: "100%",
  },
  transitionHidden: {
    opacity: "0",
    transform: "scale(.5)",
    position: "absolute",
    height: 0
  },
});

/**
 * React Material's grow transition breaks the quill editor, so this is a custom transition component
 * that uses only css transitions and so does not mess with the dom. It's gentle haha.
 *
 * @param {*} props
 * @returns
 */
function GentleGrowTransition(props) {
  const classes = useStyles();

  return (
    <div
      className={addClassToDefaultIfTrue(
        classes.transition,
        !props.show,
        classes.transitionHidden
      )}
    >
      {props.children}
    </div>
  );
}

export default GentleGrowTransition;
