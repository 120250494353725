import React from 'react';

const Link = ({ element, attributes, children }) => {
  return (
    <a href={element.url} {...attributes} className={'link'}>
      {children}
    </a>
  );
};

export default Link;
