import { useCallback, useContext, useEffect } from "react";
import { DEFAULT_EDITOR_CONTENT } from "../constants/defaultEditorContent";
import LanguageContext from "../contexts/LanguageContext";
import { useDefaultLanguage } from "./useDefaultLanguage";

export const useInitializeTranslatedContent = (
  setTranslatedContent,
  setTranslatedTitles,
  existingTranslations,
  existingTranslatedTitles
) => {
  const { defaultLanguage } = useDefaultLanguage();
  const { languageInfo } = useContext(LanguageContext);

  const getTranslatedValueOrDefault = useCallback(
    (translationList, languageId, defaultValue) => {
      const existingTranslation = translationList?.find(
        (translation) => translation.languageId === languageId
      );

      return existingTranslation?.translatedValue || defaultValue;
    },
    []
  );

  const createTranslationMapWithoutDefaultLanguage = useCallback(
    (existingTranslationList, translationListSetter, defaultValue) => {
      if (
        !languageInfo.loading &&
        defaultLanguage &&
        (existingTranslationList ||
          typeof existingTranslationList === "undefined")
      ) {
        const initialTranslations = languageInfo.languages
          .filter((language) => language.id !== defaultLanguage.id)
          .reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: getTranslatedValueOrDefault(
                existingTranslationList,
                curr.id,
                defaultValue
              ),
            }),
            {}
          );

        translationListSetter(initialTranslations);
      }
    },
    [
      defaultLanguage,
      getTranslatedValueOrDefault,
      languageInfo.loading,
      languageInfo.languages,
    ]
  );

  useEffect(() => {
    createTranslationMapWithoutDefaultLanguage(
      existingTranslations,
      setTranslatedContent,
      DEFAULT_EDITOR_CONTENT
    );
  }, [
    languageInfo.loading,
    languageInfo.languages,
    defaultLanguage,
    existingTranslations,
    getTranslatedValueOrDefault,
    createTranslationMapWithoutDefaultLanguage,
    setTranslatedContent,
  ]);

  useEffect(() => {
    createTranslationMapWithoutDefaultLanguage(
      existingTranslatedTitles,
      setTranslatedTitles,
      ""
    );
  }, [
    languageInfo.loading,
    languageInfo.languages,
    defaultLanguage,
    existingTranslatedTitles,
    getTranslatedValueOrDefault,
    createTranslationMapWithoutDefaultLanguage,
    setTranslatedTitles,
  ]);
};
