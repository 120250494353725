import { useState, useCallback, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";
import Card from "../components/Card";
import Label from "../components/Label";
import EditField from "../components/EditField";
import { IconButton, Button, CircularProgress } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClientContext from "../contexts/ClientContext";
import { VALID_EMAIL_REGEX, VALID_PHONE_REGEX } from "../constants/inputRegexes";

const useStyles = makeStyles({
  title: {
    textAlign: "left",
  },
  pageTitle: {
    marginBottom: ".5rem",
    textAlign: "left",
  },
  subTitle: {
    marginBottom: "2rem",
    textAlign: "left",
  },
  emptyAccessCodeText: {
    color: "var(--graphite)",
    fontSize: "1.1rem",
    marginBottom: "1rem",
    marginTop: "0.8rem",
  },
  form: {
    width: "33%",
  },
  accessCodes: {
    marginTop: "3rem",
    marginBottom: "2rem",
  },
  accessCode: {
    fontSize: "1.2rem",
    display: "flex",
    alignItems: "center",
  },
  addButton: {
    height: "3rem",
  },
  saveUserButton: {
    width: "12rem",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  saveLoadingSpinner: {
    marginLeft: "1rem",
  },
});

const EMAIL_ERROR_MESSAGE = "Please provide a valid email address";
const PHONE_ERROR_MESSAGE = "Please provide a valid phone number";
const MUST_SUPPLY_PHONE_OR_EMAIL_ERROR =
  "You must supply an email address or phone number";

function User() {
  const classes = useStyles();
  const history = useHistory();
  let { userId } = useParams();
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { clientInfo } = useContext(ClientContext);
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [fullAccessCodesList, setFullAccessCodesList] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  const getClientIdQueryParam = useCallback(() => {
    return clientInfo.clientId.length > 0
      ? `&clientId=${clientInfo.clientId}`
      : "";
  }, [clientInfo.clientId]);

  const getFullAccessCodes = useCallback(
    async (user) => {
      const accessCodeRequests = user.accessCodes.map((accessCodeId) =>
        makeAuthenticatedRequest(
          `${process.env.REACT_APP_COURSE_SERVICE_URL}/access-codes/${accessCodeId}`,
          false
        )
      );

      const accessCodeResponses = await Promise.all(accessCodeRequests);

      return accessCodeResponses.map((response) => response.data);
    },
    [makeAuthenticatedRequest]
  );

  useEffect(() => {
    const fetchUser = async () => {
      const userResponse = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_USER_SERVICE_URL}/users/${userId}`
      );

      const accessCodes = await getFullAccessCodes(userResponse.data);

      setUser({ ...userResponse.data, accessCodes });
    };

    fetchUser();
  }, [userId, makeAuthenticatedRequest, getFullAccessCodes]);

  useEffect(() => {
    const fetchAccessCodes = async () => {
      const accessCodeResponse = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_COURSE_SERVICE_URL
        }/access-codes?paginated=false${getClientIdQueryParam()}`
      );

      const accessCodesList = accessCodeResponse.data.docs;

      const accessCodesNotYetAssigned = accessCodesList.filter(
        (code) => !user.accessCodes.some((userCode) => userCode.id === code.id)
      );

      setFullAccessCodesList(accessCodesNotYetAssigned);
    };

    if (user) {
      setAutocompleteInputValue("");
      fetchAccessCodes();
    }
  }, [makeAuthenticatedRequest, getClientIdQueryParam, user]);

  const handleEmailChange = (event) => {
    setUser({ ...user, email: event.target.value });

    if (user.phoneNumber.length === 0 && event.target.value.length === 0) {
      setEmailError(MUST_SUPPLY_PHONE_OR_EMAIL_ERROR);
      setPhoneError(MUST_SUPPLY_PHONE_OR_EMAIL_ERROR);
    } else if (!VALID_EMAIL_REGEX.test(event.target.value)) {
      setEmailError(EMAIL_ERROR_MESSAGE);
    } else {
      setEmailError("");
    }

    if (user.phoneNumber.length === 0 && event.target.value.length > 0) {
      setPhoneError("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    setUser({ ...user, phoneNumber: event.target.value });

    if (user.email.length === 0 && event.target.value.length === 0) {
      setEmailError(MUST_SUPPLY_PHONE_OR_EMAIL_ERROR);
      setPhoneError(MUST_SUPPLY_PHONE_OR_EMAIL_ERROR);
    } else if (!VALID_PHONE_REGEX.test(event.target.value)) {
      setPhoneError(PHONE_ERROR_MESSAGE);
    } else {
      setPhoneError("");
    }

    if (user.email.length === 0 && event.target.value.length > 0) {
      setEmailError("");
    }
  };

  const handleAccessCodeAdded = () => {
    const newAccessCodeList = [...user.accessCodes, autocompleteValue];
    setAutocompleteInputValue("");
    setAutocompleteValue(null);
    setUser({ ...user, accessCodes: newAccessCodeList });
  };

  const handleAutoCompleteChanged = (event, value) => {
    setAutocompleteValue(value);
  };

  const handleAutoCompleteInputChanged = (event, value, reason) => {
    setAutocompleteInputValue(value);
  };

  const handleSaveClicked = () => {
    setLoading(true);

    const accessCodeIds = user.accessCodes.map((accessCode) => accessCode.id);
    const updateUserPayload = {
      email: user.email,
      phoneNumber: user.phoneNumber,
      accessCodes: accessCodeIds,
    };

    makeAuthenticatedRequest(
      `${process.env.REACT_APP_USER_SERVICE_URL}/users/${userId}`,
      false,
      {
        data: updateUserPayload,
        method: "PUT",
      }
    )
      .then(() => {
        history.push("/users");
        enqueueSnackbar(`${user.name} successfully updated!`, {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`${error.join(", ")}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccessCodes = () => {
    if (user && user.accessCodes.length > 0) {
      return user.accessCodes.map((accessCode) => {
        return (
          <div className={classes.accessCode} key={accessCode.id}>
            <IconButton
              onClick={() => {
                const newAccessCodeList = user.accessCodes.filter(
                  (code) => code.id !== accessCode.id
                );
                setUser({ ...user, accessCodes: newAccessCodeList });
              }}
            >
              <ClearIcon />
            </IconButton>
            {accessCode.code}
          </div>
        );
      });
    }

    return (
      <div className={classes.emptyAccessCodeText}>
        No access codes have been assigned to this user.
      </div>
    );
  };

  const showLoadingSpinner = () => {
    return loading ? (
      <CircularProgress classes={{ root: classes.saveLoadingSpinner }} size="3rem" />
    ) : null;
  };

  return (
    <Card isLoading={!user}>
      <h3 className={classes.pageTitle}>Edit User</h3>
      <h1 className={classes.title}>{user?.name}</h1>
      <h3 className={classes.subTitle}>ID: {user?.id}</h3>
      <form className={classes.form}>
        <EditField
          id="user-email-input"
          label="Email"
          value={user?.email}
          onChange={handleEmailChange}
          error={emailError.length > 0}
          helperText={emailError}
          disabled={loading}
        ></EditField>
        <EditField
          id="user-phone-input"
          label="Phone"
          value={user?.phoneNumber}
          onChange={handlePhoneNumberChange}
          error={phoneError.length > 0}
          helperText={phoneError}
          disabled={loading}
        ></EditField>
        <div className={classes.accessCodes}>
          <Label>Access codes</Label>
          {getAccessCodes()}
        </div>
        <Autocomplete
          id="access-code-picker"
          options={fullAccessCodesList}
          autoComplete={true}
          getOptionLabel={(option) => option.code}
          onChange={handleAutoCompleteChanged}
          onInputChange={handleAutoCompleteInputChanged}
          inputValue={autocompleteInputValue}
          value={autocompleteValue}
          disabled={loading}
          renderInput={(params) => (
            <EditField
              ref={params.InputProps.ref}
              id="user-access-code-input"
              placeholder="Access Code"
              inputProps={{
                ...params.inputProps,
                endAdornment: (
                  <Button
                    className={classes.addButton}
                    variant="contained"
                    color="primary"
                    title="Add this access code"
                    onClick={handleAccessCodeAdded}
                    disabled={loading}
                  >
                    Add
                  </Button>
                ),
              }}
            ></EditField>
          )}
        />
      </form>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.saveUserButton}
          variant="contained"
          color="primary"
          title="Add this access code"
          onClick={handleSaveClicked}
          disabled={loading}
        >
          Save User
        </Button>
        {showLoadingSpinner()}
      </div>
    </Card>
  );
}

export default User;
