import { useContext } from "react";
import { useSnackbar } from "notistack";
import AddEditCourseDialog from "./AddEditCourseDialog";
import ClientContext from "../../contexts/ClientContext";
import useAuthenticatedRestCall from "../../hooks/useAuthenticatedRestCall";
import { useUploadCourseCompletionImage } from "../../hooks/useUploadCourseCompletionImage";

export default function AddCourseDialog(props) {
  const { clientInfo } = useContext(ClientContext);
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { enqueueSnackbar } = useSnackbar();
  const uploadCourseCompletionImage = useUploadCourseCompletionImage();

  const handleConfirm = (name, passingGrade, imageFile, titleTranslations) => {
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.levelId}/courses`,
      false,
      {
        data: {
          clientId: clientInfo.clientId,
          name,
          passingGrade,
          titleTranslations,
        },
        method: "POST",
      }
    ).then((response) => {
      enqueueSnackbar(`${name} created successfully!`, {
        variant: "success",
      });

      if (imageFile) {
        uploadCourseCompletionImage(props.levelId, response.data.id, imageFile);
      }

      props.onNewCourseCreated(response.data);
    });

    props.onClose();
  };

  return (
    <AddEditCourseDialog
      title="Create new course"
      open={props.open}
      onClose={props.onClose}
      onConfirm={handleConfirm}
      confirmText="Create course"
      levelId={props.levelId}
    />
  );
}
