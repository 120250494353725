import React, { useState, useCallback, Fragment } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';
import useToggle from '../../../../hooks/useToggle';

import { InputBase } from '@material-ui/core';
import isHotkey from 'is-hotkey';

import '../../../device-preview/modules/_expandables.scss';

const Definition = (props) => {
  const { element, previewMode } = props;
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(element.title || "Definition");
  const editor = useSlateStatic();

  const applyTitleChange = useCallback(
    (titleInput) => {
      const path = ReactEditor.findPath(editor, element);
      if (titleInput != null) {
        setTitle(titleInput);
      }

      Transforms.setNodes(
        editor,
        { title: titleInput },
        { at: path }
      );
    },
    [editor, setTitle, element]
  );

  const onTitleChange = useCallback(
    (event) => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (!isHotkey('enter', event)) {
        return;
      }
      if (isEditingTitle) {
        applyTitleChange(event.target.value);
        setEditingTitle(false);
      }
      return;
    },
    [isEditingTitle, applyTitleChange, setEditingTitle]
  );

  const onToggleTitleEditMode = useCallback(
    (event) => {
      const wasEditing = isEditingTitle;
      setEditingTitle(!isEditingTitle);
      wasEditing && applyTitleChange(title);
    },
    [isEditingTitle, applyTitleChange, title]
  );

  const [isOpen, toggleIsOpen] = useToggle(false);
  const handleClick = () => {
    if (previewMode) {
      toggleIsOpen(!!isOpen);
    }
  };

  if (previewMode) {
    return (
      <article
      className={`expandable definition ${
        previewMode && !isOpen ? 'closed' : 'open'
      }`}
    >
      <header className="title-bar" onClick={handleClick}>
        <h3 className="title" contentEditable={false}>
          {element.title}
        </h3>
      </header>
      <div className="body">
        <section>
          <p {...props.attributes}>{props.children}</p>
        </section>
      </div>
    </article>
    );
  }

  return (
    <article 
      className={`expandable definition ${
        previewMode && !isOpen ? 'closed' : 'open'
      }`}
    >
      <header
        className="title-bar"
        contentEditable={false}
      >
        {isEditingTitle ? (
          <InputBase
            className="title-field"
            autoFocus={true}
            defaultValue={element.title}
            onKeyDown={onKeyDown}
            placeholder={'Enter a title...'}
            onChange={onTitleChange}
            onBlur={onToggleTitleEditMode}
            inputProps={{ 'aria-label': 'definition-title' }}
          />
        ) : (
          <Fragment>
            {element.title && (
              <h3 className="title" onClick={onToggleTitleEditMode}>
                {element.title}
              </h3>
            )}
            {!element.title && (
              <h3 className="title no-content" onClick={onToggleTitleEditMode}>
                Enter a title for this definition...
              </h3>
            )}
          </Fragment>
        )}
      </header>
      <div className="body">
        <section>
          <p {...props.attributes}>{props.children}</p>
        </section>
      </div>
    </article>
  );
};

export default Definition;
