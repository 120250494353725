import { useCallback } from "react";
import useAuthenticatedRestCall from "./useAuthenticatedRestCall";

/**
 * Given a list of Content ids, this hook returns a function which fetches these Content objects
 *
 * @param {*} courseContentIdList A list of Content Ids
 * @param {*} courseId The ID of the course that contains these Content objects
 * @returns an array of promises containing the resulting Content objects
 */
export default function useCourseContentList(
  levelId,
  courseContentIdList,
  courseId
) {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  return useCallback(async () => {
    const childContentRequests = courseContentIdList.map((childId) =>
      makeAuthenticatedRequest(
        `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${levelId}/courses/${courseId}/content/${childId}`,
        false
      )
    );

    const childContentResponses = await Promise.all(childContentRequests);

    const childContent = childContentResponses.map((response) => response.data);

    return childContent;
  }, [makeAuthenticatedRequest, courseContentIdList, courseId, levelId]);
}
