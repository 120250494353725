import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    zIndex: 1,
  }
});

function FullScreenLoadingIndicator() {
  const classes = useStyles();

  return (
    <Backdrop open={true} className={classes.root}>
      <CircularProgress />
    </Backdrop>
  );
}

export default FullScreenLoadingIndicator;
