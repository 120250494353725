import ToolTip from '../components/ToolTip';

/**
 * This render function adds a tooltip to a grid cell
 *
 * @example
 * const columns = [
 *  {
 *    field: "name",
 *    headerName: "Name",
 *    renderCell: renderWithToolTip,
 *  }
 *]
 * @param {*} params
 * @returns
 */
export const renderWithToolTip = (params) => (
  <ToolTip title={params.value}>
    <span>{params.value}</span>
  </ToolTip>
);

/**
 * This render takes a date and converts it to a shorter MM/DD/YYYY format for display
 *
 * @param {Date} date
 * @returns MM/DD/YYYY
 */
export const shortDateFormatter = (date) =>
  new Date(date).toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
