import { Button, makeStyles, Slide } from "@material-ui/core";
import { useContext, useEffect, useRef } from "react";
import QuizEditorContext from "../../../contexts/QuizEditorContext";
import { useSlideAnimation } from "../../../hooks/useSlideAnimation";
import { addClassToDefaultIfTrue } from "../../../util/addClassToDefaultIfTrue";
import Card from "../../Card";
import EditField from "../../EditField";
import { getDefaultQuestion } from "./getDefaultQuestion";
import QuizQuestion from "./QuizQuestion";

const useStyles = makeStyles({
  questionEditorWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    marginBottom: "5rem",
    "&.hidden": {
      display: "none",
    },
  },
  questionEditor: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  addQuestionButton: {
    alignSelf: "end",
  },
  originalQuestionsWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  originalQuizQuestion: {
    marginBottom: "3rem",
    paddingBottom: "0",
    "& > div:first-child": {
      height: "10rem",
    },
  },
  originalAnswer: {
    height: "28rem",
  },
  originalFeedbackText: {
    marginBottom: "4.5rem",
  },
  translationEditor: {
    width: "50%",
  },
});

function QuizEditor({
  quizMode,
  translationMode,
  languageToEditId,
  translationModeHasBeenTriggered,
}) {
  const {
    questions,
    setQuestions,
    emptyQuestions,
    setEmptyQuestions,
    emptyAnswers,
    setEmptyAnswers,
  } = useContext(QuizEditorContext);
  const classes = useStyles();
  const questionEditorRef = useRef(null);

  const handleAddQuestion = () => {
    const newQuestion = getDefaultQuestion();

    const newAnswerIds = newQuestion.answers.map((answer) => answer.id);

    setQuestions([...questions, newQuestion]);
    setEmptyQuestions([...emptyQuestions, newQuestion.id]);
    setEmptyAnswers([...emptyAnswers, ...newAnswerIds]);
  };

  const renderQuestions = () =>
    questions.map((question, index) => (
      <QuizQuestion
        id={question.id}
        key={question.id}
        question={question}
        index={index}
        translationMode={translationMode}
        languageToEditId={languageToEditId}
      />
    ));

  const animateOriginalLanguageEditor = useSlideAnimation(
    questionEditorRef,
    translationModeHasBeenTriggered,
    translationMode
  );

  useEffect(() => {
    if (questionEditorRef.current) {
      animateOriginalLanguageEditor();
    }
  }, [animateOriginalLanguageEditor, questionEditorRef]);

  // TODO: clean up
  useEffect(() => {
    if (quizMode) {
      const quizTranslationExists = questions.some((question) =>
        question.questionTextTranslations.some(
          (translation) => translation.languageId === languageToEditId
        )
      );

      if (translationMode && !quizTranslationExists) {
        const questionsWithBlankTranslation = questions.map((question) => ({
          ...question,
          questionTextTranslations: [
            ...question.questionTextTranslations,
            { languageId: languageToEditId, translatedValue: "" },
          ],
          answers: question.answers.map((answer) => ({
            ...answer,
            answerTextTranslations: [
              ...answer.answerTextTranslations,
              { languageId: languageToEditId, translatedValue: "" },
            ],
            feedbackTextTranslations: [
              ...answer.feedbackTextTranslations,
              { languageId: languageToEditId, translatedValue: "" },
            ],
          })),
        }));

        setQuestions(questionsWithBlankTranslation);
      }
    }
  }, [languageToEditId, questions, setQuestions, translationMode, quizMode]);

  // TODO: cleanup
  const getOriginalQuestions = () =>
    questions.map((question) => (
      <Card className={classes.originalQuizQuestion} key={question.id}>
        <EditField
          id={`${question.id}-original-question-text`}
          value={question.questionText}
          label="Question text"
          disabled
        />
        {question.answers.map((answer) => (
          <div key={answer.id} className={classes.originalAnswer}>
            <EditField
              id={`${answer.id}-original-answer-text`}
              value={answer.answerText}
              label="Answer text"
              disabled
            />
            <EditField
              id={`${answer.id}-original-feedback-text`}
              value={answer.feedbackText}
              label="Feedback text"
              disabled
            />
          </div>
        ))}
      </Card>
    ));

  const getOriginalQuestionsIfTranslationMode = () =>
    translationMode ? getOriginalQuestions() : renderQuestions();

  const renderAddQuestionButtonIfNotTranslationMode = () =>
    translationMode ? null : (
      <Button
        className={classes.addQuestionButton}
        color="primary"
        variant="contained"
        onClick={handleAddQuestion}
      >
        Add question
      </Button>
    );

  return (
    <div
      className={addClassToDefaultIfTrue(
        classes.questionEditorWrapper,
        !quizMode,
        "hidden"
      )}
    >
      <div className={classes.questionEditor} ref={questionEditorRef}>
        {getOriginalQuestionsIfTranslationMode()}
        {renderAddQuestionButtonIfNotTranslationMode()}
      </div>
      <Slide direction="up" in={translationMode} mountOnEnter unmountOnExit>
        <div className={classes.translationEditor}>{renderQuestions()}</div>
      </Slide>
    </div>
  );
}

export default QuizEditor;
