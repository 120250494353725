import { createContext } from "react";

export default createContext({
  clientInfo: {
    loadingClients: true,
    clientId: "",
    clientsHaveLoaded: false,
    errorOccurredLoadingClients: false,
  },
  setClientInfo: () => {},
});
