import { useCallback } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { useSnackbar } from "notistack";

/**
 * This hook returns a fetch function which automatically passes along keycloak
 * auth information in its header.
 *
 * The returned function has the following options:
 * - url: the url of the request to make
 * - showErrorToast: When true, an error toast is shown if the request fails, and no error is thrown. When false,
 *      an error is thrown and must be handled by the caller
 * - configOverrides: An object containing all axios config properties you'd like to override
 *
 * @returns
 */
function useAuthenticatedRestCall() {
  const { initialized, keycloak } = useKeycloak();
  const { enqueueSnackbar } = useSnackbar();

  const getConfig = useCallback(
    (url) => ({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }),
    [keycloak.token]
  );

  const getErrorMessage = useCallback((error) => {
    if (error.response?.data?.errors) {
      return error.response.data.errors.map((error) => error.message);
    }

    return error.message;
  }, []);

  const fetchAuthenticated = useCallback(
    async (url, showErrorToast = true, configOverrides, customHeaders) => {
      try {
        if (!initialized) {
          console.error(
            "An attempt was made to fetch data before Keycloak was initialized"
          );

          return Promise.reject();
        }

        const config = getConfig(url);
        config.headers = { ...config.headers, ...customHeaders };

        return await axios({ ...config, ...configOverrides });
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        if (showErrorToast) {
          enqueueSnackbar(errorMessage, { variant: "error" });
        } else {
          throw errorMessage;
        }
      }
    },
    [enqueueSnackbar, getConfig, getErrorMessage, initialized]
  );

  return fetchAuthenticated;
}

export default useAuthenticatedRestCall;
