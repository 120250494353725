import { getAdminClientRoles } from "../util/getAdminClientRoles";
import {
  getAuthUserClientRoleMappingsUrl,
  getAuthUserRealmRoleMappingsUrl,
  getAuthUsersUrl,
} from "../util/getUrls";
import useAuthenticatedRestCall from "./useAuthenticatedRestCall";

// TODO: clean up
export const useCreateAdminAuthUser = () => {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  const createNewUser = async (
    username,
    email,
    firstName,
    lastName,
    password
  ) =>
    makeAuthenticatedRequest(getAuthUsersUrl(), false, {
      data: {
        // Despite what the keycloak docs say, usernames are required and a 400 will be returned if they aren't added
        username,
        email,
        firstName,
        lastName,
        credentials: [
          {
            value: password,
            temporary: true,
            type: "password",
          },
        ],
        enabled: true
      },
      method: "POST",
    });

  const fetchCreatedUserId = async (email) => {
    const newUserResponse = await makeAuthenticatedRequest(
      `${getAuthUsersUrl()}?email=${email}&exact=true`,
      false
    );

    return newUserResponse.data[0];
  };

  const addRealmAdminRoleToUser = async (newUserId) =>
    makeAuthenticatedRequest(
      getAuthUserRealmRoleMappingsUrl(newUserId),
      false,
      {
        data: [
          {
            id: process.env.REACT_APP_KEYCLOAK_REALM_ADMIN_ROLE_ID,
            name: process.env.REACT_APP_KEYCLOAK_REALM_ADMIN_ROLE_NAME,
          },
        ],
        method: "POST",
      }
    );

  const addRealmManagementClientRolesToUser = async (newUserId) =>
    makeAuthenticatedRequest(
      getAuthUserClientRoleMappingsUrl(newUserId),
      false,
      {
        data: getAdminClientRoles(),
        method: "POST",
      }
    );

  return async (username, email, firstName, lastName, password) => {
    await createNewUser(username, email, firstName, lastName, password);

    // The keycloak API doc specifies a "realmRoles" property when a user is POSTed. It is, however, ignored. So, we have to make
    // successive calls to add our roles.

    // The POST user request doesn't properly return the id of the new user, so, unbelievably, we have to turn around and ask for it now:
    const newUser = await fetchCreatedUserId(email);

    await addRealmAdminRoleToUser(newUser.id);
    await addRealmManagementClientRolesToUser(newUser.id);

    return newUser;
  };
};
