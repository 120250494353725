import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";
import { DRAG_DROP_TYPES } from "../../constants/dragDropTypes";
import LevelContentListTitle from "./LevelContentListTitle";
import ContentListItem from "./ContentListItem";
import { addOrMoveItemAfterOtherItem } from "../../util/contentArrayUtils";
import EmptyPreviewImage from "./EmptyPreviewImage";
import useCourseContentList from "../../hooks/useCourseContentList";
import { addClassToDefaultIfTrue } from "../../util/addClassToDefaultIfTrue";
import { useHistory } from "react-router";
import GarnishDialog from "../GarnishDialog";
import EditCourseDialog from "./EditCourseDialog";
import useAuthenticatedRestCall from "../../hooks/useAuthenticatedRestCall";
import { useSnackbar } from "notistack";
import GarnishAccordion from "../GarnishAccordion";

const useStyles = makeStyles({
  contentParent: {
    width: "100%",
    borderBottom: "2px solid var(--dark-grey)",
    minHeight: "4.8rem",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  parentTitleContainer: {
    minHeight: "4.8rem",
    display: "flex",
    flexDirection: "column",
    cursor: "move",
    width: "100%"
  },
  parentTitle: {
    paddingLeft: "6rem",
    transition: "opacity 400ms ease",
    "&.dragging": {
      opacity: ".5",
    },
  },
  subPageContainer: {
    display: "flex",
    flexDirection: "column",
    "&.subpage-dragged-in": {
      boxShadow: "0px 2px 13px 0px var(--garnish-primary-green)",
    },
  },
  subpage: {
    paddingLeft: "9rem",
    minHeight: "4.8rem",
    borderBottom: "2px solid var(--dark-grey)",
    "&:last-child": {
      borderBottom: "none",
    },
    background: "var(--light-grey)",
  },
  childDropZone: {
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
  },
  siblingDropZone: {
    height: "1rem",
    opacity: "0",
    boxShadow: "0px 6px 7px 0px var(--garnish-primary-green)",
    "&.drag-over": {
      opacity: "1",
    },
    transition: "opacity 200ms ease",
    position: "absolute",
    bottom: "5.5rem",
    width: "100%",
  },
  loadingSpinner: {
    margin: "1.4rem auto 1.4rem 11rem",
    height: "20px !important",
    width: "20px !important",
  },
  addNewContentButton: {
    textTransform: "none",
    width: "max-content",
    marginLeft: "4rem",
    margin: "1rem 0",
  },
});

function Course(props) {
  const classes = useStyles();
  const history = useHistory();
  const [childContent, setChildContent] = useState(null);
  const [editCourseDialogOpen, setEditCourseDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [deletingContent, setDeletingContent] = useState(false);
  const [updatedCourse, setUpdatedCourse] = useState(null);
  const [contentToDeleteId, setContentToDeleteId] = useState(null);
  const [contentToDeleteName, setContentToDeleteName] = useState(null);
  const [confirmDeleteContentDialogOpen, setConfirmDeleteContentDialogOpen] =
    useState(false);
  const fetchContentList = useCourseContentList(
    props.course.levelId,
    props.course.children,
    props.course.id
  );
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { enqueueSnackbar } = useSnackbar();

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: DRAG_DROP_TYPES.course,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: {
      id: props.course.id,
      unpublished: false,
      title: props.course.name,
      course: props.course,
    },
  }));

  const updateCourseChildren = (updatedCourseChildren) => {
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.course.levelId}/courses/${props.course.id}`,
      true,
      {
        data: {
          children: updatedCourseChildren.map((c) => c.id),
        },
        method: "PUT",
      }
    );
  }

  const moveContentWithinCourse = (content) => {
    const childrenWithoutMovedItem = childContent.filter(
      (child) => child.id !== content.id
    );

    const updatedChildren = [content, ...childrenWithoutMovedItem];

    updateCourseChildren(updatedChildren);
    setChildContent(updatedChildren);
  };

  const handleDropAsFirstItem = (item) => {
    if (item.content.parentId === props.contentId) {
      moveContentWithinCourse(item.content);
    }
  };

  const [{ isOver: isOverAsChild }, dropAsChild] = useDrop(
    () => ({
      accept: [DRAG_DROP_TYPES.content],
      drop: handleDropAsFirstItem,
      collect: (monitor) => ({
        isOver: !!monitor.isOver() && monitor.canDrop(),
      }),
      canDrop: (item) => item.content.id !== childContent[0].id && item.parentId === props.course.id
    }),
    [props.onContentDroppedAsFirstItemInCourse, childContent]
  );

  const [{ isOver: isOverAsSibling }, dropAsSibling] = useDrop(
    () => ({
      accept: [DRAG_DROP_TYPES.course],
      drop: (item) => {
        props.onCourseDraggedAfterOtherCourse(props.course.id, item.course);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver() && monitor.canDrop(),
      }),
      canDrop: (item) => item.id !== props.id,
    }),
    [props.onContentDraggedAfter]
  );

  useEffect(() => {
    const fetchChildren = async () => {
      const childContent = await fetchContentList();

      setChildContent(childContent);
    };

    if (props.course.children) {
      fetchChildren();
    }
  }, [props.course, fetchContentList]);

  const handleDeleteContentClicked = (contentId, contentName) => {
    setContentToDeleteId(contentId);
    setContentToDeleteName(contentName);
    setConfirmDeleteContentDialogOpen(true);
  };

  const handleDeleteContent = () => {
    setDeletingContent(true);

    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.course.levelId}/courses/${props.course.id}/content/${contentToDeleteId}`,
      false,
      {
        method: "DELETE",
      }
    )
      .then(() => {
        enqueueSnackbar(`${contentToDeleteName} was deleted successfully`, {
          variant: "success",
        });

        setConfirmDeleteContentDialogOpen(false);

        const updatedChildContent = childContent.filter(
          (content) => content.id !== contentToDeleteId
        );

        setChildContent(updatedChildContent);
      })
      .catch(() => {
        enqueueSnackbar("An error occurred while deleting the content", {
          variant: "error",
        });
      })
      .finally(() => {
        setDeletingContent(false);
      });
  };

  const handleContentDraggedAfterOtherContent = (contentId, draggedContent) => {
    if (draggedContent.courseId === props.course.id) {
      const updatedChildContent = addOrMoveItemAfterOtherItem(
        childContent,
        draggedContent,
        contentId
      );

      setChildContent(updatedChildContent);
      updateCourseChildren(updatedChildContent);
    }
  };

  const renderChildren = () =>
    childContent.map((child) => (
      <ContentListItem
        key={child.id}
        title={child.title}
        published={child.published}
        content={child}
        supportedLanguageIds={child.availableTranslations}
        courseId={props.course.id}
        onContentDraggedAfterOtherContent={
          handleContentDraggedAfterOtherContent
        }
        onDeleteClicked={handleDeleteContentClicked}
        levelId={props.course.levelId}
      />
    ));

  const renderChildrenIfLoaded = () => {
    if (childContent) {
      return renderChildren();
    } else if (props.course.children && childContent === null) {
      return <CircularProgress className={classes.loadingSpinner} />;
    }
  };

  const getSiblingDropZoneClass = () =>
    addClassToDefaultIfTrue(
      classes.siblingDropZone,
      isOverAsSibling,
      "drag-over"
    );

  const getCourseContentListTitleClass = () =>
    addClassToDefaultIfTrue(classes.parentTitle, isDragging, "dragging");

  const handleEditClicked = () => {
    setEditCourseDialogOpen(true);
  };

  const handleDeleteCourseClicked = () => {
    setConfirmDeleteDialogOpen(true);
  };

  const handleDeleteDialogClosed = () => {
    setConfirmDeleteDialogOpen(false);
  };

  const handleEditCourseDialogClosed = () => setEditCourseDialogOpen(false);

  const handleCourseModified = (updatedCourse) => {
    setUpdatedCourse(updatedCourse);
  };

  const handleDeleteCourse = () => {
    setDeletingContent(true);

    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.course.levelId}/courses/${props.course.id}`,
      false,
      {
        method: "DELETE",
      }
    )
      .then(() => {
        enqueueSnackbar(`${props.course.name} was deleted successfully`, {
          variant: "success",
        });

        props.onCourseDeleted(props.course.id);
      })
      .catch(() => {
        enqueueSnackbar("An error occurred while deleting the course", {
          variant: "error",
        });
      })
      .finally(() => {
        setDeletingContent(false);
      });
  };

  const getCourseName = () => updatedCourse?.name || props.course.name;

  const handleAddNewContentClicked = () => {
    history.push(
      `/levels/${props.course.levelId}/courses/${props.course.id}/add-new-content`
    );
  };

  const handleDeleteContentDialogClosed = () =>
    setConfirmDeleteContentDialogOpen(false);

  const getSupportedLanguageIds = () => {
    const course = updatedCourse || props.course;
    return course.titleTranslations.map(
      (translation) => translation.languageId
    );
  };

  const getAccordionTitleContent = () => (<div className={classes.parentTitleContainer} ref={drag}>
    <div className={classes.childDropZone} ref={dropAsChild}>
      <LevelContentListTitle
        title={getCourseName()}
        className={getCourseContentListTitleClass()}
        onEditClicked={handleEditClicked}
        showEditButton={true}
        onDeleteClicked={handleDeleteCourseClicked}
        supportedLanguageIds={getSupportedLanguageIds()}
      />
    </div>
  </div>)

  return (
    <GarnishAccordion
      title={getAccordionTitleContent()}
      noSideBorders={true}
      highlighted={isOverAsChild}
    >
      <div className={classes.subPageContainer}>{renderChildrenIfLoaded()}</div>
      <div className={getSiblingDropZoneClass()} test="test" ref={dropAsSibling}></div>
      <Button
        color="primary"
        classes={{ root: classes.addNewContentButton }}
        onClick={handleAddNewContentClicked}
      >
        Add new content to "{getCourseName()}"
      </Button>
      <EmptyPreviewImage preview={preview} />
      <EditCourseDialog
        open={editCourseDialogOpen}
        course={updatedCourse || props.course}
        onClose={handleEditCourseDialogClosed}
        onCourseModified={handleCourseModified}
        levelId={props.course.levelId}
      />
      <GarnishDialog
        title="Are you sure?"
        open={confirmDeleteDialogOpen}
        onClose={handleDeleteDialogClosed}
        onConfirm={handleDeleteCourse}
        confirmText={"Delete content"}
        confirming={deletingContent}
      >
        Are you sure you want to delete "{getCourseName()}"? This can't be
        undone.
      </GarnishDialog>
      <GarnishDialog
        title="Are you sure?"
        open={confirmDeleteContentDialogOpen}
        onClose={handleDeleteContentDialogClosed}
        onConfirm={handleDeleteContent}
        confirmText={"Delete content"}
        confirming={deletingContent}
      >
        Are you sure you want to delete "{contentToDeleteName}"? This can't be
        undone.
      </GarnishDialog>
    </GarnishAccordion>
  );
}

export default Course;
