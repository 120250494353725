import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addClassToDefaultIfTrue } from "../util/addClassToDefaultIfTrue";
import { addClassToDefaultIfDefined } from "../util/addClassToDefaultIfDefined";

const useStyles = makeStyles({
  card: {
    background: "white",
    border: "1px solid var(--dark-grey)",
    padding: "2.5rem",
    textAlign: "left",
    "&.no-padding": {
      padding: 0,
    },
    "&.hidden": {
      display: "none",
    },
  },
});

function Card(props) {
  const classes = useStyles();

  const getContent = () =>
    props.isLoading ? <CircularProgress /> : props.children;

  const getClasses = () => {
    const classWithPadding = addClassToDefaultIfTrue(
      classes.card,
      props.noPadding,
      "no-padding"
    );

    const classWithHidden = addClassToDefaultIfTrue(classWithPadding, props.hidden, "hidden");
    return addClassToDefaultIfDefined(classWithHidden, props.className)
  };

  return <section className={getClasses()}>{getContent()}</section>;
}

export default Card;
