import { useContext, useCallback } from 'react';
import { Editor, Transforms } from 'slate';
import { v4 as uuidv4 } from 'uuid';
import ClientContext from '../contexts/ClientContext';
import useAuthenticatedRestCall from '../hooks/useAuthenticatedRestCall';

export const useUploadCourseContentImage = (editor, selection) => {
  const { clientInfo } = useContext(ClientContext);
  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  return useCallback(
    (event) => {
      event.preventDefault();
      const id = uuidv4();

      const files = event.target.files;
      if (files.length === 0) {
        return;
      }
      const file = files[0];
      const tempImg = URL.createObjectURL(file);
      const formData = new FormData();
      const date = Date.now();
      const uniqueFileName = `${date}-${id}-${file.name}`;
      formData.append('upload', file, uniqueFileName);

      Transforms.insertNodes(
        editor,
        [
          {
            id,
            type: 'image',
            title: '',
            caption: '',
            style: 'light',
            src: tempImg,
            isUploading: true,
            children: [{ text: '' }],
          },
          // {
          //   type: 'paragraph',
          //   children: [{ text: '' }],
          // },
        ],
        { at: selection, select: true }
      );

      return makeAuthenticatedRequest(
        `${process.env.REACT_APP_IMAGE_SERVICE_URL}/clients/${clientInfo.clientId}/course-images`,
        true,
        {
          data: formData,
          method: 'POST',
        },
        { 'Content-Type': 'multipart/form-data' }
      )
        .then((response) => {
          const { location } = response.data;
          const newImageEntry = Editor.nodes(editor, {
            match: (n) => n.id === id,
          });
          if (newImageEntry == null) {
            return;
          }
          Transforms.setNodes(
            editor,
            { isUploading: false, src: location },
            { at: newImageEntry[1] }
          );
          Transforms.insertNodes(
            editor,
            [
              {
                type: 'paragraph',
                children: [{ text: '' }],
              },
            ],
            { at: selection, select: true }
          );
        })
        .catch((error) => {
          // Fire another Transform.setNodes to set an upload failed state on the image
          console.log(error);
        });
    },
    [editor, selection, clientInfo.clientId, makeAuthenticatedRequest]
  );
};
