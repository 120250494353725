import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  imageContainer: {
    width: "25rem",
    minHeight: "25rem",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "center",
    "& > img": {
      width: "25rem",
      margin: "0 auto",
      objectFit: "contain"
    },
  },
  noImage: {
    background: "var(--grey)",
    borderRadius: ".5rem",
    height: "25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& > svg": {
      height: "15rem",
      width: "15rem",
      color: "var(--dark-grey)",
    },
  },
});

/**
 * This component either renders the image with the src specified, or shows a placeholder image.
 */
function ImageContainer(props) {
  const classes = useStyles()
  const getImagePreview = () => {
    if (props.isLoading) {
      return <CircularProgress />
    }

    if (props.imageSrc) {
      return (
        <img
          src={props.imageSrc}
          alt={props.imageAlt || "User-uploaded image"}
        ></img>
      );
    }

    return (
      <div className={classes.noImage}>
        <ImageIcon />
      </div>
    )
  }

  return <div className={classes.imageContainer}>{getImagePreview()}</div>
}

ImageContainer.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  isLoading: PropTypes.bool
}



export default ImageContainer