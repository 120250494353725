import PropTypes from 'prop-types'
import EditField from "../EditField"
import { ReactComponent as EditIcon } from "../../images/pencil.svg";
import { useCallback, useEffect } from 'react';

const NAME_REQUIRED_ERROR = "Please supply a name for the client"

export function ClientNameInput({
    value, onError, onChange, error, dirty, disabled
}) {
    const handleNameChange = useCallback((value) => {
        if (value.trim().length === 0) {
            onError(NAME_REQUIRED_ERROR)
        } else {
            onError(null)
        }

        onChange(value)
    }, [onError, onChange])

    useEffect(() => {
        if (dirty) {
            handleNameChange(value)
        }
    }, [dirty, value, handleNameChange])

    return (<EditField
        id="new-client-title"
        placeholder="Client Name"
        label="name"
        value={value}
        onChange={event => handleNameChange(event.target.value)}
        endAdornment={<EditIcon />}
        disabled={disabled}
        error={error !== null}
        helperText={error}
    />)
}

ClientNameInput.propTypes = {
    value: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    dirty: PropTypes.bool
}

export default ClientNameInput