import { Fragment, useCallback, useMemo, useState, useEffect } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import useAuthenticatedRestCall from "../../hooks/useAuthenticatedRestCall";
import { getLevel } from "../../util/getUrls";
import { renderWithToolTip } from "../../util/gridUtils";

const useStyles = makeStyles({
  adminGrid: {
    color: "var(--darker-grey)",
    '& .MuiDataGrid-cell[data-field="editSpacer"]': {
      paddingLeft: "5px",
      "& button": {
        padding: ".4rem",
      },
    },
    '& .MuiDataGrid-cell[data-field="code"]': {
      fontWeight: "500",
      color: "#212529",
    },
    "& .MuiDataGrid-colCellTitle": {
      fontWeight: "normal",
      color: "#3A3B3F",
    },
  },
});

function AdminAccessCodesGrid(props) {
  const classes = useStyles();
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const [accessCodeRows, setAccessCodeRows] = useState([]);

  const fetchLevelNames = useCallback(
    async (levelIds) => {
      const levelRequests = levelIds.map((id) =>
        makeAuthenticatedRequest(getLevel(id))
      );

      const levels = await Promise.all(levelRequests);

      return levels.map((levelResponse) => levelResponse.data.title).join(", ");
    },
    [makeAuthenticatedRequest]
  );

  const fetchLevelNamesForAccessCode = useCallback(
    async (accessCode) => {
      const levelIds = accessCode.levels.map((level) => level.levelId);

      return fetchLevelNames(levelIds);
    },
    [fetchLevelNames]
  );

  const mapAccessCode = useCallback(
    async (accessCode) => {
      const levelNames = await fetchLevelNamesForAccessCode(accessCode);

      return {
        id: accessCode.id,
        code: accessCode.code,
        levelNames: levelNames,
      };
    },
    [fetchLevelNamesForAccessCode]
  );

  useEffect(() => {
    const mapAccessCodeResponseToRows = async (response) => {
      const mapResponses = props.accessCodes.map(mapAccessCode);

      const accessCodeRows = await Promise.all(mapResponses);

      setAccessCodeRows(accessCodeRows);
    };

    mapAccessCodeResponseToRows();
  }, [props.accessCodes, mapAccessCode]);

  const columns = useMemo(
    () => [
      {
        field: "editSpacer",
        headerName: " ",
        width: 60,
        renderCell: (params) => (
          <Fragment>
            <IconButton onClick={() => props.onDeleteClicked(params.id)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => props.onEditClicked(params.id)}>
              <EditIcon />
            </IconButton>
          </Fragment>
        ),
      },
      {
        field: "code",
        headerName: "Access Code",
        flex: 0.15,
        renderCell: renderWithToolTip,
      },
      {
        field: "levelNames",
        headerName: "Levels",
        flex: 0.9,
        renderCell: renderWithToolTip,
      },
    ],
    [props]
  );

  const [pageSize, setPageSize] = useState(25);

  return (
    <DataGrid
      className={classes.adminGrid}
      rows={accessCodeRows}
      columns={columns}
      error={props.error}
      loading={props.loading}
      autoHeight
      disableColumnSelector
      disableSelectionOnClick
      pagination
      pageSize={pageSize}
      onPageSizeChange={(params) => setPageSize(params.pageSize)}
    />
  );
}

export default AdminAccessCodesGrid;
