import { QUESTION_TYPES } from "./questionTypes";

export const getDefaultAnswer = (correct = false) => ({
  id: `${Math.random()}`,
  answerText: "",
  answerTextTranslations: [],
  correct,
  feedbackText: "",
  feedbackTextTranslations: [],
});

export const getDefaultQuestion = () => ({
  id: `${Math.random()}`,
  questionText: "",
  type: QUESTION_TYPES.singleCorrectAnswer,
  questionTextTranslations: [],
  answers: [getDefaultAnswer(true), getDefaultAnswer()],
});
