import React, { useState, useCallback, Fragment } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';
import { InputBase } from '@material-ui/core';
import isHotkey from 'is-hotkey';

import '../../../device-preview/modules/_lists.scss';

const NumberedList = ({ attributes, children, element, previewMode }) => {
  const [isEditingSubtitle, setEditingSubtitle] = useState(false);
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [subtitle, setSubtitle] = useState(element.subtitle);
  const [title, setTitle] = useState(element.title);
  const editor = useSlateStatic();
  const {align, type} = element;

  const applySubtitleChange = useCallback(
    (subtitleInput) => {
      const path = ReactEditor.findPath(editor, element);
      if (subtitleInput != null) {
        setSubtitle(subtitleInput);
      }

      Transforms.setNodes(
        editor,
        { subtitle: subtitleInput },
        { at: path }
      );
    },
    [editor, setSubtitle, element]
  );

  const applyTitleChange = useCallback(
    (titleInput) => {
      const path = ReactEditor.findPath(editor, element);
      if (titleInput != null) {
        setTitle(titleInput);
      }

      Transforms.setNodes(
        editor,
        { title: titleInput },
        { at: path }
      );
    },
    [editor, setTitle, element]
  );


  const onSubtitleChange = useCallback(
    (event) => {
      setSubtitle(event.target.value);
    },
    [setSubtitle]
  );

  const onTitleChange = useCallback(
    (event) => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (!isHotkey('enter', event)) {
        return;
      }
      if (isEditingSubtitle) {
        applySubtitleChange(event.target.value);
        setEditingSubtitle(false);
      }
      if (isEditingTitle) {
        applyTitleChange(event.target.value);
        setEditingTitle(false);
      }
      return;
    },
    [
      isEditingSubtitle,
      applySubtitleChange,
      setEditingSubtitle,
      isEditingTitle,
      applyTitleChange,
      setEditingTitle,
    ]
  );

  const onToggleSubtitleEditMode = useCallback(
    (event) => {
      const wasEditing = isEditingSubtitle;
      setEditingSubtitle(!isEditingSubtitle);
      wasEditing && applySubtitleChange(subtitle);
    },
    [isEditingSubtitle, applySubtitleChange, subtitle]
  );

  const onToggleTitleEditMode = useCallback(
    (event) => {
      const wasEditing = isEditingTitle;
      setEditingTitle(!isEditingTitle);
      wasEditing && applyTitleChange(title);
    },
    [isEditingTitle, applyTitleChange, title]
  );

  if(previewMode) {
    return (
      <div className={`list__wrapper ${type ? type : ''}`}>
        {(element.title || element.subtitle) &&
          <header contentEditable={false}>
            {element.title && <h2 className="title">{element.title}</h2>}
            {element.subtitle && <h3 className="subtitle">{element.subtitle}</h3>}
          </header>
        }
        <ol className={`list numbered-list ${align ? align : ''}`} {...attributes}>
          {children}
        </ol>
      </div>
    );
  }

  return (
    <div className={`list__wrapper ${type ? type : ''}`}>
      <header contentEditable={false}>
      {isEditingTitle ? (
          <InputBase
            className="title-field"
            autoFocus={true}
            defaultValue={element.title}
            onKeyDown={onKeyDown}
            placeholder={'Enter a title...'}
            onChange={onTitleChange}
            onBlur={onToggleTitleEditMode}
            inputProps={{ 'aria-label': 'list-title' }}
          />
        ) : (
          <Fragment>
            {element.title && (
              <h2 className="title" onClick={onToggleTitleEditMode}>
                {element.title}
              </h2>
            )}
            {!element.title && (
              <h2 className="title no-content" onClick={onToggleTitleEditMode}>
                Enter a title...
              </h2>
            )}
          </Fragment>
        )}
        {isEditingSubtitle ? (
          <InputBase
            className="subtitle-field"
            autoFocus={true}
            defaultValue={element.subtitle}
            onKeyDown={onKeyDown}
            placeholder={'Enter a subtitle...'}
            onChange={onSubtitleChange}
            onBlur={onToggleSubtitleEditMode}
            inputProps={{ 'aria-label': 'list-subtitle' }}
          />
        ) : (
          <Fragment>
            {element.subtitle && (
              <h3 className="subtitle" onClick={onToggleSubtitleEditMode}>
                {element.subtitle}
              </h3>
            )}
            {!element.subtitle && (
              <h3 className="subtitle no-content" onClick={onToggleSubtitleEditMode}>
                Enter a subtitle...
              </h3>
            )}
          </Fragment>
        )}
      </header>
      <ol className={`list numbered-list ${align ? align : ''}`} {...attributes}>
        {children}
      </ol>
    </div>
  );
};

export default NumberedList;
