import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { addClassToDefaultIfTrue } from "../util/addClassToDefaultIfTrue";

const useStyles = makeStyles({
  accordion: {
    boxShadow: "none",
    border: "2px solid var(--dark-grey)",
    borderBottomWidth: "0px",
    borderRadius: "0px",
    marginBottom: "0",
    "&:not($accordion:nth-of-type(1))": {
      borderTopWidth: "0px",
    },
    "&.Mui-expanded": {
      margin: "0",
      borderBottomWidth: "2px",
    },
    "&::before": {
      visibility: "hidden",
    },
    "&.no-side-borders": {
      borderLeftWidth: 0,
      borderRightWidth: 0
    },
    transition: "border-bottom-width 0ms linear 200ms",
  },
  accordionSummaryRoot: {
    margin: "0",
    // This value is overwritten by Material UI using javascript :/
    minHeight: "4.8rem !important",
    borderBottom: "2px solid var(--dark-grey)",
    transition: "border-bottom 0ms linear 200ms",
  },
  accordionSummaryContent: {
    alignItems: "center",
    margin: "0",
    "&.Mui-expanded": {
      margin: "0",
    },
  },
  accordionContent: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    padding: "0",
    cursor: "",
  },
  accordionExpandIcon: {
    "& svg": {
      width: "2.5rem",
      height: "2.5rem",
    },
  },
  addNewContentContainer: {
    minHeight: "4.8rem",
    padding: "1rem 6rem",
    display: "flex",
    alignItems: "center",
  },
  addNewContentButton: {
    fontSize: "1.3rem",
    textTransform: "none",
  },
  titleWrapper: {
    position: "relative",
    zIndex: 1,

    "&.highlighted": {
      boxShadow: "0px 6px 7px 0px var(--garnish-primary-green)",
    }
  }
});

function GarnishAccordion(props) {
  const classes = useStyles();

  return (
    <Accordion
      classes={{
        root: addClassToDefaultIfTrue(classes.accordion, props.noSideBorders, "no-side-borders"),
      }}
      square
      onChange={props.onToggle}
    >
      <div className={addClassToDefaultIfTrue(classes.titleWrapper, props.highlighted, "highlighted")}>
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
            expandIcon: classes.accordionExpandIcon,
          }}
          expandIcon={<ArrowDropDownIcon />}
        >
          {props.startContent}
          {props.title}
        </AccordionSummary>
      </div>
      <AccordionDetails className={classes.accordionContent}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}

export default GarnishAccordion;
