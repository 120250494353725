import { useKeycloak } from "@react-keycloak/web";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import garnishLogo from "../images/garnish-logo.png";
import { ReactComponent as LogoutIcon } from "../images/logout-icon.svg";
import ClientDropdown from "./ClientDropdown";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "var(--garnish-dark-green)",
    minHeight: "6.4rem",
    paddingLeft: "1.5rem",
    position: "sticky",
    top: 0,
    zIndex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  garnishLogo: {
    height: "4.6rem",
  },
  logoutBtn: {
    marginRight: "2rem",
  },
});

function MainHeader(props) {
  const { keycloak } = useKeycloak();
  const classes = useStyles();

  return (
    <header className={classes.root}>
      <Link to="/">
        <img
          className={classes.garnishLogo}
          src={garnishLogo}
          alt="Garnish Global logo"
        />
      </Link>
      <ClientDropdown clientList={props.clientList} />
      <IconButton
        title="Log out"
        aria-label="log out"
        className={classes.logoutBtn}
        onClick={() => {
          keycloak.logout();
        }}
      >
        <LogoutIcon />
      </IconButton>
    </header>
  );
}

export default MainHeader;
