import React, { useCallback } from 'react';
import isHotkey from 'is-hotkey';
import { Transforms } from 'slate';
import { toggleMark } from '../components/courses/slateEditor/SlateEditorUtils';
import DefaultElement from '../components/courses/slateEditor/contentTypes/DefaultElement';
import Blockquote from '../components/courses/slateEditor/contentTypes/Blockquote';
import DidYouKnow from '../components/courses/slateEditor/contentTypes/DidYouKnow';
import Definition from '../components/courses/slateEditor/contentTypes/Definition';
import Divider from '../components/courses/slateEditor/contentTypes/Divider';
import Example from '../components/courses/slateEditor/contentTypes/Example';
import GarnishTip from '../components/courses/slateEditor/contentTypes/GarnishTip';
import Heading from '../components/courses/slateEditor/contentTypes/Heading';
import Image from '../components/courses/slateEditor/contentTypes/Image';
import Link from '../components/courses/slateEditor/contentTypes/Link';
import NumberedList from '../components/courses/slateEditor/contentTypes/NumberedList';
import BulletedList from '../components/courses/slateEditor/contentTypes/BulletedList';
import Video from '../components/courses/slateEditor/contentTypes/Video';

export default function useEditorConfig(editor) {
  const { isVoid } = editor;
  const onKeyDown = useCallback(
    (event) => KeyBindings.onKeyDown(editor, event),
    [editor]
  );
  editor.isVoid = (element) => {
    return (
      ['image', 'video', 'divider'].includes(element.type) || isVoid(element)
    );
  };
  editor.isInline = (element) => ['link'].includes(element.type);
  return { renderElement, renderLeaf, onKeyDown };
}

const KeyBindings = {
  onKeyDown: (editor, event) => {
    if (event.key === 'Enter' && event.shiftKey === true) {
      event.preventDefault();
      Transforms.insertText(editor, '\n', { at: editor.selection });
      return;
    }
    if (isHotkey('mod+b', event)) {
      toggleMark(editor, 'bold');
      return;
    }
    if (isHotkey('mod+i', event)) {
      toggleMark(editor, 'italic');
      return;
    }
    if (isHotkey('mod+u', event)) {
      toggleMark(editor, 'underline');
      return;
    }
  },
};

const renderElement = (props) => {
  const { attributes, children, element, previewMode } = props;
  switch (element.type) {
    case 'blockquote':
      return <Blockquote {...props} />;
    case 'bulleted-list':
      return <BulletedList {...props} />;
    case 'bulleted-list-standard':
      return <BulletedList className="standard" {...props} />;
    case 'bulleted-list-fancy':
      return <BulletedList className="fancy" {...props} />;
    case 'definition':
      return <Definition previewMode={previewMode} {...props} />;
    case 'did-you-know':
      return <DidYouKnow previewMode={previewMode} {...props} />;
    case 'divider':
      return <Divider {...props}/>;
    case 'garnish-tip':
      return <GarnishTip previewMode={previewMode} {...props} />;
    case 'example':
      return <Example {...props} />;
    case 'heading-one':
      return <Heading {...props} />;
    case 'heading-two':
      return <Heading {...props} />;
    case 'heading-three':
      return <Heading {...props} />;
    case 'heading-four':
      return <Heading {...props} />;
    case 'heading-five':
      return <Heading {...props} />;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <NumberedList {...props} />;
    case 'numbered-list-standard':
      return <NumberedList className="standard" {...props} />;
    case 'numbered-list-fancy':
      return <NumberedList className="fancy" {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'video':
      return <Video {...props} />;
    case 'link':
      return <Link {...props} url={element.url} />;
    default:
      return <DefaultElement {...props} />;
  }
};

const renderLeaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export const SlateElement = (props) => {
  return renderElement(props);
};

export const SlateLeaf = ({ attributes, children, leaf }) => {
  return renderLeaf({ attributes, children, leaf });
};
