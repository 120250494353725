import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontSize: '1.4rem',
    textTransform: 'none',
    height: '4.5rem',
    color: 'var(--graphite)',
    '& svg': {
      transition: 'color 400ms ease',
    },
    '&.active': {
      background: 'var(--grey)',
      boxShadow: '-3.5px 0px 0px 0px var(--garnish-primary-green)',
      '& svg': {
        color: 'var(--garnish-primary-green)',
      },
    },
    marginBottom: '1rem',
  },
  label: {
    justifyContent: 'start',
    marginLeft: '2rem',
    color: 'var(--graphite)',
  },
});

function MainNavLink(props) {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      component={NavLink}
      to={props.to}
      startIcon={props.icon}
      target={props.target}
    >
      {props.children}
    </Button>
  );
}

export default MainNavLink;
