import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import { addClassToDefaultIfDefined } from "../util/addClassToDefaultIfDefined";
import Label from "./Label";
import React, { forwardRef, useState } from "react";

const REQUIRED_ERROR = "This field is required";
const REGEX_ERROR = "This value is invalid";

const useStyles = makeStyles({
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: "2.5rem",
  },
  textField: {
    width: "100%",
  },
  input: {
    background: "white",
    height: "4.4rem",
    fontSize: "1.4rem",
  },
  titleInput: {
    "& input": {
      textTransform: 'uppercase !important',
      fontSize: "3.2rem",
      fontFamily: 'Montserrat',
      color: 'var(--garnish-dark-green)',
      letterSpacing: '0.1rem',
      fontWeight: "bold"
    }
  },
  focusedInput: {
    "& svg": {
      color: "var(--garnish-primary-green)",
    },
  },
  icon: {
    width: "2.4rem",
    height: "2.4rem",
    color: "var(--darker-grey)",
  },
  label: {
    textTransform: "uppercase",
    color: "var(--graphite)",
    fontWeight: "bolder",
    marginBottom: "8px",
  },
  error: {
    fontSize: "1rem",
    position: "absolute",
    top: "4.2rem",
  },
});

const EditField = forwardRef((props, ref) => {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [regexError, setRegexError] = useState(false);

  const handleChange = (event) => {
    if (!dirty) {
      setDirty(true);
    }

    if (props.regex && regexError && props.regex.test(event.target.value)) {
      setRegexError(false);
    }

    props.onChange(event);
  };

  const handleBlur = (event) => {
    if (!dirty) {
      setDirty(true);
    }

    if (props.regex) {
      setRegexError(!props.regex.test(event.target.value));
    }
  };

  const isDirtyOrRequiredErrorsForced = () =>
    dirty || props.showRequiredErrorBeforeInteraction;

  const showRequiredFieldError = () =>
    props.required &&
    isDirtyOrRequiredErrorsForced() &&
    props.value.trim().length === 0;

  const getRequiredTextError = () => props.requiredErrorText || REQUIRED_ERROR;
  const getReqexError = () => props.regexErrorMessage || REGEX_ERROR;

  const getError = () => regexError || showRequiredFieldError() || props.error;

  const getHelperText = () => {
    if (showRequiredFieldError()) {
      return getRequiredTextError();
    }

    if (regexError) {
      return getReqexError();
    }

    return props.helperText;
  };

  const inputClass = props.variant === 'title' ? classes.titleInput : classes.input

  return (
    <div
      className={addClassToDefaultIfDefined(
        classes.inputWrapper,
        props.className
      )}
      ref={ref}
    >
      <Label className={classes.label} for={props.id}>
        {props.label}
      </Label>
      <TextField
        id={props.id}
        ref={props.textFieldRef}
        variant="outlined"
        value={props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.textField}
        placeholder={props.placeholder}
        error={getError()}
        helperText={getHelperText()}
        disabled={props.disabled || false}
        autoFocus={props.autofocus}
        type={props.type}
        FormHelperTextProps={{
          className: classes.error,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          classes: {
            root: inputClass,
            focused: classes.focusedInput,
          },
          endAdornment: (
            <InputAdornment position="end">
              <EditIcon className={classes.icon} />
            </InputAdornment>
          ),
          ...props.inputProps,
        }}
      />
    </div>
  );
});

EditField.propTypes = {
  /**
   * This is required to associated the label with its input
   */
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  /**
   * If defined, the input will be given error styling
   */
  error: PropTypes.any,
  /**
   * This is displayed when an error occurs
   */
  helperText: PropTypes.string,
  disabled: PropTypes.any,
  autoFocus: PropTypes.func,
  inputProps: PropTypes.any,
  type: PropTypes.string,
  required: PropTypes.bool,
  requiredErrorText: PropTypes.string,
  /**
   *  If this prop is set to true, and the field value is empty, the required field error will be displayed before the user changes
   *  the input value or the input value loses focus. This is useful when a form is submitted to mark fields that haven't been filled
   *  out yet.
   */
  showRequiredErrorBeforeInteraction: PropTypes.bool,
  /**
   *  A regex for the input value to match. If it doesn't match, an error will be displayed
   */
  regex: PropTypes.any,
  regexErrorMessage: PropTypes.string,
  variant: PropTypes.string
};

export default EditField;
