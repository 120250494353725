import { useContext } from "react";
import { MenuItem } from "@material-ui/core";
import GarnishSelect from "../GarnishSelect";
import LanguageContext from "../../contexts/LanguageContext";
import { DEFAULT_LANGUAGE_CODE } from "../../constants/defaultLanguageCode";

export default function LanguageSelect({
  id,
  value,
  onChange,
  excludeLanguageIds,
  label,
  hideDefaultLanguage,
  maxHeight,
  width
}) {
  const { languageInfo } = useContext(LanguageContext);

  const languagesToExclude = excludeLanguageIds || [];

  const getLanguagesWithoutDefaultIfSpecified = () => {
    const languagesWithoutDefault = languageInfo.languages.filter(
      (language) => language.abbreviation !== DEFAULT_LANGUAGE_CODE
    );

    if (hideDefaultLanguage) {
      return languagesWithoutDefault;
    }

    const defaultLanguage = languageInfo.languages.find(
      (language) => language.abbreviation === DEFAULT_LANGUAGE_CODE
    );

    return [defaultLanguage, ...languagesWithoutDefault];
  };

  const languageMenuItems = languageInfo.loading
    ? []
    : getLanguagesWithoutDefaultIfSpecified()
      .filter((language) => !languagesToExclude.includes(language.id))
      .map((language) => (
        <MenuItem value={language.id} key={language.id}>
          {language.displayName}
        </MenuItem>
      ));

  return (
    <GarnishSelect
      id={id}
      value={value}
      loading={languageInfo.loading}
      label={label}
      onChange={onChange}
      maxHeight={maxHeight}
      width={width}
    >
      {languageMenuItems}
    </GarnishSelect>
  );
}
