import { useCallback } from "react";
import { DEFAULT_EDITOR_CONTENT } from "../constants/defaultEditorContent";

export const useTranslatedContentPayload = (
  translatedTitles,
  translatedContent
) => {
  const getFilteredTranslationPayload = (translationList, filterFnc) =>
    Object.keys(translationList)
      .filter(filterFnc)
      .map((languageId) => ({
        languageId,
        translatedValue: translationList[languageId],
      }));

  const getTitleTranslations = useCallback(
    () =>
      getFilteredTranslationPayload(
        translatedTitles,
        (languageId) => translatedTitles[languageId].trim().length > 0
      ),
    [translatedTitles]
  );

  const getTranslatedRichContentPayload = useCallback(
    () =>
      getFilteredTranslationPayload(
        translatedContent,
        (languageId) =>
          JSON.stringify(translatedContent[languageId]) !==
          JSON.stringify(DEFAULT_EDITOR_CONTENT)
      ),
    [translatedContent]
  );

  return { getTitleTranslations, getTranslatedRichContentPayload };
};
