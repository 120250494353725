import { makeStyles } from "@material-ui/core/styles";
import { useDrag, useDrop } from "react-dnd";
import LevelContentListTitle from "./LevelContentListTitle";
import { DRAG_DROP_TYPES } from "../../constants/dragDropTypes";
import EmptyPreviewImage from "./EmptyPreviewImage";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  subpageWrapper: {
    borderBottom: "2px solid var(--dark-grey)",
    transition: "opacity 400ms ease",
    cursor: "move",
    "&.dragging": {
      opacity: 0.5,
    },
  },
  subpage: {
    paddingLeft: "9rem",
    minHeight: "4.8rem",
    background: "var(--light-grey)",
    position: "relative",
    "&.drag-over": {
      "& $subpageDropBorder": {
        opacity: "1",
        boxShadow: "0px 6px 8px -3px var(--garnish-primary-green)",
        zIndex: "1",
      },
    },
  },
  subpageDropBorder: {
    position: "absolute",
    height: "1rem",
    opacity: 0,
    transition: "opacity 400ms ease",
    width: "100%",
    bottom: "0",
    left: "0",
  },
});

function ContentListItem(props) {
  const history = useHistory();
  const classes = useStyles();

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: DRAG_DROP_TYPES.content,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: {
      id: props.content.id,
      parentId: props.courseId,
      unpublished: !props.published,
      title: props.title,
      content: props.content,
    },
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DRAG_DROP_TYPES.content],
      drop: (item) => {
        props.onContentDraggedAfterOtherContent(props.content.id, item.content);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver() && monitor.canDrop(),
      }),
      canDrop: (item) => {
        return item.id !== props.content.id && item.parentId === props.courseId
      },
    }),
    [props.onContentDraggedAfterOtherContent]
  );

  const getDropZoneClass = () =>
    isOver ? `${classes.subpage} drag-over` : classes.subpage;

  const getDragClass = () =>
    isDragging ? `${classes.subpageWrapper} dragging` : classes.subpageWrapper;

  const handleEditClicked = () => {
    history.push(
      `/levels/${props.levelId}/courses/${props.courseId}/content/${props.content.id}/edit`
    );
  };

  return (
    <div className={getDragClass()} ref={drop}>
      <LevelContentListTitle
        title={props.title}
        className={getDropZoneClass()}
        unpublished={!props.published}
        innerRef={drag}
        onEditClicked={handleEditClicked}
        showEditButton={true}
        supportedLanguageIds={props.supportedLanguageIds}
        onDeleteClicked={() =>
          props.onDeleteClicked(props.content.id, props.title)
        }
      >
        <div className={classes.subpageDropBorder}></div>
      </LevelContentListTitle>
      <EmptyPreviewImage preview={preview} />
    </div>
  );
}

export default ContentListItem;
