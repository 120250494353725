import React from 'react';
import HomeIcon from '../../images/app-home-icon-white.png';
import LeaderboardIcon from '../../images/app-leaderboard-icon-white.png';
import CoursesIcon from '../../images/app-courses-icon-mint.png';
import NotificationsIcon from '../../images/app-notifications-icon-white.png';
import QuickLinksIcon from '../../images/app-quicklinks-icon-white.png';

const DevicePreviewFooter = () => {
  return (
    <footer className="preview-content__footer">
      <nav>
        <img
          className="preview-content__footer-icon"
          src={HomeIcon}
          alt="Home"
          draggable={false}
        />
        <img
          className="preview-content__footer-icon"
          src={LeaderboardIcon}
          alt="Leaderboards"
          draggable={false}
        />
        <img
          className="preview-content__footer-icon"
          src={CoursesIcon}
          alt="Courses"
          draggable={false}
        />
        <img
          className="preview-content__footer-icon"
          src={NotificationsIcon}
          alt="Notifications"
          draggable={false}
        />
        <img
          className="preview-content__footer-icon"
          src={QuickLinksIcon}
          alt="Quick Links"
          draggable={false}
        />
      </nav>
    </footer>
  );
};

export default DevicePreviewFooter;
