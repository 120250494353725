import {
  FormControlLabel,
  Checkbox,
  Radio,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useContext, useState } from "react";
import QuizEditorContext from "../../../contexts/QuizEditorContext";
import EditField from "../../EditField";

const useStyles = makeStyles({
  answerWrapper: {
    padding: "3rem 0",
    display: "flex",
    height: "28rem",
    position: "relative",
    flexDirection: "column",
  },
  correctAnswerLabel: {
    fontSize: "1.4rem",
  },
  deleteButton: {
    width: "4rem",
    height: "4rem",
    alignSelf: "end",
    position: "absolute",
    top: "1.5rem",
    "& svg": {
      width: "2.5rem",
      height: "2.5rem",
    },
  },
});

function QuizAnswer(props) {
  const classes = useStyles();
  const [answerTextDirty, setAnswerTextDirty] = useState(false);

  const { emptyAnswers, displayAllErrors } = useContext(QuizEditorContext);

  const getCorrectnessControl = () =>
    props.isMultipleChoice ? (
      <FormControlLabel
        classes={{
          label: classes.correctAnswerLabel,
        }}
        control={
          <Checkbox
            checked={props.isCorrect}
            onChange={(event) =>
              props.onCorrectnessCheckboxChanged(event, props.id)
            }
            name="checkedA"
          />
        }
        label="Correct answer"
      />
    ) : (
      <FormControlLabel
        value={props.id}
        control={<Radio />}
        classes={{ label: classes.correctAnswerLabel }}
        label="Correct answer"
      />
    );

  const showDeleteButtonIfNotFirstTwoAnswersAndNotTranslationMode = () =>
    !props.translationMode && props.index > 1 ? (
      <IconButton
        className={classes.deleteButton}
        onClick={() => props.onAnswerDeleted(props.id)}
      >
        <DeleteIcon />
      </IconButton>
    ) : null;

  const handleAnswerTextChanged = (event) => {
    setAnswerTextDirty(true);

    props.onAnswerTextChanged(event, props.id);
  };

  const handleFeedbackTextChanged = (event) =>
    props.onFeedbackTextChanged(event, props.id);

  const isAnswerTextInErrorState = () =>
    (displayAllErrors || answerTextDirty) && emptyAnswers.includes(props.id);

  const getAnswerTextHelperText = () =>
    isAnswerTextInErrorState() ? "Please enter an answer" : null;

  const renderCorrectnessControlIfNotTranslationMode = () =>
    props.translationMode ? null : getCorrectnessControl();

  return (
    <div className={classes.answerWrapper}>
      {showDeleteButtonIfNotFirstTwoAnswersAndNotTranslationMode()}
      <EditField
        id={`answer-${props.id}`}
        label="Answer Text"
        value={props.answerText}
        placeholder="Type your answer here"
        onChange={handleAnswerTextChanged}
        error={isAnswerTextInErrorState()}
        helperText={getAnswerTextHelperText()}
      />
      <EditField
        id={`feedback-${props.id}`}
        label="Feedback Text"
        value={props.feedbackText}
        placeholder="Explain why this answer is correct or incorrect"
        onChange={handleFeedbackTextChanged}
      />
      {renderCorrectnessControlIfNotTranslationMode()}
    </div>
  );
}

export default QuizAnswer;
