import { makeStyles } from "@material-ui/styles";
import Page from "../components/Page";
import AdminLandingPageButton from "../components/admin/AdminLandingPageButton";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    width: "750px",
    justifyContent: "space-between",
  },
  button: {
    background: "white",
    padding: "2rem 5rem",
    border: "1px solid var(--dark-grey)",
    fontWeight: "bold",
    letterSpacing: "1px",
  },
});

function AdminLandingPage() {
  const classes = useStyles();

  return (
    <Page title="Admin Options">
      <div className={classes.buttonContainer}>
        <AdminLandingPageButton to="/admin/users" label="Edit admin users" />
        <AdminLandingPageButton to="/admin/access-codes" label="Edit access codes" />
        <AdminLandingPageButton to="/admin/export-reports" label="Export reports" />
      </div>
    </Page>
  );
}

export default AdminLandingPage;
