import { useState, useEffect, Fragment } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { useDrop } from "react-dnd";
import { CircularProgress } from "@material-ui/core";
import useAuthenticatedRestCall from "../../hooks/useAuthenticatedRestCall";
import { addOrMoveItemAfterOtherItem } from "../../util/contentArrayUtils";
import Course from "./Course";
import { DRAG_DROP_TYPES } from "../../constants/dragDropTypes";
import { addClassToDefaultIfTrue } from "../../util/addClassToDefaultIfTrue";

const useStyles = makeStyles({
  emptyText: {
    padding: "2rem",
    borderBottom: "2px solid var(--dark-grey)",
  },
  loadingSpinner: {
    margin: "2rem auto",
  },
  firstChildDropArea: {
    height: "1.3rem",
    width: "100%",
    position: "absolute",
    opacity: "0",
    boxShadow: "0px -2px 3px 0px var(--garnish-primary-green)",
    transition: "opacity 200ms ease",
    zIndex: "2",
    "&.drag-over": {
      opacity: 1,
    },
  },
});

function LevelCourseList(props) {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const [levelCourses, setLevelCourses] = useState(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadContent = async () => {
      try {
        const courseRequests = props.levelCourses.map((courseId) =>
          makeAuthenticatedRequest(
            `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.levelId}/courses/${courseId}`
          )
        );

        const courseResponses = await Promise.all(courseRequests);

        const courses = courseResponses.map((response) => response.data);

        setLevelCourses(courses);
      } catch (error) {
        enqueueSnackbar(`An error occurred fetching this level's courses`, {
          variant: "error",
        });
      }
    };

    if (props.showContent) {
      loadContent();
    }
  }, [
    props.showContent,
    makeAuthenticatedRequest,
    props.levelId,
    enqueueSnackbar,
    props.levelCourses,
  ]);

  const updateLevelCourses = (updatedLevelCourses) =>
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${props.levelId}`,
      true,
      {
        data: {
          courses: updatedLevelCourses.map((c) => c.id),
        },
        method: "PUT",
      }
    );

  const handleFirstChildDrop = (item) => {
    const coursesWithoutItem = levelCourses.filter(
      (course) => course.id !== item.id
    );

    const updatedCourses = [item.course, ...coursesWithoutItem];

    updateLevelCourses(updatedCourses);
    setLevelCourses(updatedCourses);
  };

  const [{ isOver: isOverFirstChildDropArea }, dropAsFirstItem] = useDrop(
    () => ({
      accept: [DRAG_DROP_TYPES.course],
      drop: handleFirstChildDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver() && monitor.canDrop(),
      }),
      canDrop: (item) => item.id !== levelCourses[0].id,
    }),
    [props.onPageConvertedToFirstSubPage, levelCourses]
  );

  const handleDeleteCourse = (courseId) => {
    const updatedCourses = levelCourses.filter(
      (course) => course.id !== courseId
    );
    setLevelCourses(updatedCourses);
  };

  const handleCourseDraggedAfterOtherCourse = (courseId, draggedCourse) => {
    const updatedCourseList = addOrMoveItemAfterOtherItem(
      levelCourses,
      draggedCourse,
      courseId
    );

    setLevelCourses(updatedCourseList);
    updateLevelCourses(updatedCourseList);
  };

  const renderCourseContentOrEmptyMessage = () => {
    if (levelCourses.length > 0 || props.newCourses.length > 0) {
      return [...levelCourses, ...props.newCourses].map((course) => (
        <Course
          key={course.id}
          id={course.id}
          course={course}
          onCourseDeleted={handleDeleteCourse}
          onCourseDraggedAfterOtherCourse={handleCourseDraggedAfterOtherCourse}
        />
      ));
    } else {
      return (
        <div className={classes.emptyText}>This level has no courses.</div>
      );
    }
  };

  return levelCourses ? (
    <Fragment>
      <div
        className={addClassToDefaultIfTrue(
          classes.firstChildDropArea,
          isOverFirstChildDropArea,
          "drag-over"
        )}
        ref={dropAsFirstItem}
      ></div>
      {renderCourseContentOrEmptyMessage()}
    </Fragment>
  ) : (
    <CircularProgress className={classes.loadingSpinner} />
  );
}

export default LevelCourseList;
