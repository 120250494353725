import { useState, createRef } from 'react';
import debounce from 'debounce';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Page from '../components/Page';
import UsersGrid from '../components/UsersGrid';

const SEARCH_DEBOUNCE_DELAY_MS = 300;

const useStyles = makeStyles({
  searchInput: {
    background: 'white',
    height: '4.4rem',
    fontSize: '1.4rem',
    width: '62rem',
    paddingLeft: '1rem',
  },
  searchIcon: {
    width: '2.4rem',
    height: '2.4rem',
    color: 'var(--darker-grey)',
  },
  searchLabel: {
    fontSize: '1.4rem',
    color: 'var(--darker-grey)',
    transform: 'none',
    position: 'absolute',
    top: '1.5rem',
    left: '4rem',
    textTransform: 'none',
    fontWeight: 'normal',
  },
  focusedSearchLabel: {
    display: 'none',
  },
  addButton: {
    color: 'white',
    fontSize: '1.4rem',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
  },
  dirtyField: {
    '& label': {
      display: 'none',
    },
  },
});

function Users() {
  const classes = useStyles();
  const searchRef = createRef();
  const [searchQuery, setSearchQuery] = useState('');
  const setSearchQueryDebounced = debounce(() => {
    const value = searchRef.current ? searchRef.current.value : '';
    setSearchQuery(value);
  }, SEARCH_DEBOUNCE_DELAY_MS);

  const handleSearchValueChanged = (event) => {
    setSearchQueryDebounced(event.target.value.trim());
  };

  const getSearchInputClass = () =>
    searchRef.current && searchRef.current.value.trim().length > 0
      ? classes.dirtyField
      : '';

  return (
    <Page>
      <div className={classes.toolbar}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <TextField
            id="search-users"
            className={getSearchInputClass()}
            label="Search users by name, id"
            variant="outlined"
            inputRef={searchRef}
            onChange={handleSearchValueChanged}
            InputLabelProps={{
              shrink: false,
              classes: {
                root: classes.searchLabel,
                focused: classes.focusedSearchLabel,
              },
            }}
            InputProps={{
              className: classes.searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </div>
      <UsersGrid search={searchQuery} />
    </Page>
  );
}

export default Users;
