import {
  Switch,
  FormControlLabel,
  RadioGroup,
  makeStyles,
  IconButton,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useCallback, useContext, useEffect, useState } from "react";
import QuizEditorContext from "../../../contexts/QuizEditorContext";
import Card from "../../Card";
import EditField from "../../EditField";
import { getDefaultAnswer } from "./getDefaultQuestion";
import { QUESTION_TYPES } from "./questionTypes";
import QuizAnswer from "./QuizAnswer";
import { updateObjectAtIndex } from "../../../util/arrayUtils";

const useStyles = makeStyles({
  question: {
    marginBottom: "3rem",
  },
  questionConfigWrapper: {
    height: "10rem",
    display: "flex",
    alignItems: "center",
  },
  questionTextInput: {
    width: "46rem",
  },
  deleteButton: {
    width: "4rem",
    height: "4rem",
    "& svg": {
      width: "3rem",
      height: "3rem",
    },
    marginLeft: "2rem",
  },
  multipleChoiceSwitch: {
    marginLeft: "auto",
  },
  multipleChoiceSwitchLabel: {
    fontSize: "1.4rem",
  },
});

function QuizQuestion({
  id,
  index,
  question,
  translationMode,
  languageToEditId,
}) {
  const {
    questions,
    setQuestions,
    emptyQuestions,
    setEmptyQuestions,
    emptyAnswers,
    setEmptyAnswers,
    displayAllErrors,
  } = useContext(QuizEditorContext);

  const getInitialQuestionText = useCallback(
    () =>
      translationMode
        ? question.questionTextTranslations.find(
            (translation) => translation.languageId === languageToEditId
          )?.translatedValue
        : question.questionText,
    [translationMode, question, languageToEditId]
  );

  useEffect(() => {
    setQuestionText(getInitialQuestionText());
  }, [languageToEditId, question, translationMode, getInitialQuestionText]);

  const initialAnswerIdSelected = translationMode
    ? false
    : question.answers.find((answer) => answer.correct).id;

  // TODO: is this needed?
  const [questionText, setQuestionText] = useState(getInitialQuestionText());
  const [questionTextDirty, setQuestionTextDirty] = useState(null);
  const [isMultipleChoice, setIsMultipleChoice] = useState(
    question.type === QUESTION_TYPES.multipleCorrectAnswer
  );
  const [singleCorrectAnswer, setSingleCorrectAnswer] = useState(
    initialAnswerIdSelected
  );
  const classes = useStyles();

  const getQuestionUpdateForTranslation = (question, value) => {
    const translationToUpdateIndex =
      question.questionTextTranslations.findIndex(
        (translation) => translation.languageId === languageToEditId
      );

    const updatedQuestionTextTranslations =
      translationToUpdateIndex !== -1
        ? updateObjectAtIndex(
            question.questionTextTranslations,
            translationToUpdateIndex,
            {
              translatedValue: value,
            }
          )
        : [
            {
              translatedValue: value,
              languageId: languageToEditId,
            },
          ];

    return {
      ...question,
      questionTextTranslations: updatedQuestionTextTranslations,
    };
  };

  const updateQuestion = (update) => {
    const questionToUpdateIndex = questions.findIndex(
      (question) => question.id === id
    );

    const updatedQuestionArray = updateObjectAtIndex(
      questions,
      questionToUpdateIndex,
      update
    );

    setQuestions(updatedQuestionArray);
  };

  const handleQuestionTextChanged = (event) => {
    setQuestionText(event.target.value);

    const questionUpdate = translationMode
      ? getQuestionUpdateForTranslation(question, event.target.value)
      : {
          questionText: event.target.value,
        };

    updateQuestion(questionUpdate);

    if (!translationMode) {
      updateEmptyListIfEmpty(
        id,
        event.target.value.trim(),
        emptyQuestions,
        setEmptyQuestions
      );

      setQuestionTextDirty(true);
    }
  };

  const getAnswersWithOnlyThisAnswerCorrect = (answerId) => {
    return question.answers.map((answer) =>
      answer.id === answerId
        ? { ...answer, correct: true }
        : { ...answer, correct: false }
    );
  };

  const handleMultipleChoiceChanged = (event) => {
    setIsMultipleChoice(event.target.checked);

    const questionUpdate = {
      type: event.target.checked
        ? QUESTION_TYPES.multipleCorrectAnswer
        : QUESTION_TYPES.singleCorrectAnswer,
    };

    if (!event.target.checked) {
      setSingleCorrectAnswer(initialAnswerIdSelected);
      questionUpdate.answers = getAnswersWithOnlyThisAnswerCorrect(
        initialAnswerIdSelected
      );
    }

    updateQuestion(questionUpdate);
  };

  const getAnswerCorrectness = (answer) =>
    isMultipleChoice ? answer.correct : answer.id === singleCorrectAnswer;

  const getAnswerIndex = (question, answerId) =>
    question.answers.findIndex((answer) => answer.id === answerId);

  const handleAnswerDeleted = (answerToDeleteId) => {
    const currentQuestion = question;
    const answerIndex = getAnswerIndex(currentQuestion, answerToDeleteId);

    setEmptyAnswers(
      emptyAnswers.filter((answerId) => answerId !== answerToDeleteId)
    );

    updateQuestion({
      answers: [
        ...currentQuestion.answers.slice(0, answerIndex),
        ...currentQuestion.answers.slice(answerIndex + 1),
      ],
    });
  };

  const updateEmptyListIfEmpty = (id, value, emptyList, emptyListSetter) => {
    if (emptyList.includes(id) && value.trim().length > 0) {
      const updatedEmptyList = emptyList.filter(
        (questionId) => questionId !== id
      );

      emptyListSetter(updatedEmptyList);
    } else if (!emptyList.includes(id) && value.trim().length === 0) {
      emptyListSetter([...emptyList, id]);
    }
  };

  const updateAnswerTranslations = (
    updatedTranslationValue,
    translationList
  ) => {
    const translationToUpdateIndex = translationList.findIndex(
      (translation) => translation.languageId === languageToEditId
    );

    // TODO: this is also done for questions
    const updatedTranslations =
      translationToUpdateIndex !== -1
        ? updateObjectAtIndex(translationList, translationToUpdateIndex, {
            translatedValue: updatedTranslationValue,
          })
        : [
            {
              translatedValue: updatedTranslationValue,
              languageId: languageToEditId,
            },
          ];

    return updatedTranslations;
  };

  const getTranslationOrTextUpdate = (
    answer,
    value,
    untranslatedKeyname,
    translationKeyName
  ) =>
    translationMode
      ? {
          [translationKeyName]: updateAnswerTranslations(
            value,
            answer[translationKeyName]
          ),
        }
      : {
          [untranslatedKeyname]: value,
        };

  // TODO -- update for translations, refactor
  const handleAnswerTextChanged = (event, answerId) => {
    const currentQuestion = question;
    const answerIndex = getAnswerIndex(currentQuestion, answerId);
    const answer = question.answers[answerIndex];
    const answerUpdate = getTranslationOrTextUpdate(
      answer,
      event.target.value,
      "answerText",
      "answerTextTranslations"
    );

    const updatedAnswers = updateObjectAtIndex(
      currentQuestion.answers,
      answerIndex,
      answerUpdate
    );

    updateQuestion({
      answers: updatedAnswers,
    });

    if (!translationMode) {
      updateEmptyListIfEmpty(
        answerId,
        event.target.value.trim(),
        emptyAnswers,
        setEmptyAnswers
      );
    }
  };

  // TODO -- update for translations, refactor
  const handleFeedbackTextChanged = (event, answerId) => {
    const currentQuestion = question;
    const answerIndex = getAnswerIndex(currentQuestion, answerId);
    const answer = question.answers[answerIndex];
    const answerUpdate = getTranslationOrTextUpdate(
      answer,
      event.target.value,
      "feedbackText",
      "feedbackTextTranslations"
    );
    const updatedAnswers = updateObjectAtIndex(
      currentQuestion.answers,
      answerIndex,
      answerUpdate
    );

    updateQuestion({
      answers: updatedAnswers,
    });
  };

  const handleCorrectnessCheckBoxChanged = (event, answerId) => {
    const currentQuestion = question;
    const existingAnswers = [...currentQuestion.answers];
    const answerToUpdateIndex = getAnswerIndex(currentQuestion, answerId);
    const answerToUpdate = existingAnswers[answerToUpdateIndex];

    const updatedAnswer = { ...answerToUpdate, correct: event.target.checked };

    const updatedAnswers = [
      ...existingAnswers.slice(0, answerToUpdateIndex),
      updatedAnswer,
      ...existingAnswers.slice(answerToUpdateIndex + 1),
    ];

    updateQuestion({
      answers: updatedAnswers,
    });
  };

  const getAnswerTranslatedValueOrDefault = (answer, translationListName) =>
    answer[translationListName].find(
      (translation) => translation.languageId === languageToEditId
    )?.translatedValue || "";

  const getTranslatedAnswerTextIfNeeded = (
    answer,
    translationListName,
    propertyName
  ) =>
    translationMode
      ? getAnswerTranslatedValueOrDefault(answer, translationListName)
      : answer[propertyName];

  const getAnswers = () =>
    question.answers.map((answer, index) => {
      const answerText = getTranslatedAnswerTextIfNeeded(
        answer,
        "answerTextTranslations",
        "answerText"
      );
      const feedbackText = getTranslatedAnswerTextIfNeeded(
        answer,
        "feedbackTextTranslations",
        "feedbackText"
      );

      return (
        <QuizAnswer
          key={answer.id}
          id={answer.id}
          index={index}
          answerText={answerText}
          feedbackText={feedbackText}
          isCorrect={getAnswerCorrectness(answer)}
          isMultipleChoice={isMultipleChoice}
          translationMode={translationMode}
          onAnswerDeleted={handleAnswerDeleted}
          onAnswerTextChanged={handleAnswerTextChanged}
          onFeedbackTextChanged={handleFeedbackTextChanged}
          onCorrectnessCheckboxChanged={handleCorrectnessCheckBoxChanged}
        />
      );
    });

  const handleSingleCorrectAnswerChosen = (event) => {
    setSingleCorrectAnswer(event.target.value);

    updateQuestion({
      answers: getAnswersWithOnlyThisAnswerCorrect(event.target.value),
    });
  };

  const renderAnswers = () =>
    isMultipleChoice ? (
      getAnswers()
    ) : (
      <RadioGroup
        aria-label="correct answer"
        name="correct-answer"
        value={singleCorrectAnswer}
        onChange={handleSingleCorrectAnswerChosen}
      >
        {getAnswers()}
      </RadioGroup>
    );

  const handleQuestionDeleted = () => {
    const questionToRemoveIndex = questions.findIndex(
      (question) => question.id === id
    );
    const updatedQuestions = [
      ...questions.slice(0, questionToRemoveIndex),
      ...questions.slice(questionToRemoveIndex + 1),
    ];

    setEmptyQuestions(emptyQuestions.filter((questionId) => questionId !== id));

    const answerIds = question.answers.map((answer) => answer.id);

    setEmptyAnswers(
      emptyAnswers.filter((answerId) => !answerIds.includes(answerId))
    );

    setQuestions(updatedQuestions);
  };

  const showDeleteButtonIfNotFirstQuestionAndNotTranslationMode = () =>
    !translationMode && index > 0 ? (
      <IconButton
        title="Delete question"
        aria-label="Delete question"
        onClick={handleQuestionDeleted}
        className={classes.deleteButton}
      >
        <DeleteIcon />
      </IconButton>
    ) : null;

  const handleAddAnswerClicked = () => {
    const currentQuestion = questions.find((question) => question.id === id);

    const newAnswer = getDefaultAnswer();

    updateQuestion({
      answers: [...currentQuestion.answers, newAnswer],
    });

    setEmptyAnswers([...emptyAnswers, newAnswer.id]);
  };

  const isQuestionTextInErrorState = () =>
    (displayAllErrors || questionTextDirty) && emptyQuestions.includes(id);

  const getQuestionTextHelperText = () =>
    isQuestionTextInErrorState() ? "Please enter a question" : null;

  const renderAddAnswerButtonIfNotTranslationMode = () =>
    translationMode ? null : (
      <Button
        color="primary"
        variant="contained"
        onClick={handleAddAnswerClicked}
      >
        Add answer
      </Button>
    );

  const renderMultipleChoiceSwitchIfNotTranslationMode = () =>
    translationMode ? null : (
      <FormControlLabel
        labelPlacement="start"
        classes={{
          root: classes.multipleChoiceSwitch,
          label: classes.multipleChoiceSwitchLabel,
        }}
        control={
          <Switch
            checked={isMultipleChoice}
            onChange={handleMultipleChoiceChanged}
            name="published"
            color="primary"
          />
        }
        label="Multiple choice"
      />
    );
  return (
    <Card className={classes.question}>
      <div className={classes.questionConfigWrapper}>
        <EditField
          id={id}
          className={classes.questionTextInput}
          label="Question text"
          value={questionText}
          onChange={handleQuestionTextChanged}
          placeholder="Type your question here"
          error={isQuestionTextInErrorState()}
          helperText={getQuestionTextHelperText()}
        ></EditField>
        {renderMultipleChoiceSwitchIfNotTranslationMode()}
        {showDeleteButtonIfNotFirstQuestionAndNotTranslationMode()}
      </div>
      {renderAnswers()}
      {renderAddAnswerButtonIfNotTranslationMode()}
    </Card>
  );
}

export default QuizQuestion;
