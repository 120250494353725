import { useCallback, useContext, useEffect, useState } from "react";
import { DEFAULT_LANGUAGE_CODE } from "../constants/defaultLanguageCode";
import LanguageContext from "../contexts/LanguageContext";

export function useDefaultLanguage() {
  const { languageInfo } = useContext(LanguageContext);

  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [defaultLanguageLoaded, setDefaultLanguageLoaded] = useState(false);

  const findDefaultLanguage = useCallback(() => {
    return languageInfo.languages.find(
      (language) => language.abbreviation === DEFAULT_LANGUAGE_CODE
    );
  }, [languageInfo.languages]);

  useEffect(() => {
    if (!languageInfo.loading) {
      setDefaultLanguage(findDefaultLanguage());
      setDefaultLanguageLoaded(true);
    }
  }, [languageInfo.loading, languageInfo.languages, findDefaultLanguage]);

  return { defaultLanguage, defaultLanguageLoaded };
}
