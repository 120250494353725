import { useCallback } from "react";

/**
 * This animation is intended to be used for toggling translation mode for rich content and quizzes.
 *
 * @param {*} ref
 * @param {*} playAnimation when false, the animation won't play
 * @param {*} forwards When true, the animation slides right to left. When false, it plays the opposite direction
 * @returns
 */
export const useSlideAnimation = (ref, playAnimation, forwards) => {
  const animateSlideTransition = useCallback(() => {
    const translationDistance = ref.current.clientWidth / 2;

    const keyframes = forwards
      ? [
          { transform: `translateX(${translationDistance}px)` },
          { transform: "translateX(0)" },
        ]
      : [
          { transform: "translateX(0)" },
          { transform: `translateX(${translationDistance}px)` },
        ];

    if (playAnimation) {
      ref.current.animate(keyframes, {
        duration: 200,
        easing: "ease",
        fill: forwards ? "forwards" : "none",
      });
    }
  }, [playAnimation, forwards, ref]);

  return animateSlideTransition;
};
