import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";

export const useUploadImage = () => {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  return function (url, imageFile) {
    const bodyFormData = new FormData();
    bodyFormData.append("upload", imageFile);

    return makeAuthenticatedRequest(
      url,
      true,
      {
        data: bodyFormData,
        method: "POST",
      },
      { "Content-Type": "multipart/form-data" }
    );
  };
};
