import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import useAuthenticatedRequest from "../hooks/useAuthenticatedRestCall";
import AddEditCourseContent from "../components/AddEditCourseContent";
import { CONTENT_TYPES } from "../constants/contentTypes";
import QuizEditorContext from "../contexts/QuizEditorContext";
import { getDefaultQuestion } from "../components/courses/quizEditor/getDefaultQuestion";
import { useTranslatedContentPayload } from "../hooks/useTranslatedContentPayload";
import { useInitializeTranslatedContent } from "../hooks/useInitializeTranslatedContent";
import { removeEmptyTranslations } from "../util/translationUtils";

function AddCourseContent() {
  let { courseId, levelId } = useParams();
  const makeAuthenticatedRequest = useAuthenticatedRequest();
  const [title, setTitle] = useState("My New Content");
  const [published, setPublished] = useState(false);
  const [quillContents, setQuillContents] = useState(null);
  const [isQuiz, setIsQuiz] = useState(false);
  const [questions, setQuestions] = useState([getDefaultQuestion()]);
  const [displayAllErrors, setDisplayAllErrors] = useState(false);
  const [translatedContent, setTranslatedContent] = useState({});
  const [translatedTitles, setTranslatedTitles] = useState({});
  const [questionTranslations, setQuestionTranslations] = useState([]);
  const { getTitleTranslations, getTranslatedRichContentPayload } =
    useTranslatedContentPayload(translatedTitles, translatedContent);

  useInitializeTranslatedContent(setTranslatedContent, setTranslatedTitles);

  const [emptyQuestions, setEmptyQuestions] = useState(
    questions.map((question) => question.id)
  );

  const [emptyAnswers, setEmptyAnswers] = useState(
    questions.flatMap((question) => question.answers.map((answer) => answer.id))
  );

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const getAnswersWithoutIds = (answers) =>
    answers.map((answer) => ({
      answerText: answer.answerText,
      correct: answer.correct,
      feedbackText: answer.feedbackText,
      answerTextTranslations: removeEmptyTranslations(
        answer.answerTextTranslations
      ),
      feedbackTextTranslations: removeEmptyTranslations(
        answer.feedbackTextTranslations
      ),
    }));

  const getQuestionsWithoutIds = () =>
    questions.map((question) => ({
      questionText: question.questionText,
      type: question.type,
      answers: getAnswersWithoutIds(question.answers),
      questionTextTranslations: removeEmptyTranslations(
        question.questionTextTranslations
      ),
    }));

  const getPayload = () => {
    const translationInfo = {
      titleTranslations: getTitleTranslations(),
      richContentTranslations: getTranslatedRichContentPayload(),
    };

    if (!isQuiz) {
      return {
        title,
        published,
        type: CONTENT_TYPES.page,
        ...translationInfo,
      };
    }

    return {
      title,
      published,
      type: CONTENT_TYPES.quiz,
      questions: getQuestionsWithoutIds(),
      ...translationInfo,
    };
  };

  const postNewContent = () =>
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels/${levelId}/courses/${courseId}/content`,
      true,
      {
        data: getPayload(),
        method: "POST",
      }
    );

  const handleRichContentEdited = useCallback((updatedContents) => {
    setQuillContents(updatedContents);
  }, []);

  const handlePublishedChanged = (event) => setPublished(event.target.checked);
  const handleQuizModeChanged = (event) => setIsQuiz(event.target.checked);

  const newQuizContextValue = {
    questions,
    setQuestions,
    emptyQuestions,
    setEmptyQuestions,
    emptyAnswers,
    setEmptyAnswers,
    displayAllErrors,
    setDisplayAllErrors,
    questionTranslations,
    setQuestionTranslations,
  };

  return (
    <QuizEditorContext.Provider value={newQuizContextValue}>
      <AddEditCourseContent
        title={title}
        onTitleChange={handleTitleChange}
        onRichContentEdited={handleRichContentEdited}
        published={published}
        onPublishedChanged={handlePublishedChanged}
        showQuizModeToggle={true}
        isQuiz={isQuiz}
        onQuizModeChanged={handleQuizModeChanged}
        saveRequest={postNewContent}
        quillContents={quillContents}
        translatedContent={translatedContent}
        setTranslatedContent={setTranslatedContent}
        saveSuccessMessage={`${title} updated successfully!`}
        translatedTitles={translatedTitles}
        setTranslatedTitles={setTranslatedTitles}
      />
    </QuizEditorContext.Provider>
  );
}

export default AddCourseContent;
