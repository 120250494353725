import { useContext } from "react";
import { useSnackbar } from "notistack";
import AddEditLevelDialog from "./AddEditLevelDialog";
import ClientContext from "../../contexts/ClientContext";
import useAuthenticatedRestCall from "../../hooks/useAuthenticatedRestCall";
import { useUploadLevelCompletionImage } from "../../hooks/useUploadLevelCompletionImage";

export default function AddLevelDialog(props) {
  const { clientInfo } = useContext(ClientContext);
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { enqueueSnackbar } = useSnackbar();
  const uploadLevelCompletionImage = useUploadLevelCompletionImage();

  const handleConfirm = (title, imageFile, titleTranslations) => {
    makeAuthenticatedRequest(
      `${process.env.REACT_APP_COURSE_SERVICE_URL}/levels`,
      false,
      {
        data: {
          clientId: clientInfo.clientId,
          title,
          titleTranslations,
        },
        method: "POST",
      }
    ).then((response) => {
      enqueueSnackbar(`${title} created successfully!`, {
        variant: "success",
      });

      if (imageFile) {
        uploadLevelCompletionImage(response.data.id, imageFile);
      }

      props.onNewLevelCreated(response.data);
    });

    props.onClose();
  };

  return (
    <AddEditLevelDialog
      title="Create new level"
      open={props.open}
      onClose={props.onClose}
      onConfirm={handleConfirm}
      confirmText="Create level"
    />
  );
}
