import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as EditIcon } from "../../images/pencil.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import { addClassToDefaultIfDefined } from "../../util/addClassToDefaultIfDefined";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";

const useStyles = makeStyles({
  itemTitle: {
    fontWeight: "500",
    marginLeft: "1.5rem",
  },
  checkmark: {
    width: "3rem",
    height: "3rem",
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "&.unpublished": {
      "& svg": {
        filter: "saturate(0)",
        opacity: ".6",
      },
      "& $itemTitle": {
        opacity: ".3",
      },
      "& $toolbarButton svg": {
        filter: "saturate(1)",
      },
    },
    "&:hover $toolbarButton": {
      transform: "translate(0)",
      opacity: "1",
      "& svg": {
        opacity: "1",
      },
    },
  },
  editButton: {
    marginLeft: "7rem",
  },
  toolbarButton: {
    "& svg": {
      width: "2.5rem",
      height: "2.5rem",
    },
    opacity: "0",
    transform: "translateY(2rem)",
    transition: "transform 200ms ease, opacity 200ms ease",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginRight: "1rem",
  },
  languageIndicatorsWrapper: {
    marginLeft: "auto",
    display: "flex",
  },
  languageIndicator: {
    borderRadius: "50%",
    background: "var(--darker-grey)",
    width: "3.5rem",
    height: "3.5rem",
    color: "white",
    fontWeight: "bold",
    marginLeft: "1.7rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const languageIndicatorColorMap = new Map([
  ["FR", "#0b665c"],
  ["PT", "var(--garnish-light-green)"],
]);

function LevelContentListTitle(props) {
  const classes = useStyles();

  const { languageInfo } = useContext(LanguageContext);

  const getClassName = () => {
    const baseClassName = addClassToDefaultIfDefined(
      classes.container,
      props.className
    );

    return props.unpublished ? `${baseClassName} unpublished` : baseClassName;
  };

  const getEditButton = () =>
    props.showEditButton ? (
      <IconButton
        className={classes.toolbarButton}
        aria-label="delete"
        onClick={props.onDeleteClicked}
        color="primary"
      >
        <DeleteIcon />
      </IconButton>
    ) : null;

  const mapSupportedLanguageIdsToLanguage = (supportedLanguageId) =>
    languageInfo.languages.find(
      (language) => language.id === supportedLanguageId
    );

  const mapLanguageToLanguageIndicator = (language) => (
    <div
      key={language.id}
      className={classes.languageIndicator}
      style={{
        background: languageIndicatorColorMap.get(language.abbreviation),
      }}
    >
      {language.abbreviation}
    </div>
  );

  const getLanguageIndicators = () => {
    if (languageInfo.loading) {
      return null;
    }

    return props.supportedLanguageIds
      ?.map(mapSupportedLanguageIdsToLanguage)
      .map(mapLanguageToLanguageIndicator);
  };

  return (
    <div className={getClassName()} ref={props.innerRef}>
      <div className={classes.titleWrapper}>
        <CheckIcon color="primary" className={classes.checkmark} />
        <span className={classes.itemTitle}>{props.title}</span>
        <IconButton
          className={`${classes.editButton} ${classes.toolbarButton}`}
          aria-label="edit"
          onClick={props.onEditClicked}
          color="primary"
        >
          <EditIcon />
        </IconButton>
        {getEditButton()}
        {props.children}
        <div className={classes.languageIndicatorsWrapper}>
          {getLanguageIndicators()}
        </div>
      </div>
    </div>
  );
}

LevelContentListTitle.propTypes = {
  title: PropTypes.string,
};

export default LevelContentListTitle;
