import React from 'react';
import SettingsIcon from '../../images/app-settings-icon-grey.png';
import HelpIcon from '../../images/app-question-icon-white.png';

const DevicePreviewHeader = ({ title }) => {
  return (
    <header className="preview-content__header">
      <nav>
        <img
          className="preview-content__header-icon"
          src={SettingsIcon}
          alt="Settings"
          draggable={false}
        />
        <h1>Garnish Preview</h1>
        <img
          className="preview-content__header-icon"
          src={HelpIcon}
          alt="Help"
          draggable={false}
        />
      </nav>
      <h2>{title}</h2>
    </header>
  );
};

export default DevicePreviewHeader;
