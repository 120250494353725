import { useEffect, useRef, useState, useCallback } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import isUrl from 'is-url';

import {
  makeStyles,
  Button,
  Paper,
  InputBase,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'var(--light-grey)',
    display: 'none',
    position: 'absolute',
    marginTop: '4px',
    minWidth: '300px',
    zIndex: 1,
    padding: '2px 4px',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function LinkEditor({ editorOffsets, selectionForLink }) {
  const linkEditorRef = useRef(null);
  const editor = useSlate();
  const classes = useStyles();

  const [node, path] = Editor.above(editor, {
    at: selectionForLink,
    match: (n) => n.type === 'link',
  });

  const [linkURL, setLinkURL] = useState(node.url);

  // Show the placeholder value if the link has the default hash
  useEffect(() => {
    if (node.url !== '#') {
      setLinkURL(node.url);
    } else {
      setLinkURL('');
    }
  }, [node]);

  const onLinkURLChange = useCallback(
    (event) => setLinkURL(event.target.value),
    [setLinkURL]
  );

  const onApply = useCallback(
    (event) => {
      event.preventDefault();
      Transforms.setNodes(editor, { url: linkURL }, { at: path });
    },
    [editor, linkURL, path]
  );

  useEffect(() => {
    const linkEditorEl = linkEditorRef.current;
    if (linkEditorEl == null) {
      return;
    }

    // Show the editor underneath the selected link node
    const linkDOMNode = ReactEditor.toDOMNode(editor, node);
    const {
      x: nodeX,
      height: nodeHeight,
      y: nodeY,
    } = linkDOMNode.getBoundingClientRect();

    linkEditorEl.style.display = 'flex';
    linkEditorEl.style.top = `${nodeY + nodeHeight - editorOffsets.y}px`;
    linkEditorEl.style.left = `${nodeX - editorOffsets.x - 10}px`;
  }, [editor, editorOffsets.x, editorOffsets.y, node]);

  if (editorOffsets == null) {
    return null;
  }

  return (
    <Paper
      ref={linkEditorRef}
      component="form"
      className={classes.root}
      onSubmit={onApply}
    >
      <InputBase
        className={classes.input}
        color="primary"
        placeholder="Enter link URL"
        value={linkURL}
        onChange={onLinkURLChange}
        inputProps={{ 'aria-label': 'Enter link URL' }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <Button
        disabled={!isUrl(linkURL)}
        onClick={onApply}
        size="small"
        color="secondary"
        disableElevation
      >
        Apply
      </Button>
    </Paper>
  );
}
