/**
 * This util function provides a way to update the properties of a specific object within an array
 * of objects without mutating the object or array in question (and without needing to do a deep copy)
 * 
 * @example
 * const example = [{name: 'lysol', rating: 8}, {name: 'houdini', rating: 8}]
 * const update = updateObjectAtIndex(example, 0, {rating: 10})
 * console.log(update) // [{name: 'lysol', rating: 10}, {name: 'houdini', rating: 8}]
 * 
 * @param {*} array 
 * @param {*} itemIndex 
 * @param {*} objectToMerge 
 * @returns 
 */
export const updateObjectAtIndex = (array, itemIndex, objectToMerge) => [
  ...array.slice(0, itemIndex),
  {
      ...array[itemIndex],
      ...objectToMerge
  },
  ...array.slice(itemIndex + 1),
];
