import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addClassToDefaultIfTrue } from "../util/addClassToDefaultIfTrue";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  dialog: {
    padding: "2rem 6rem 3rem 6rem",
    "& label": {
      fontSize: "1.4rem",
      fontWeight: "normal",
      width: "100%",
      textAlign: "center",
    },
    "&.align-labels-left label": {
      textAlign: "left",
    },
  },
  dialogActions: {
    marginTop: "5rem",
    justifyContent: "center",
  },
  dialogTitle: {
    fontSize: "2rem",
  },
  dialogHeader: {
    textAlign: "center",
  },
  dialogContent: {
    fontSize: "1.4rem",
  },
});

export function GarnishDialog(props) {
  const classes = useStyles();

  const getCancelButton = () =>
    props.showCancelButton ? (
      <Button
        variant="contained"
        onClick={props.onCancel}
        color="primary"
        disabled={props.confirming || false}
      >
        Cancel
      </Button>
    ) : null;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: addClassToDefaultIfTrue(
          classes.dialog,
          props.alignLabelsLeft,
          "align-labels-left"
        ),
      }}
      scroll="body"
      fullWidth
      disableBackdropClick={props.confirming || false}
      disableEscapeKeyDown={props.confirming || false}
    >
      <DialogTitle
        id="form-dialog-title"
        className={classes.dialogTitle}
        disableTypography
      >
        <h1 className={classes.dialogHeader}>{props.title}</h1>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {props.loading ? <CircularProgress /> : props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          onClick={props.onConfirm}
          color="primary"
          disabled={props.confirming || props.disableConfirm || false}
        >
          {props.confirmText || "ok"}
        </Button>
        {getCancelButton()}
        {props.confirming ? <CircularProgress /> : null}
      </DialogActions>
    </Dialog>
  );
}

GarnishDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  disableConfirm: PropTypes.bool,
  alignLabelsLeft: PropTypes.bool,
  /**
   * Setting this to true will disable the confirm button and prevent the user from closing the dialog. It also displays a loading spinner
   */
  confirming: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  loading: PropTypes.bool,
};

export default GarnishDialog;
