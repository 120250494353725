import React, { useMemo, useRef, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { createEditor } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import useEditorConfig from '../../../hooks/useEditorConfig';
import useSelection from '../../../hooks/useSelection';
import { SlateToolbar } from './SlateToolbar';
import { isLinkNodeAtSelection } from './SlateEditorUtils';
import LinkEditor from './editorMenus/LinkEditor';
import { withCorrectVoidBehavior } from './withCorrectVoidBehavior';

const SlateEditor = React.forwardRef((props, ref) => {
  const {
    editorScrollTop,
    setEditorContent,
    readOnly,
    editorContent,
    onScroll,
  } = props;
  const classes = useStyles();
  const editorRef = useRef(null);
  const editor = useMemo(
    () => withHistory(withReact(withCorrectVoidBehavior(createEditor()))),
    []
  );

  useEffect(() => {
    ReactEditor.toDOMNode(editor, editor).scrollTop = editorScrollTop;
  }, [editorScrollTop, editor]);

  const { renderElement, renderLeaf, onKeyDown } = useEditorConfig(editor);
  const [previousSelection, selection, setSelection] = useSelection(editor);

  const onChangeLocal = useCallback(
    (doc) => {
      setEditorContent(doc);
      setSelection(editor.selection);
    },
    [setEditorContent, setSelection, editor]
  );

  let selectionForLink = null;
  if (isLinkNodeAtSelection(editor, selection)) {
    selectionForLink = selection;
  } else if (
    selection === null &&
    isLinkNodeAtSelection(editor, previousSelection)
  ) {
    selectionForLink = previousSelection;
  }

  const toolbarContent = readOnly ? null : <SlateToolbar />;

  return (
    <div className={classes.slateEditorWrapper} ref={ref} style={props.style}>
      <Slate editor={editor} value={editorContent} onChange={onChangeLocal}>
        {toolbarContent}
        <div className={classes.innerEditor} ref={editorRef}>
          {selectionForLink != null ? (
            <LinkEditor
              editorOffsets={
                editorRef.current != null
                  ? {
                      x: editorRef.current.getBoundingClientRect().x,
                      y: editorRef.current.getBoundingClientRect().y,
                    }
                  : null
              }
              selectionForLink={selectionForLink}
            />
          ) : null}
          <Editable
            className={`${classes.slateEditor} ${readOnly ? 'read-only' : ''}`}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Start creating your course content..."
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            onScroll={onScroll}
          />
        </div>
      </Slate>
    </div>
  );
});

const useStyles = makeStyles({
  slateEditorWrapper: {
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  slateEditor: {
    padding: '30px',
    fontFamily: '"Roboto Condensed", sans-serif',
    color: '#333',
    overflowY: 'scroll',
    height: '100%',
    '& > h1, & > h2, & > h3, & > h4, & > h5': {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontFamily: '"Montserrat", sans-serif',
      color: 'var(--garnish-dark-green)',
    },
    '& > h1': {
      fontSize: '35px',
      letterSpacing: 1.75,
    },
    '& > h2': {
      fontSize: '24px',
      letterSpacing: 1,
    },
    '& > h3': {
      fontSize: '18px',
      letterSpacing: 1,
    },
    '& > h4': {
      fontSize: '15px',
      letterSpacing: 1,
    },
    '& > h5': {
      fontSize: '12px',
      letterSpacing: 1,
    },
    '& > p': {
      fontSize: '16px',
      lineHeight: '22px',
    },
    '&.read-only': {
      overflowY: 'hidden',
    },
  },
  innerEditor: {
    textAlign: 'left',
    position: 'relative',
    height: '100%',
    flex: 1,
    minHeight: 0,
  },
});

export default SlateEditor;
