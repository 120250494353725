import { useEffect, useState } from "react";
import useAuthenticatedRestCall from "./useAuthenticatedRestCall";

export function useDivisionTypes() {
    const [divisionTypes, setDivisionTypes] = useState([])
    const makeAuthenticatedRequest = useAuthenticatedRestCall()

    useEffect(() => {
        const fetchDivisionTypes = async () => {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_CLIENT_SERVICE_URL}/reference-data/division-types?paginated=false`
            );

            const divisionTypes = response.data;

            setDivisionTypes(divisionTypes)
        };

        fetchDivisionTypes();
    }, [makeAuthenticatedRequest, setDivisionTypes]);

    return divisionTypes
}