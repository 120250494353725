import { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '../images/pencil.svg';
import useAuthenticatedRestCall from '../hooks/useAuthenticatedRestCall';
import useClientIdQueryParameter from '../hooks/useClientIdQueryParameter';
import ClientContext from '../contexts/ClientContext';
import { DataGrid } from '@material-ui/data-grid';
import { renderWithToolTip } from '../util/gridUtils';

const useStyles = makeStyles({
  grid: {
    marginTop: '2rem',
    color: 'var(--darker-grey)',
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      position: 'relative',
      '&::before': {
        content: `url('${EditIcon}')`,
        width: '2rem',
        height: '2rem',
        display: 'block',
        position: 'absolute',
        top: '1.5rem',
        left: '1.5rem',
        opacity: 0,
        transition: 'opacity 200ms ease, transform 200ms ease',
        transform: 'translateY(1rem)',
      },
      '&:hover': {
        '&::before': {
          opacity: 1,
          transform: 'translateY(0)',
        },
      },
    },
    '& .MuiDataGrid-cell[data-field="name"]': {
      fontWeight: '500',
      color: '#212529',
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 'normal',
      color: '#3A3B3F',
    },
  },
});

const shortDateFormatter = (date) =>
  new Date(date).toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

const leftPadTime = (value) => `00${value}`.slice(-2);

const stopWatchFormatter = (date) => {
  const ms = date % 1000;
  const totalSeconds = (date - ms) / 1000;
  const secs = totalSeconds % 60;
  const totalMinutes = (totalSeconds - secs) / 60;
  const mins = totalMinutes % 60;
  const hrs = (totalMinutes - mins) / 60;

  return `${hrs}:${leftPadTime(mins)}:${leftPadTime(secs)}`;
};

const renderPercentWithToolTip = (params) =>
  renderWithToolTip({ value: `${params.value}%` });

const renderShortDateWithToolTip = (params) =>
  renderWithToolTip({ value: shortDateFormatter(params.value) });

const renderStopwatchWithToolTip = (params) =>
  renderWithToolTip({ value: stopWatchFormatter(params.value) });

const columns = [
  { field: 'editSpacer', headerName: ' ', width: 50 },
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.3,
    renderCell: renderWithToolTip,
  },
  { field: 'id', headerName: 'ID', width: 160, renderCell: renderWithToolTip },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 125,
    renderCell: renderWithToolTip,
  },
  {
    field: 'email',
    headerName: 'Email Address',
    flex: 0.3,
    renderCell: renderWithToolTip,
  },
  {
    field: 'divisionName',
    headerName: 'Division Name',
    width: 110,
    renderCell: renderWithToolTip,
  },
  {
    field: 'accessCodeNames',
    headerName: 'Access Codes',
    flex: 0.3,
    renderCell: renderWithToolTip,
  },
  {
    field: 'accountCreated',
    headerName: 'Account Created',
    width: 125,
    type: 'date',
    renderCell: renderShortDateWithToolTip,
  },
  {
    field: 'lastActive',
    headerName: 'Last Active',
    type: 'date',
    width: 100,
    renderCell: renderShortDateWithToolTip,
  },
  {
    field: 'timeActive',
    headerName: 'Total Time Active',
    width: 125,
    type: 'number',
    renderCell: renderStopwatchWithToolTip,
  },
  {
    field: 'progress',
    headerName: 'Progress',
    width: 100,
    renderCell: renderPercentWithToolTip,
  },
];

// TODO: this should be broken up into smaller components/hooks
function UsersGrid(props) {
  const classes = useStyles();
  const history = useHistory();

  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { clientInfo } = useContext(ClientContext);

  const [pageSize, setPageSize] = useState(10);
  const [totalDocsCount, setTotalDocsCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getClientIdQueryParameter = useClientIdQueryParameter();

  const handlePageSizeChange = useCallback((pageUpdate) => {
    const pageSize =
      typeof pageUpdate.pageSize === undefined
        ? pageUpdate
        : pageUpdate.pageSize;
    setPageSize(pageSize);
  }, []);

  const handlePageNumberChange = useCallback((pageUpdate) => {
    const pageNumber =
      typeof pageUpdate.page === undefined ? pageUpdate : pageUpdate.page;

    setPageNumber(pageNumber);
  }, []);

  const getSearchQueryParameter = useCallback(() => {
    return props.search && props.search.length > 0
      ? `&search=${props.search}`
      : '';
  }, [props.search]);

  const getUserDivisionName = useCallback(
    async (user) => {
      try {
        const divisionResponse = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_CLIENT_SERVICE_URL}/clients/${user.clientId}/divisions/${user.divisionId}`,
          false
        );

        return divisionResponse.data.name;
      } catch {
        return '';
      }
    },
    [makeAuthenticatedRequest]
  );

  const getUserAccessCodeNames = useCallback(
    async (user) => {
      try {
        const accessCodeRequests = user.accessCodes.map((accessCodeId) =>
          makeAuthenticatedRequest(
            `${process.env.REACT_APP_COURSE_SERVICE_URL}/access-codes/${accessCodeId}`,
            false
          )
        );

        const accessCodeResponses = await Promise.all(accessCodeRequests);

        return accessCodeResponses
          .map((response) => response.data.code)
          .join(', ');
      } catch {
        return [];
      }
    },
    [makeAuthenticatedRequest]
  );

  const getUserWithDivisionAndAccessCodeNames = useCallback(
    async (user) => {
      try {
        const divisionName = await getUserDivisionName(user);
        const accessCodeNames = await getUserAccessCodeNames(user);

        return { ...user, divisionName, accessCodeNames };
      } catch {
        return user;
      }
    },
    [getUserDivisionName, getUserAccessCodeNames]
  );

  const mapUserRowData = useCallback(
    (userData) => {
      const usersWithDivisionNameRequests = userData.map(
        getUserWithDivisionAndAccessCodeNames
      );

      return Promise.all(usersWithDivisionNameRequests);
    },
    [getUserWithDivisionAndAccessCodeNames]
  );

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);

      try {
        const response = await makeAuthenticatedRequest(
          `${
            process.env.REACT_APP_USER_SERVICE_URL
          }/users?pageSize=${pageSize}&pageNumber=${
            pageNumber + 1
          }${getSearchQueryParameter()}${getClientIdQueryParameter()}`
        );

        const mappedRowData = await mapUserRowData(response.data.docs);

        setTotalDocsCount(response.data.totalDocs);
        setRows(mappedRowData);
      } catch (error) {
        console.error(error);
        setError(error);
      }

      setLoading(false);
    };

    if (clientInfo.clientsHaveLoaded) {
      fetchUsers();
    }
  }, [
    pageNumber,
    pageSize,
    clientInfo.clientId,
    props.search,
    makeAuthenticatedRequest,
    getClientIdQueryParameter,
    getSearchQueryParameter,
    mapUserRowData,
    clientInfo.clientsHaveLoaded,
  ]);

  const handleRowClick = (param) => {
    history.push(`/users/${param.id}`);
  };

  return (
    <DataGrid
      className={classes.grid}
      rows={rows}
      columns={columns}
      autoHeight
      pageSize={pageSize}
      rowsPerPageOptions={[10, 20, 50, 100]}
      pagination
      paginationMode="server"
      onPageChange={handlePageNumberChange}
      onPageSizeChange={handlePageSizeChange}
      loading={loading}
      rowCount={totalDocsCount}
      error={error}
      disableColumnSelector
      onRowClick={handleRowClick}
    />
  );
}

export default UsersGrid;
