import React from 'react';

import '../../../device-preview/modules/_example.scss';

const Example = (props) => {
  return (
    <article className="example-block">
      <header className="title-bar">
        <h3 className="title" contentEditable={false}>
          Example:
        </h3>
      </header>
      <div className="body">
        <section>
          <p {...props.attributes}>{props.children}</p>
        </section>
      </div>
    </article>
  );
};

export default Example;
