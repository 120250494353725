import React from 'react';
import '../../../device-preview/modules/_alignment.scss';

const DefaultElement = (props) => {
  const { align } = props.element;
  return (
    <p className={`${align ? align : ''}`} {...props.attributes}>
      {props.children}
    </p>
  );
};

export default DefaultElement;
