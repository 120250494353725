import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./App.scss";
import AnimatedRoutes from "./routes/AnimatedRoutes";
import MainNav from "./components/MainNav";
import MainHeader from "./components/MainHeader";
import ClientContext from "./contexts/ClientContext";
import FullScreenLoadingIndicator from "./components/FullScreenLoadingIndicator";
import LevelContentDragPreview from "./components/courses/LevelContentDragPreview";
import LanguageContext from "./contexts/LanguageContext";
import useAuthenticatedRestCall from "./hooks/useAuthenticatedRestCall";

function App() {
  const { initialized, keycloak } = useKeycloak();

  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  const [clientInfo, setClientInfo] = useState({
    loadingClients: true,
    clientId: "",
    clientsHaveLoaded: false,
    errorOccurredLoadingClients: false,
  });

  const [showSideNav, setShowSideNav] = useState(true);

  const clientInfoContextValue = { clientInfo, setClientInfo };

  const [languageInfo, setLanguageInfo] = useState({
    loading: true,
    languages: [],
  });

  useEffect(() => {
    if (clientInfo.clientsHaveLoaded) {
      localStorage.setItem("selectedClient", clientInfo.clientId);
    }
  }, [clientInfo.clientId, clientInfo.clientsHaveLoaded]);

  useEffect(() => {
    const fetchLanguageInfo = async () => {
      const languageResponse = await makeAuthenticatedRequest(
        `${process.env.REACT_APP_I18N_SERVICE_URL}/supported-languages`
      );

      setLanguageInfo({
        loading: false,
        languages: languageResponse.data.docs,
      });
    };

    if (initialized) {
      fetchLanguageInfo();
    }
  }, [makeAuthenticatedRequest, initialized]);

  if (!initialized) {
    return <FullScreenLoadingIndicator />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return <FullScreenLoadingIndicator />;
  }

  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <LanguageContext.Provider value={{ languageInfo, setLanguageInfo }}>
          <ClientContext.Provider value={clientInfoContextValue}>
            <Router>
              <MainHeader />
              <section className="app-contents">
                <MainNav visible={showSideNav} />
                <AnimatedRoutes
                  onFullPageRouteLoaded={() => setShowSideNav(false)}
                  onRegularRouteLoaded={() => setShowSideNav(true)}
                />
              </section>
            </Router>
          </ClientContext.Provider>
        </LanguageContext.Provider>
        <LevelContentDragPreview />
      </DndProvider>
    </div>
  );
}

export default App;
