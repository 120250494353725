import React from 'react';
import useToggle from '../../../../hooks/useToggle';

import '../../../device-preview/modules/_expandables.scss';

const GarnishTip = (props) => {
  const { previewMode } = props;
  const [isOpen, toggleIsOpen] = useToggle(false);
  const handleClick = () => {
    if (previewMode) {
      toggleIsOpen(!!isOpen);
    }
  };
  return (
    <article
      className={`expandable garnish-tip ${
        previewMode && !isOpen ? 'closed' : 'open'
      }`}
    >
      <header className="title-bar" onClick={handleClick}>
        <h3 className="title" contentEditable={false}>
          Garnish Tip
        </h3>
      </header>
      <div className="body">
        <section>
          <p {...props.attributes}>{props.children}</p>
        </section>
      </div>
    </article>
  );
};

export default GarnishTip;
