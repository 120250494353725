import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import GarnishDialog from "../GarnishDialog"
import ImageUploader from "../ImageUploader";
import DivisionTypeSelect from "./DivisionTypeSelect";
import ClientNameInput from "./ClientNameInput";

const useStyles = makeStyles({
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        width: "30rem",
        marginLeft: "auto",
        marginRight: "auto"
    },
    label: {
        textAlign: "center"
    },
    clientImageUploaderWrapper: {
        marginTop: "2rem"
    }
})

function EditClientDialog(props) {
    const classes = useStyles()
    const [divisionType, setDivisionType] = useState('');
    const [updatedWatermarkFile, setUpdatedWatermarkFile] = useState(null);
    const [updatedWatermarkUrl, setUpdatedWatermarkUrl] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);

    useEffect(() => {
        if (props.divisionType) {
            setDivisionType(props.divisionType)
        }
    }, [props.divisionType, props.open])

    useEffect(() => {
        if (props.clientName) {
            setName(props.clientName)
        }
    }, [props.clientName, props.open])

    const handleUpdateWatermark = (file, url) => {
        setUpdatedWatermarkFile(file)
        setUpdatedWatermarkUrl(url)
    }

    const handleSave = () => {
        props.handleEditClientConfirmed({ updatedName: name, updatedWatermarkUrl, updatedWatermarkFile, updatedDivisionType: divisionType })
    }

    return (<GarnishDialog confirmText="Save" open={props.open} confirming={props.saving} disableConfirm={nameError !== null} title={`Edit ${props.clientName}`} onClose={props.onDialogClosed} onConfirm={handleSave}>
        <div className={classes.dialogContainer}>
            <ClientNameInput value={name} error={nameError} onError={error => setNameError(error)} onChange={name => setName(name)} />
            <DivisionTypeSelect value={divisionType} onChange={value => setDivisionType(value)} />
            <div className={classes.clientImageUploaderWrapper}>
                <ImageUploader className={classes.clientImageUploader} label="Watermark logo" onImageUpload={handleUpdateWatermark} disabled={props.saving} defaultImageSrc={props.watermarkIconSrc} />
            </div>
        </div>
    </GarnishDialog>)
}

EditClientDialog.propTypes = {
    open: PropTypes.bool,
    clientName: PropTypes.string,
    divisionType: PropTypes.string,
    saving: PropTypes.bool,
    watermarkIconSrc: PropTypes.string,
    onDialogClosed: PropTypes.func,
    handleEditClientConfirmed: PropTypes.func
}

export default EditClientDialog