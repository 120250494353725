import { createContext } from "react";

export default createContext({
  questions: [],
  setQuestions: () => {},
  emptyQuestions: [],
  setEmptyQuestions: () => {},
  emptyAnswers: [],
  setEmptyAnswers: () => {},
  displayAllErrors: false,
  setDisplayAllErrors: () => {},
  questionTranslations: [],
  setQuestionTranslations: () => {},
});
