import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Label from "./Label";
import ImageContainer from "./ImageContainer";
import { InputLabel } from "@material-ui/core";

const MAX_IMAGE_SIZE_IN_BYTES = 2000000;

const useStyles = makeStyles({
  imageUploader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imageContainer: {
    width: "25rem",
    minHeight: "25rem",
    margin: "1rem 0",
    "& > img": {
      width: "25rem",
      margin: "0 auto",
    },
  },
  noImage: {
    background: "var(--grey)",
    borderRadius: ".5rem",
    height: "25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      height: "15rem",
      width: "15rem",
      color: "var(--dark-grey)",
    },
  },
  imageNote: {
    textTransform: "none",
    fontSize: "1.2rem",
  },
});

function ImageUploader(props) {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(props.defaultImageSrc || '');
  const { enqueueSnackbar } = useSnackbar();

  const fileId = useMemo(() => `file-upload-${Math.random().toString().split('.')[1]}`, [])

  useEffect(() => {
    if (!props.isLoading) {
      setCurrentImage(props.defaultImageSrc);
    }
  }, [props.isLoading, props.defaultImageSrc]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file.size > MAX_IMAGE_SIZE_IN_BYTES) {
      enqueueSnackbar(
        "The file uploaded was too large. Files must be smaller than 2MB.",
        {
          variant: "error",
        }
      );
    } else {
      URL.revokeObjectURL(currentImage);
      const imageUrl = URL.createObjectURL(file)
      setCurrentImage(imageUrl);
      props.onImageUpload(file, imageUrl);
    }
  };

  const getLabel = () => props.noLabel ? null : <InputLabel>{props.label || "Upload image"}</InputLabel>

  return (
    <Label for={fileId} className={classes.imageUploader}>
      {getLabel()}
      <ImageContainer isLoading={props.isLoading} imageSrc={currentImage} />
      <input
        id={fileId}
        hidden
        className={classes.input}
        type="file"
        accept=".png,.jpg"
        onChange={handleImageUpload}
      ></input>
      <p className={classes.imageNote}>Images must be smaller than 2MB</p>
      <Button variant="contained" color="primary" component="span" disabled={props.disabled}>
        {props.buttonLabel || "Upload new image"}
      </Button>
    </Label>
  );
}

ImageUploader.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultImageSrc: PropTypes.string
};

export default ImageUploader;