import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  label: {
    textTransform: "uppercase",
    color: "var(--graphite)",
    fontWeight: "bolder",
    marginBottom: "8px",
  },
});

function Label(props) {
  const classes = useStyles();

  const getClass = () =>
    props.className ? `${classes.label} ${props.className}` : classes.label;

  return (
    <label className={getClass()} htmlFor={props.for}>
      {props.children}
    </label>
  );
}

export default Label;
