import { useContext, useEffect, useState, useCallback } from "react";
import { Button, makeStyles } from "@material-ui/core";
import LanguageSelect from "./LanguageSelect";
import LanguageContext from "../../contexts/LanguageContext";
import { DEFAULT_LANGUAGE_CODE } from "../../constants/defaultLanguageCode";

const useStyles = makeStyles({
  addLanguageContainer: {
    display: "flex",
    maxHeight: "4rem",
    marginBottom: "2rem",
    alignItems: "end",
    marginTop: "4rem"
  },
  addLanguageButton: {
    marginLeft: "2rem",
    flexGrow: "1",
    height: "3.5rem"
  },
});

export default function AddTranslation(props) {
  const classes = useStyles();
  const [newLanguageId, setNewLanguageId] = useState("");

  const { languageInfo } = useContext(LanguageContext);

  const getTranslationsToExclude = useCallback(() => {
    return props.existingTranslations.map(
      (translation) => translation.languageId
    );
  }, [props.existingTranslations]);

  useEffect(() => {
    if (!languageInfo.loading) {
      const languageToDisplayAfterLoad = languageInfo.languages
        .filter((language) => language.abbreviation !== DEFAULT_LANGUAGE_CODE)
        .find((language) => !getTranslationsToExclude().includes(language.id));

      setNewLanguageId(languageToDisplayAfterLoad.id);
    }
  }, [languageInfo.loading, languageInfo.languages, getTranslationsToExclude]);

  const handleLanguageChanged = (event) => {
    setNewLanguageId(event.target.value);
  };

  const handleTranslationAdded = () => {
    props.onTranslationAdded(newLanguageId);
    setNewLanguageId("");
  };

  return (
    <div className={classes.addLanguageContainer}>
      <LanguageSelect
        id={props.id}
        label="Language"
        value={newLanguageId}
        onChange={handleLanguageChanged}
        excludeLanguageIds={getTranslationsToExclude()}
        hideDefaultLanguage={true}
        width={"20rem"}
        maxHeight={true}
      />
      <Button
        className={classes.addLanguageButton}
        variant="contained"
        onClick={handleTranslationAdded}
        color="primary"
        disabled={props.disabled || languageInfo.loading}
      >
        {props.buttonLabel}
      </Button>
    </div>
  );
}
