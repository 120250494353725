import { useState, useEffect, useContext, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import Page from "../components/Page";
import AddIcon from "@material-ui/icons/Add";
import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";
import ClientContext from "../contexts/ClientContext";
import { getAccessCodeUrl, getAccessCodeListUrl } from "../util/getUrls";
import AdminAccessCodesGrid from "../components/admin/AdminAccessCodesGrid";
import GarnishDialog from "../components/GarnishDialog";
import EditField from "../components/EditField";
import { updateObjectAtIndex } from "../util/arrayUtils";
import { useAccessCodeVerification } from "../hooks/useAccessCodeVerification";

function AdminAccessCodesPage() {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const { clientInfo } = useContext(ClientContext);
  const [accessCodes, setAccessCodes] = useState([]);
  const [accessCodeError, setAccessCodeError] = useState(null);
  const [loadingAccessCodes, setLoadingAccessCodes] = useState(true);
  const [deleteAccessCodeDialogOpen, setDeleteAccessCodeDialogOpen] =
    useState(false);
  const [deletingAccessCode, setDeletingAccessCode] = useState(false);
  const [accessCodeToDelete, setAccessCodeToDelete] = useState(null);
  const [editAccessCodeDialogOpen, setEditAccessCodeDialogOpen] =
    useState(false);
  const [savingAccessCode, setSavingAccessCode] = useState(false);
  const [accessCodeToEdit, setAccessCodeToEdit] = useState(null);
  const [codeEditValue, setCodeEditValue] = useState("");
  const { accessCodeAlreadyExists, validateAccessCode } =
    useAccessCodeVerification();
  const { enqueueSnackbar } = useSnackbar();

  const getAccessCodeIfDefined = useCallback(
    (accessCodeId) => {
      const accessCode = accessCodes.find(
        (accessCode) => accessCode.id === accessCodeId
      );

      return accessCode?.code;
    },
    [accessCodes]
  );

  const getAccessCodeToDeleteDisplayName = () =>
    accessCodeToDelete ? getAccessCodeIfDefined(accessCodeToDelete) : "";

  const getAccessCodeToEditDisplayName = useCallback(
    () => (accessCodeToEdit ? getAccessCodeIfDefined(accessCodeToEdit) : ""),
    [accessCodeToEdit, getAccessCodeIfDefined]
  );

  useEffect(() => {
    const fetchAccessCodes = async () => {
      try {
        const response = await makeAuthenticatedRequest(
          getAccessCodeListUrl(clientInfo.clientId)
        );
        setAccessCodes(response.data.docs);
      } catch (error) {
        setAccessCodeError(true);
      }

      setLoadingAccessCodes(false);
    };

    if (clientInfo.clientId) {
      fetchAccessCodes();
    }
  }, [makeAuthenticatedRequest, clientInfo.clientId]);

  useEffect(() => {
    if (accessCodeToEdit) {
      const codeDisplayName = getAccessCodeToEditDisplayName(accessCodeToEdit);
      setCodeEditValue(codeDisplayName);
    }
  }, [accessCodeToEdit, getAccessCodeToEditDisplayName]);

  const getToolbarButtons = () => (
    <Button
      variant="contained"
      color="primary"
      title="Add a new access code"
      startIcon={<AddIcon />}
      component={NavLink}
      to="/admin/access-codes/create"
    >
      Add access code
    </Button>
  );

  const handleDeleteAccessCodeClosed = () =>
    setDeleteAccessCodeDialogOpen(false);

  const handleEditAccessCodeClosed = () => setEditAccessCodeDialogOpen(false);

  const deleteAccessCode = () => {
    setDeletingAccessCode(true);

    makeAuthenticatedRequest(getAccessCodeUrl(accessCodeToDelete), false, {
      method: "DELETE",
    })
      .then(() => {
        enqueueSnackbar(`"${getAccessCodeToDeleteDisplayName()}" deleted`, {
          variant: "success",
        });

        setDeleteAccessCodeDialogOpen(false);

        setAccessCodes(
          accessCodes.filter(
            (accessCode) => accessCode.id !== accessCodeToDelete
          )
        );
      })
      .catch(() => {
        enqueueSnackbar(
          `${getAccessCodeToDeleteDisplayName()} could not be deleted`,
          {
            variant: "error",
          }
        );
      })
      .finally(() => {
        setDeletingAccessCode(false);
      });
  };

  const saveAccessCode = () => {
    setSavingAccessCode(true);

    makeAuthenticatedRequest(getAccessCodeUrl(accessCodeToEdit), false, {
      method: "PUT",
      data: { code: codeEditValue },
    })
      .then(() => {
        enqueueSnackbar(`"${codeEditValue}" saved`, {
          variant: "success",
        });

        setEditAccessCodeDialogOpen(false);

        const updatedItemIndex = accessCodes.findIndex(
          (accessCode) => accessCode.id === accessCodeToEdit
        );

        const updatedAccessCodes = updateObjectAtIndex(
          accessCodes,
          updatedItemIndex,
          { ...accessCodes[updatedItemIndex], code: codeEditValue }
        );

        setAccessCodes(updatedAccessCodes);
      })
      .catch(() => {
        enqueueSnackbar(
          `${getAccessCodeToEditDisplayName()} could not be saved`,
          {
            variant: "error",
          }
        );
      })
      .finally(() => {
        setSavingAccessCode(false);
      });
  };

  const handleDeleteAccessCodeClicked = (accessCodeId) => {
    setAccessCodeToDelete(accessCodeId);
    setDeleteAccessCodeDialogOpen(true);
  };

  const handleEditAccessCodeClicked = (accessCodeId) => {
    setAccessCodeToEdit(accessCodeId);
    setEditAccessCodeDialogOpen(true);
  };

  const handleCodeEditValueChanged = (event) => {
    setCodeEditValue(event.target.value.toUpperCase());
    validateAccessCode(event.target.value);
  };

  return (
    <Page title="Access codes" toolbarContent={getToolbarButtons()}>
      <AdminAccessCodesGrid
        accessCodes={accessCodes}
        loading={loadingAccessCodes}
        error={accessCodeError}
        onDeleteClicked={handleDeleteAccessCodeClicked}
        onEditClicked={handleEditAccessCodeClicked}
      />

      <GarnishDialog
        open={deleteAccessCodeDialogOpen}
        title="Are you sure?"
        onClose={handleDeleteAccessCodeClosed}
        confirmText="Delete access code"
        onConfirm={deleteAccessCode}
        confirming={deletingAccessCode}
        showCancelButton={true}
        onCancel={handleDeleteAccessCodeClosed}
      >
        Are you sure you want to delete {getAccessCodeToDeleteDisplayName()}?
        This action can't be undone.
      </GarnishDialog>

      <GarnishDialog
        open={editAccessCodeDialogOpen}
        title={`Edit ${getAccessCodeToEditDisplayName()}`}
        onClose={handleEditAccessCodeClosed}
        confirmText="Save"
        onConfirm={saveAccessCode}
        confirming={savingAccessCode}
        disableConfirm={accessCodeAlreadyExists}
      >
        <EditField
          id="edit-access-code"
          label="Code value"
          value={codeEditValue}
          onChange={handleCodeEditValueChanged}
          error={accessCodeAlreadyExists}
          helperText={
            accessCodeAlreadyExists
              ? "This code name is already being used"
              : null
          }
        />
      </GarnishDialog>
    </Page>
  );
}

export default AdminAccessCodesPage;
