import { useState, useEffect } from "react";
import { updateObjectAtIndex } from "../util/arrayUtils";

export default function useTranslatedTitlesInDialog(
  open,
  existingTitleTranslations
) {
  const [titleTranslations, setTitleTranslations] = useState([]);

  useEffect(() => {
    if (existingTitleTranslations) {
      const combinedTitleTranslations = [
        ...existingTitleTranslations,
        ...titleTranslations,
      ];

      setTitleTranslations(combinedTitleTranslations);
    }
  }, [existingTitleTranslations]);

  useEffect(() => {
    if (!open) {
      setTitleTranslations([]);
    }
  }, [open]);

  const handleTranslatedTitleChanged = (value, languageId) => {
    const indexOfLanguageId = titleTranslations.findIndex(
      (translation) => translation.languageId === languageId
    );

    setTitleTranslations(
      updateObjectAtIndex(titleTranslations, indexOfLanguageId, {
        translatedValue: value,
      })
    );
  };

  const handleDeleteTranslatedTitleClicked = (languageId) => {
    const updatedTitleTranslations = titleTranslations.filter(
      (translation) => translation.languageId !== languageId
    );

    setTitleTranslations(updatedTitleTranslations);
  };

  const handleTranslationAdded = (newLanguageId) => {
    setTitleTranslations([
      ...titleTranslations,
      { languageId: newLanguageId, translatedValue: "" },
    ]);
  };

  const getTranslatedTitlesForUpload = () =>
    titleTranslations.filter(
      (translation) => translation.translatedValue.trim().length !== 0
    );

  return {
    titleTranslations,
    handleTranslatedTitleChanged,
    handleDeleteTranslatedTitleClicked,
    handleTranslationAdded,
    getTranslatedTitlesForUpload,
  };
}
