import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LevelCourseList from "./LevelCourseList";
import LevelContentListTitle from "./LevelContentListTitle";
import EditLevelDialog from "./EditLevelDialog";
import AddCourseDialog from "./AddCourseDialog";
import GarnishAccordion from "../GarnishAccordion";

const useStyles = makeStyles({
  addNewCourseContainer: {
    minHeight: "4.8rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },
  addNewCourseButton: {
    textTransform: "none",
  },
});

function LevelAccordion(props) {
  const classes = useStyles();
  const [addCourseDialogOpen, setAddCourseDialogOpen] = useState(false);
  const [newCourses, setNewCourses] = useState([]);

  const handleAddCourseModalClosed = () => setAddCourseDialogOpen(false);
  const onAddNewCourseClicked = () => setAddCourseDialogOpen(true);

  const handleNewCourseCreated = (newCourse) =>
    setNewCourses([...newCourses, newCourse]);

  const supportedLanguageIds = props.level.titleTranslations.map(
    (translation) => translation.languageId
  );

  const getAccordionTitle = () => (<LevelContentListTitle
    title={props.level.title}
    onEditClicked={props.onEditLevelClicked}
    supportedLanguageIds={supportedLanguageIds}
  />)

  return (
    <GarnishAccordion
      title={getAccordionTitle()}
      onToggle={props.onToggle}
    >
      <LevelCourseList
        levelId={props.level.id}
        levelCourses={props.level.courses}
        showContent={props.shouldShowContent}
        newCourses={newCourses}
      />
      <div className={classes.addNewCourseContainer}>
        <Button
          color="primary"
          classes={{ root: classes.addNewCourseButton }}
          onClick={onAddNewCourseClicked}
        >
          Add new course to "{props.level.title}"
        </Button>
      </div>
      <EditLevelDialog
        open={props.openEditDialog}
        level={props.level}
        onClose={props.onEditLevelModalClosed}
        onLevelModified={props.onLevelModified}
      />
      <AddCourseDialog
        open={addCourseDialogOpen}
        onClose={handleAddCourseModalClosed}
        onNewCourseCreated={handleNewCourseCreated}
        levelId={props.level.id}
      />
    </GarnishAccordion>
  );
}

export default LevelAccordion;
