import React from 'react';
import '../../../device-preview/modules/_alignment.scss';

const Heading = (props) => {
  const { attributes, children, element } = props;
  const { align } = element;

  if (element.type === 'heading-one') {
    return (
      <h1 className={`${align ? align : ''}`} {...attributes}>
        {children}
      </h1>
    );
  }
  if (element.type === 'heading-two') {
    return (
      <h2 className={`${align ? align : ''}`} {...attributes}>
        {children}
      </h2>
    );
  }
  if (element.type === 'heading-three') {
    return (
      <h3 className={`${align ? align : ''}`} {...attributes}>
        {children}
      </h3>
    );
  }
  if (element.type === 'heading-four') {
    return (
      <h4 className={`${align ? align : ''}`} {...attributes}>
        {children}
      </h4>
    );
  }
  if (element.type === 'heading-five') {
    return (
      <h5 className={`${align ? align : ''}`} {...attributes}>
        {children}
      </h5>
    );
  }
  return (
    <p className={`${align ? align : ''}`} {...attributes}>
      {children}
    </p>
  );
};

export default Heading;
