import React from 'react';

import '../../../device-preview/modules/_divider.scss';

const Divider = ({ attributes, children }) => {
  return (
    <p {...attributes}>
      <hr style={{ userSelect: "none" }} contentEditable={false} />
      {children}
    </p>
  )
};

export default Divider;
