import { makeStyles } from "@material-ui/core";
import { usePreview } from "react-dnd-preview";
import LevelContentListTitle from "./LevelContentListTitle";

const useStyles = makeStyles({
  preview: {
    background: "white",
    minWidth: "20rem",
    padding: "1rem 2rem",
    opacity: "1",
    borderRadius: ".5rem",
    boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.16)",
    zIndex: "2"
  },
});

const LevelContentDragPreview = () => {
  const { display, item, style, ref } = usePreview();
  const classes = useStyles();

  if (!display) {
    return null;
  }

  return (
    <div style={style} className={classes.preview} ref={ref}>
      <LevelContentListTitle title={item.title} unpublished={item.unpublished}/>
    </div>
  );
};

export default LevelContentDragPreview;
