import { useCallback, useEffect, useState } from "react";
import useAuthenticatedRestCall from "./useAuthenticatedRestCall";

export default function useExistingTitleTranslationsInDialog(open, url) {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  const [existingTitleTranslations, setExistingTitleTranslations] =
    useState(null);

  const fetchTitleTranslations = useCallback(() => {
    makeAuthenticatedRequest(url, false).then((result) => {
      setExistingTitleTranslations(result.data.titleTranslations || []);
    });
  }, [makeAuthenticatedRequest, url]);

  useEffect(() => {
    if (open) {
      fetchTitleTranslations();
    }
  }, [open, fetchTitleTranslations]);

  return {
    existingTitleTranslations,
  };
}
