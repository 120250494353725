import { useContext, useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ClientContext from '../contexts/ClientContext';
import useAuthenticatedRestCall from '../hooks/useAuthenticatedRestCall';
import GarnishSelect from './GarnishSelect';

const useStyles = makeStyles({
  menuItem: {
    fontSize: '1.4rem',
  },
});

function ClientDropdown() {
  const classes = useStyles();
  const { clientInfo, setClientInfo } = useContext(ClientContext);

  const makeAuthenticatedRequest = useAuthenticatedRestCall();

  const [clientList, setClientList] = useState([]);

  const getClientIdFromLocalStorage = (clientList) => {
    const storedClientId = localStorage.getItem('selectedClient');

    const storedClientExists = clientList.find(
      (client) => client.id === storedClientId
    );

    if (storedClientExists) {
      return storedClientId;
    }

    return clientList[0].id;
  };

  useEffect(() => {
    const fetchClientsAndSetState = async () => {
      try {
        const response = await makeAuthenticatedRequest(
          `${process.env.REACT_APP_CLIENT_SERVICE_URL}/clients?paginated=false`
        );
        const clientList = response.data.docs;

        const savedClientId = getClientIdFromLocalStorage(clientList);

        setClientList(clientList);
        setClientInfo({
          loadingClients: false,
          clientsHaveLoaded: true,
          clientId: savedClientId,
          errorOccurredLoadingClients: false,
        });
      } catch (error) {
        setClientInfo({
          loadingClients: false,
          clientsHaveLoaded: true,
          clientId: '',
          errorOccurredLoadingClients: true,
        });
      }
    };

    fetchClientsAndSetState();
  }, [makeAuthenticatedRequest, setClientInfo]);

  const clientMenuItems = clientList.map((client) => {
    return (
      <MenuItem className={classes.menuItem} value={client.id} key={client.id}>
        {client.name}
      </MenuItem>
    );
  });

  return (
    <GarnishSelect
      id="client-dropdown"
      value={clientInfo.clientId}
      onChange={(event) => {
        setClientInfo({ ...clientInfo, clientId: event.target.value });
      }}
      disabled={
        clientInfo.loadingClients || clientInfo.errorOccurredLoadingClients
      }
      loading={clientInfo.loadingClients}
    >
      {clientMenuItems}
    </GarnishSelect>
  );
}

export default ClientDropdown;
