import React from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontSize: 20,
  },
  overrides: {
    MuiButton: {
      contained: {
        color: 'white',
        fontSize: '1.4rem',
        minWidth: '12rem',
        '& $label': {
          color: 'white',
        },
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        color: 'var(--graphite)',
        fontWeight: 'bolder',
        marginBottom: '8px',
        fontSize: '1.2rem',
      },
    },
    MuiDataGrid: {
      root: {
        backgroundColor: 'white',
        '& .MuiTablePagination-selectIcon': {
          width: '2rem',
          height: '2rem',
        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '& .MuiDataGrid-footer': {
          fontSize: '1.2rem',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#60C2A7',
    },
    secondary: {
      main: '#033832',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
