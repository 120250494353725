import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addClassToDefaultIfTrue } from "../util/addClassToDefaultIfTrue";
import Label from "./Label";

const useStyles = makeStyles({
  selectFormControl: {
    height: "3.6rem",

    "&.max-height": {
      height: "100%",

      "& .select": {
        height: "100%",
      },
    },

    "& .loading $selectIcon": {
      opacity: "0",
    },
  },
  selectContainer: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  select: {
    boxSizing: "border-box",
    backgroundColor: "white",
    fontSize: "1.6rem",
    borderRadius: ".5rem",
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto Condensed",
    "&:focus": {
      backgroundColor: "white",
      borderRadius: ".5rem",
    },
    padding: "1rem",
    textTransform: "capitalize"
  },
  leftAlign: {
    "& div": {
      justifyContent: "left"
    }
  },
  rightAlign: {
    "& div": {
      justifyContent: "right"
    }
  },
  centerAlign: {
    "& div": {
      justifyContent: "center"
    }
  },
  selectFocused: {
    background: "white",
  },
  selectIcon: {
    width: "2.5rem",
    height: "2.5rem",
    transition: "opacity 400ms ease",
    opacity: "1",
  },
  selectLoadingIcon: {
    position: "absolute",
    right: "1rem",
    top: ".6rem",
    transition: "opacity 400ms ease",
    "&.loaded": {
      opacity: "0",
    },
  },
  menuPaper: {
    maxHeight: 400,
  },
  labelShrink: {
    transform: "none"
  }
});

function GarnishSelect(props) {
  const classes = useStyles();

  const getLabel = () =>
    props.label ? <Label for={props.id} classes={{ root: classes.label, shrink: classes.labelShrink }}>{props.label}</Label> : null;

  const getAlignmentClass = () => {
    switch (props.alignItems) {
      case 'left':
        return classes.leftAlign
      case 'right':
        return classes.rightAlign
      default:
        return classes.centerAlign
    }
  }

  const getSelectClass = () => `${props.loading ? "loading" : "loaded"} select ${getAlignmentClass()}`;

  return (
    <FormControl
      style={{
        width: props.width || "30rem"
      }}
      classes={{
        root: addClassToDefaultIfTrue(
          classes.selectFormControl,
          props.maxHeight,
          "max-height"
        ),
      }}
    >
      {getLabel()}
      <div className={classes.selectContainer}>
        <Select
          id={props.id}
          className={getSelectClass()}
          variant="outlined"
          style={{
            width: props.width || "30rem"
          }}
          classes={{
            root: classes.select,
            icon: classes.selectIcon,
          }}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          displayEmpty={props.displayEmpty}
          labelId={props.id}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
        >
          {props.children}
        </Select>
        <CircularProgress
          className={props.loading ? "loading" : "loaded"}
          classes={{
            root: classes.selectLoadingIcon,
          }}
          size="2.5rem"
        />
      </div>
    </FormControl >
  );
}

GarnishSelect.propTypes = {
  id: PropTypes.string.isRequired,
  alignItems: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  width: PropTypes.string
};

export default GarnishSelect;
