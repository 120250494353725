import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types'
import { useEffect, useState } from "react";
import { useDivisionTypes } from "../../hooks/useDivisionTypes";
import GarnishSelect from "../GarnishSelect";

const useStyles = makeStyles({
    divisionTypeSelect: {
        width: "100%"
    },
    divisionMenuItem: {
        textTransform: "capitalize"
    },
})

function DivisionTypeSelect({ value, onChange, saving }) {
    const classes = useStyles()
    const divisionTypes = useDivisionTypes()
    const [selectValue, setSelectValue] = useState("")

    useEffect(() => {
        if (divisionTypes.length > 0) {
            if (value.length > 0) {
                setSelectValue(value)
            } else {
                setSelectValue(divisionTypes[0])
                onChange(divisionTypes[0])
            }
        }
    }, [value, divisionTypes, onChange])

    const divisionTypeMenuItems = divisionTypes.map((divisionType) => {
        return (
            <MenuItem value={divisionType} key={divisionType} className={classes.divisionMenuItem}>
                {divisionType}
            </MenuItem>
        );
    });

    return <GarnishSelect id="division-type-select" label="Division type" alignItems="left" className={classes.divisionTypeSelect} value={selectValue} maxHeight={true} loading={divisionTypes.length === 0} disabled={saving} onChange={event => onChange(event.target.value)}>
        {divisionTypeMenuItems}
    </GarnishSelect>
}

DivisionTypeSelect.propTypes = {
    value: PropTypes.string.isRequired,
    saving: PropTypes.bool,
    onChange: PropTypes.func
}

export default DivisionTypeSelect