import React, { useEffect, useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import useAuthenticatedRestCall from "../hooks/useAuthenticatedRestCall";
import useClientIdQueryParameter from "../hooks/useClientIdQueryParameter";
import Page from "../components/Page";
import Card from "../components/Card";
import AddLevelDialog from "../components/courses/AddLevelDialog";
import ClientContext from "../contexts/ClientContext";
import LevelAccordion from "../components/courses/LevelAccordion";

function Courses() {
  const makeAuthenticatedRequest = useAuthenticatedRestCall();
  const getClientIdQueryParameter = useClientIdQueryParameter();
  const [levels, setLevels] = useState(null);
  const [addLevelDialogOpen, setAddLevelDialogOpen] = useState(false);
  const [levelToEdit, setLevelToEdit] = useState(null);
  // Material UI accordions always render their content, even when closed. This should be controlled by the TransitionProps prop on
  // the accordion, but this doesn't seem to work. We need to avoid loading all children of a course because
  // the combined course content list could be fairly large. So, we keep track of which accordions have actually
  // been opened and only load the content of these items.
  const [levelContentToLoadList, setLevelContentToLoadList] = useState([]);
  const { clientInfo } = useContext(ClientContext);

  useEffect(() => {
    const fetchLevels = async () => {
      const levelListResponse = await makeAuthenticatedRequest(
        `${
          process.env.REACT_APP_COURSE_SERVICE_URL
        }/levels?paginated=false${getClientIdQueryParameter()}`,
        false
      );

      setLevels(levelListResponse.data.docs);
    };

    if (clientInfo.clientsHaveLoaded) {
      fetchLevels();
    }
  }, [
    makeAuthenticatedRequest,
    getClientIdQueryParameter,
    clientInfo.clientsHaveLoaded,
  ]);

  const loadAccordionContentIfOpened = (levelId, expanded) => {
    if (expanded && !levelContentToLoadList.includes(levelId)) {
      setLevelContentToLoadList([...levelContentToLoadList, levelId]);
    }
  };

  const shouldLoadContent = (levelId) =>
    levelContentToLoadList.includes(levelId);

  const handleEditLevelClicked = (event, course) => {
    event.stopPropagation();

    setLevelToEdit(course);
  };

  const handleEditLevelModalClosed = () => setLevelToEdit(null);

  const handleLevelModified = (modifiedLevel) => {
    const updatedLevels = [...levels];

    let levelToModify = updatedLevels.find(
      (level) => level.id === modifiedLevel.id
    );

    levelToModify.title = modifiedLevel.title;
    levelToModify.titleTranslations = modifiedLevel.titleTranslations;

    setLevels(updatedLevels);
  };

  const getLevelAccordions = () =>
    levels.map((level) => (
      <LevelAccordion
        key={level.id}
        level={level}
        onToggle={(event, expanded) =>
          loadAccordionContentIfOpened(level.id, expanded)
        }
        onEditLevelClicked={(event) => handleEditLevelClicked(event, level)}
        shouldShowContent={shouldLoadContent(level.id)}
        openEditDialog={levelToEdit?.id === level.id}
        onEditLevelModalClosed={handleEditLevelModalClosed}
        onLevelModified={handleLevelModified}
      />
    ));

  const getLevelAccordionsOrEmptyMessage = () =>
    levels !== null && levels.length > 0 ? (
      getLevelAccordions()
    ) : (
      <h4>No levels have been created.</h4>
    );

  const handleAddLevelClicked = () => setAddLevelDialogOpen(true);
  const handleAddLevelModalClosed = () => setAddLevelDialogOpen(false);

  const handleNewLevelCreated = (newLevel) => {
    setLevels([...levels, newLevel]);
  };

  const toolbarButtons = () => (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddLevelClicked}
      >
        Add level
      </Button>
    </React.Fragment>
  );

  return (
    <Page title="Courses" toolbarContent={toolbarButtons()}>
      <Card
        isLoading={levels === null}
        noPadding={levels !== null && levels.length > 0}
      >
        {getLevelAccordionsOrEmptyMessage()}
      </Card>
      <AddLevelDialog
        open={addLevelDialogOpen}
        onClose={handleAddLevelModalClosed}
        onNewLevelCreated={handleNewLevelCreated}
      />
    </Page>
  );
}

export default Courses;
